import axios from "axios";
import React, { useState } from "react";
import { Col, Checkbox, Form, Input, message } from "antd";
import * as AuthApi from "../services/api/auth";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../components/Button/Button";
import AuthHeader from "../components/Dashboard/AuthHeader";
import * as adminApi from "../services/api/admin";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

function ChangePassword() {

  // console.log("0==================",userInfo.id);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const onFinish = async (credentials) => {
    try {
      const json = {
        userId: userInfo.id,
        oldPassword: credentials.oldPassword,
        password: credentials.password,
        confirmpassword: credentials.confirmpassword,
      };
      axios
        .post(
          "https://gokepiqof3.execute-api.us-east-1.amazonaws.com/prod/api/admin/change-password",
          json
        )
        .then((res) => {
          const response = res.data;

          console.log("-----------", response);
          localStorage.setItem("userInfo", JSON.stringify(response));
          // navigate("/dashboard");
          setLoader(false);
        });


      /* setLoader(true);
      const {
        data: { data },
        response: { status },
      } = await adminApi.changePasswordAPI(userInfo.id,credentials);

      if (status === 200) {
        console.log("-----------", data);
        localStorage.setItem("userInfo", JSON.stringify(data));
        navigate("/dashboard");
      }
      setLoader(false); */
    } catch (error) {
      console.log(error)
    }
  };
  return (
    <>
      <div className="login"> 
        {/* <AuthHeader/> */}
        <div className="forget-section">
          <Col>
            <Form
              className="login-form"
              name="basic"
              onFinish={onFinish}
              layout="vertical"
              style={{ marginTop: "0px" }}
            >
              <div className="input-field">
                <h4>Change Password</h4>
                <Form.Item
                  label="Old Password:"
                  name="oldPassword"
                  rules={[
                    { required: true, message: "Please input your OldPassword!" },
                    
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
                <Form.Item
                  label="Password   "
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name= "confirmpassword"
                  rules={[
                    { required: true, message: "Please input your Confirm Password!" },
                    
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
                <DefaultButton
                  className="colored_btn"
                  title={"Submit"}
                  loading={loader}
                  htmlType={"submit"}
                />
              </div>
            </Form>
          </Col>
        </div>
      </div>
    </>
  );
}
export default ChangePassword;
