import { Col, Row } from "antd";
import React, { useState } from "react";
import { Steps, Input, Form, DatePicker, RangePicker } from "antd";

export default function BasicDetails({ form, nextStep }) {
  const { RangePicker } = DatePicker;
  return (
    <Form form={form} onFinish={nextStep}>
      <Row className="form-content">
        <Col lg={24}>
          <Form.Item
            label="Start Date"
            name="start_date"
            rules={[{ required: true, message: "Start Date is required" }]}
          >
            <DatePicker placeholder="Start-Date" />
          </Form.Item>
        </Col>

        <Col lg={24}>
          <Form.Item
            label="End Date"
            name="end_date"
            rules={[{ required: true, message: "End Date is required" }]}
          >
            <DatePicker placeholder="End-Date" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
