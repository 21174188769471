import { axiosApi } from "../axios/axios_config";
export async function getScentaware(testTube, pagination, filter, studyNumber, participentId) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  try {
    // Construct the base endpoint
    let endpoint = `scentaware?testTube=${testTube}&userId=${userInfo.id}`;
    // Add filter parameters if filter is present and not empty
    // if (filter?.filterBy && filter?.filterValue) {
    //   endpoint += `&filterBy=${filter.filterBy}&filterValue=${filter.filterValue}`;
    // }
    // if (filter) {
    // const filterObject = JSON.parse(filter);
    // if (filter && Object.keys(filter).length > 0) {
    //   // endpoint += `&filterBy=${filter.filterBy}&filterValue=${filter.filterValue}`;
    //   endpoint += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    // }
    // }
    let body = {};
    if (filter && Object.keys(filter).length > 0) {
      // endpoint += `&filterBy=${filter.filterBy}&filterValue=${filter.filterValue}`;

      const filterObj = {}
      if (filter?.UserId && filter?.UserId.length) {
        filterObj['UserId'] = filter.UserId;
      }
      if (filter?.StudyNumber && filter?.StudyNumber.length) {
        filterObj['StudyNumber'] = filter.StudyNumber;
      }
      if (filter?.PatientId && filter?.PatientId.length) {
        filterObj['PatientId'] = filter.PatientId;
      }
      if (filter?.createdDateRange) {
        filterObj['createdDateRange'] = filter.createdDateRange;
      }
      if (filter?.tubeId) {
        filterObj['tubeId'] = filter.tubeId;
      }
      body = JSON.stringify({ filter: filterObj });
    }

    //if (filterObject && filterObject.filterBy !== null && filterObject.filterBy !== undefined &&
    //     filterObject.filterBy !== '' && filterObject.filterValue !== null && filterObject.filterValue !== undefined &&
    //     filterObject.filterValue !== '' ){
    //   endpoint += `&filter=${encodeURIComponent(filter)}`;
    // }

    // Add pagination parameters if pagination is present and not empty
    if (pagination?.pageIndex && pagination?.pageSize) {
      endpoint += `&pageIndex=${pagination.pageIndex}&pageSize=${pagination.pageSize}`;
    }
    if (studyNumber) {
      endpoint += `&studyNumber=${studyNumber}`;
    } if (participentId) {
      endpoint += `&participentId=${participentId}`;
    }

    const { data, response } = await axiosApi({
      endpoint: endpoint,
      method: "POST",
      bodyData: body ? body : null,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
    return { data, response };
  } catch (err) {
    console.error(`Error in getScentaware: ${err.message}`);
    throw err;
  }
}

export async function getScentawareTestStats() {
  try {
    const { data, response } = await axiosApi({
      endpoint: `scentaware/stats`,
      method: "GET",
      bodyData: null,
    });

    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function getScentwareFilterOption(studyNumber) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  try {
    let endpoint = `scentaware/getscentwarefilteroption?userId=${userInfo.id}`
    let body = {}
    if(studyNumber){
      body = JSON.stringify({ studyNumber });
    }
    
    const { data, response } = await axiosApi({
      endpoint: endpoint,
      method: "POST",
      bodyData: body,
    });

    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function getScentawareGraph() {
  try {
    const { data, response } = await axiosApi({
      endpoint: `scentaware/graph-stats`,
      method: "GET",
      bodyData: null,
    });

    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function getScentawareAnswers(testTube) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `scentaware/answers`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}
