/** @format */

import React, { useEffect, useState, useRef } from "react";
import * as scentawareApi from "../../services/api/scentaware";
import { Link, useParams } from "react-router-dom";
// import tableExport from "antd-table-export";
import { useDownloadExcel } from "react-export-table-to-excel";
import moment from "moment";
import CustomTable from "../../components/Table/CustomTable";
import useDebounce from '../../components/Table/debounce';
import { useLocation } from 'react-router-dom';

const ScentAwareTestLogs = () => {
  const { id: studyNumber } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const participentId = queryParams.get("participentId");

  const columns = [
    {
      Header: "Serial No",
      accessor: "Id",
    },
    {
      Header: "StudyNumber",
      accessor: "StudyNumber",
    },
    {
      Header: "Researcher/PI Id",
      accessor: "UserId",
    },
    {
      Header: "Participant Id",
      accessor: "PatientId",
    },
    {
      Header: "SessionId",
      accessor: "SessionId",
    },
    {
      Header: "TestType",
      accessor: "TestType",
    },
    {
      Header: "TubeId",
      accessor: "TubeId",
    },
    {
      Header: "TubeAnswer",
      accessor: "tubeAnswer",
    },
    {
      Header: "UserResponse",
      accessor: "SelectedAnswer",
    },

    // {
    //   Header: "isCorrect",
    //   accessor: "isCorrect",
    // },
    // {
    //   Header: "IncorrectSmell",
    //   accessor: "IncorrectSmell",
    // },
    {
      Header: "Score",
      accessor: "score",
    },
    {
      Header: "Total Score",
      accessor: "totalScore",
    },
    {
      Header: "ResponseTime",
      accessor: "AverageResponseTime",
    },
    {
      Header: "Scan To Scan Time",
      accessor: "ScanToScanTime",
    },
    {
      Header: "StuffyNose",
      accessor: "IsStuffyNose",
      Cell: ({ value }) => (value ? "TRUE" : "FALSE"),
    },
    {
      Header: "Is Medication",
      accessor: "IsMedication",
    },
    {
      Header: "DateTime",
      accessor: "CreatedDate",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState({});

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0
  });
  // const [filterOption, setFilterOption] = useState({})

  // const getScentAwareData = async () => {
  //   try {
  //     setLoading(true);
  //     // const filterString = JSON.stringify(filter);
  //     const { totalCount, tubeIdData } = await fetchScentAwareData();

  //     const scentwareFilterOption = await scentawareApi?.getScentwareFilterOption();
  //     setFilterOption(scentwareFilterOption.data.data)
  //     setPagination((state) => ({ ...state, totalCount: totalCount }));

  //     setData(tubeIdData);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error);
  //   }
  // };

  const getScentAwareData = async () => {
    try {
      setLoading(true);

      // Create an array of promises
      const promises = [
        fetchScentAwareData('', pagination, filter, studyNumber, participentId),
        // scentawareApi?.getScentwareFilterOption()
      ];

      // Use Promise.all to execute them in parallel
      const [scentAwareDataResult] = await Promise.all(promises);

      // Destructure totalCount and tubeIdData from the first call result
      const { totalCount, data } = scentAwareDataResult;

      // Get the data from the second call result
      // const scentwareFilterOption = scentwareFilterOptionResult.data.data;

      // Set state based on the results
      
      // setFilterOption(scentwareFilterOption);
      setPagination((state) => ({ ...state, totalCount: totalCount }));
      setData(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };


  const fetchScentAwareData = async (testTube, pagination, filter, studyNumber, participentId) => {
    var {
      data: { data, totalCount },

    } = await scentawareApi?.getScentaware(testTube, pagination, filter, studyNumber, participentId);
    if (studyNumber) {
      data = data.filter((c) => c.StudyNumber == studyNumber);
    }
    const answerRes = await scentawareApi?.getScentawareAnswers();
    const answers = answerRes.data.data;


    const tubeIdData = [];

    data.forEach((el) => {
      let tubeIdVal = el.TubeId;
      let tubeIdArr = tubeIdVal.split(",");

      let selectedAns = el.SelectedAnswer;
      var ans = selectedAns.split(",");

      let scoreList = [];
      let totalScore1 = 0;

      // filter with 1 condition
      // const filtered = data.filter((obj) => {
      //   return obj.SessionId == el.SessionId;
      // });
      //console.log("Filtered Data :", filtered.length);

      tubeIdArr.forEach((value, index) => {
        let correctAnswerObj = answers.find(
          (x) => parseInt(x.TubeId) == parseInt(value)
        );
        let tubeAnswer =
          correctAnswerObj && correctAnswerObj != "undefined"
            ? correctAnswerObj.Answer
            : "";
        let objIndex = scoreList.findIndex((x) => x.tubeId == value);

        if (objIndex != -1) {
          scoreList[objIndex].score += tubeAnswer == ans[index] ? 1 : 0;
        } else {
          let scoreObj = { tubeId: value };
          scoreObj.score = tubeAnswer == ans[index] ? 1 : 0;
          scoreList.push(scoreObj);
        }

        if (tubeAnswer == ans[index]) {
          totalScore1++;
        }
      });

      tubeIdArr.forEach((value, index) => {
        let correctAnswerObj = answers.find(
          (x) => parseInt(x.TubeId) == parseInt(value)
        );
        let tubeAnswer =
          correctAnswerObj && correctAnswerObj != "undefined"
            ? correctAnswerObj.Answer
            : "";
        let scoreIndex = scoreList.findIndex((x) => x.tubeId == value);

        /*if (el.TestType === "eight" && el.TotalScore <= 8) {
          TotalType = "8";
        } else if (el.TestType === "eight" && el.TotalScore <= 16) {
          TotalType = "16";
        } else if (el.TestType === "sixteen" && el.TotalScore <= 16) {
          TotalType = "16";
        } else {
          TotalType = "32";
        }*/

        let newObj = {
          Id: el.Id,
          TubeId: value,
          TestType: el.TestType,
          TestType: el.TestType,
          StudyNumber: el.StudyNumber,
          PatientId: el.PatientId,
          SessionId: el.SessionId,
          CreatedDate: moment(el.CreatedDate).format("MM/DD/Y HH:mm:ss"),
          // "SelectedAnswer": ans[index],
          SelectedAnswer: el.SelectedAnswer,
          AverageResponseTime: el.AverageResponseTime,
          UserId: el.UserId,
          PatientId: el.PatientId,
          IsStuffyNose: el.IsStuffyNose,
          tubeAnswer: el.CorrectAnswer,
          // "isCorrect": (tubeAnswer == ans[index])? "TRUE":"FALSE",
          isCorrect: el.IsAnswerCorrect,
          IncorrectSmell:
            el.CorrectAnswer != el.SelectedAnswer ? el.CorrectAnswer : "",
          score:
            typeof scoreList[scoreIndex] == "undefined"
              ? ""
              : scoreList[scoreIndex].score,
          totalScore: el.TotalScore,
          // totalScore: el.TotalScore + "/" + filtered.length,
          ScanToScanTime: el.ScanToScanTime,
          IsMedication: el.IsMedication,
        };
        tubeIdData.push(newObj);
      });
    });

    return { data : tubeIdData, totalCount };
  }

  const debouncedFilterValue = useDebounce(filter.filterValue, 300);

  const handleSearchFilter = (e) => {
    setFilter((state) => ({ ...state, filterValue: e.target.value }))
  };

  useEffect(() => {
    if (debouncedFilterValue !== null) {
      getScentAwareData();
    }
  }, [currentPage, debouncedFilterValue]);

  useEffect(() => {
    if (filter) {
      getScentAwareData();
    }
  }, [filter])


  var returnPath = "/dashboard";
  if (studyNumber) {
    returnPath = "/dashboard/" + studyNumber + "?type=clinicalTrialId";
  }


  return (
    <>
      <CustomTable
        columns={columns}
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
        setCurrentPage={setCurrentPage}
        setFilter={setFilter}
        filter={filter}
        // filterOption={filterOption}
        totalCount={pagination.totalCount}
        fetchData={fetchScentAwareData}
        type={'scentAware'}
      />
    </>

  );
};

export default ScentAwareTestLogs;
