import "./App.css";
import Right from "../src/assets/images/Right.png";
import Header from "./components/Dashboard/Header";
import Spreedsheet from "./components/Spreedsheet";
import React, { useEffect, useState } from "react";

import Home from "../src/pages/Home";
import Scentaware from "../src/pages/Scentaware";
import Traceaware from "../src/pages/Traceaware";
import Audioaware from "../src/pages/Audioaware";
import Login from "../src/pages/Login";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  redirect,
  Navigate,
} from "react-router-dom";
import ClinicalForm from "./pages/ClinicalTrial/ClinicalForm";
import FinishForm from "./pages/ClinicalTrial/FinishForm";
import ForgetPassword from "./pages/ForgetPassword";
import ChangePassword from "./pages/ChangePassword";
import ProtectedRoute from "./components/utils/ProtectedRoute";
import AddUser from "./pages/AddUser";
import GlanceAware from "./pages/GlanceAware";
import EightScentTest from "./pages/Scentaware_Tests/Eight-Scent";
import SixteenScentTest from "./pages/Scentaware_Tests/Sixteen-Scent";
import AdminLayout  from "./layouts/admin";
import TempwareLayout from "./layouts/TempwareLayout";
import AuthLayout from "./layouts/auth";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="/*" element={<AuthLayout />} />

        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/tempware/*"
          element={
            <ProtectedRoute>
              <TempwareLayout />
            </ProtectedRoute>
          }
        /> */}

        {/* ========================== */}

        {/* <Route path="/" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/change-password" element={<ChangePassword />} /> */}

        {/* <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        /> */}

        {/* <Route
          path="/clinical-trial"
          element={
            <ProtectedRoute>
              <ClinicalForm />
            </ProtectedRoute>
          }
        />

        <Route
          path="/scentaware"
          element={
            <ProtectedRoute>
              <Scentaware />
            </ProtectedRoute>
          }
        />

        <Route
          path="/traceaware"
          element={
            <ProtectedRoute>
              <Traceaware />
            </ProtectedRoute>
          }
        />

        <Route
          path="/audioaware"
          element={
            <ProtectedRoute>
              <Audioaware />
            </ProtectedRoute>
          }
        />

        <Route
          path="/add-user/:Id"
          element={
            <ProtectedRoute>
              <AddUser />
            </ProtectedRoute>
          }
        />

        <Route
          path="/glanceaware"
          element={
            <ProtectedRoute>
              <GlanceAware />
            </ProtectedRoute>
          }
        />
        <Route
          path="/scentaware/eight-scent"
          element={
            <ProtectedRoute>
              <EightScentTest />
            </ProtectedRoute>
          }
        />
        <Route
          path="/scentaware/sixteen-scent"
          element={
            <ProtectedRoute>
              <SixteenScentTest />
            </ProtectedRoute>
          }
        /> */}
      </Routes>
    </>
  );
}

export default App;
