/** @format */

import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import AreaChart from "../../components/Charts/AreaChart";
import axios from "axios";
import { groupBy } from "underscore";
import moment from "moment";
import { useParams } from 'react-router-dom';
import { API_URL } from '../../services/axios/axios_config'
import * as scentawareApi from "../../services/api/scentaware";
import * as wordsAwareApi from "../../services/api/wordsaware";
import * as traceAwareApi from "../../services/api/traceaware";
import * as audioAwareApi from "../../services/api/audioware";
import * as glanceAwareApi from "../../services/api/glanceaware";
import * as TrainingDataApi from "../../services/api/trainingdata";
import { useLocation } from 'react-router-dom';
import JSZip from 'jszip';
import Charts from "../../components/Charts/HighCharts";
import Highcharts, { color } from 'highcharts';
import * as adminApi from "../../services/api/admin";

const UserGraphs = () => {
  const [visualShortTermMemoryData, setVisualShortTermMemoryData] = useState([]);
  const [audio, setAudio] = useState([]);
  const [traceaware, setTraceaware] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const [olfactory, setOlfactory] = useState([]);
  const [sixteenOlfactory, setSixteenOlfactory] = useState([]);
  const [eightx, setEightx] = useState([]);
  const [createdDates, setCreatedDates] = useState([]);
  const [memory, setMemory] = useState([]);
  const [createdDateSixteen, setCreatedDateSixteen] = useState([]);
  const [glanceAware, setGlanceAware] = useState([]);
  const [grammer, setGrammer] = useState([]);
  const [words, setWords] = useState([]);
  const [show8Tube, setShow8Tube] = useState(true);
  const [show16Tube, setShow16Tube] = useState(true);


  //states for downloading csv 
  const [scentAwareCsv, setScentAwareData] = useState([]);
  const [wordsAwareCsv, setWordsAwareData] = useState([]);
  const [traceAwareCsv, setTraceAwareData] = useState([]);
  const [audioAwareCsv, setAudioAwareData] = useState([]);
  const [glanceAwareCsv, setGlanceAwareData] = useState([]);
  const [trainingDataCsv, setTrainingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState(false);
  const [role, setRole] = useState('')
  const [allTestTypeForUser, setAllTestTypeForUser] = useState([])

  let userInfo = null;
  let clinicalTrialId = '';
  let studyNumber = '';
  let participentId = '';
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  let { id } = useParams();

  if (type === "participantId") {
    // Handle data for participant scenario
    const participentIdParam = queryParams.get("participentId");
    participentId = participentIdParam;
    userInfo = { id: parseInt(id) };
  } else {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  }

  if (type === "clinicalTrialId") {
    // Handle data for clinical trial scenario
    studyNumber = id;
  }

  // if (id) {
  //   userInfo = { id: parseInt(id) };
  // } else {

  // }

  useEffect(() => {
    const userRole = JSON.parse(localStorage.getItem("userInfo"));
    setRole(userRole.role);

    fetchAllData(userRole)

  }, []);

  const fetchAllData = async (userRole) => {
    if (userRole.role == "Admin" || userRole.role === "PI") {
      const { data: { data } } = await adminApi.getClinicalTrailWithCount();
      if(!data || !data.length){
        return;
      }
      if(studyNumber){
        const clinicaltrailTestType = data.filter(x => x.StudyNumber === studyNumber).map(y => JSON.parse(y.TestType));
        setAllTestTypeForUser(clinicaltrailTestType[0]);
      }
      else{
        const allTestTypeForUser = data.map(x => JSON.parse(x.TestType));
        setAllTestTypeForUser(allTestTypeForUser[0]);
      }
     
    }

    axios
      .get(
        `${API_URL}/scentaware/olfactoryxaxis?userId=${userInfo.id}&studyNumber=${studyNumber}&participentId=${participentId}`
      )
      .then((res) => {
        const data = res.data.map((c) =>
          getFormattedDate(c.CreatedDate.slice(0, 10))
        );
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Months are zero-based

        const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);
        const xaxis = Array.from({ length: daysInCurrentMonth }, (_, i) => (i + 1).toString());

        setXaxis(xaxis);

        setTimeout(() => {
          fetch(data);
          marksEight(data); //done
          marksSixteen(data); //done
          traceAwareGraphData(); //done
          visualShortTermMemory(); //done
          audioAware(); //done
          faceAware();  //done
          wordsAware();  //done
          grammerAware(); //done
          visualShortTermMemoryGraph(); //done
        }, 100);
      });
  }

  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  function visualShortTermMemoryGraph() {
    axios
      .get(
        `${API_URL}/traceaware/visualShortTerMemoryGraphData?userId=${userInfo.id}&clinicalTrialId=${clinicalTrialId}`
      )
      .then((res) => {
        let arrayObj = res.data.map((item) => {
          return {
            CreatedDate: item.CreatedDate.slice(0, 10),
            average: parseInt((item.average * 100).toFixed(0)),
          };
        });

        setVisualShortTermMemoryData(arrayObj);
      });
  }
  function grammerAware() {
    axios
      .get(
        `${API_URL}/grammaraware/grammerAware?userId=${userInfo.id}&clinicalTrialId=${clinicalTrialId}`
      )
      .then((res) => setGrammer(res.data));
  }
  function wordsAware() {
    axios
      .get(
        `${API_URL}/wordsaware/wordsAware?userId=${userInfo.id}&clinicalTrialId=${clinicalTrialId}`
      )
      .then((res) => setWords(res.data));
  }
  function faceAware() {
    axios
      .get(
        `${API_URL}/glanceaware/glanceAwareGraphData?userId=${userInfo.id}&clinicalTrialId=${clinicalTrialId}`
      )
      .then((res) => {
        if (res.data.length) {
          const result = res.data.map((c) => {
            /* const arr1 = c.TrueNames.split(",");
            const match1 = c.SelectedNames.split(",");
            const arr2 = c.TrueProfession.split(",");
            const match2 = c.SelectedProfession.split(","); */
            let count = 0;
            if (c.IsProfessionCorrect == true && c.IsNameCorrect == true) {
              count = 1;
            }

            /* arr1.forEach((item, index) => {
              if (item === match1[index] && arr2[index] === match2[index]) {
                count++;
              }
            }); */

            const date = new Date(c.CreatedDate.slice(0, 10));

            return {
              CreatedDate: getFormattedDate(c.CreatedDate.slice(0, 10)),
              count,
            };
          });

          const groups = groupBy(result, "CreatedDate");
          const averageWiseData = Object.keys(groups).map((e) => {
            const total = groups[e].reduce(function (x, y) {
              return x + y.count;
            }, 0);

            return {
              key: e,
              average: total, // / groups[e].length,
            };
          });

          setGlanceAware(averageWiseData);
        }
      });
  }

  function getFormattedDate(date) {
    return moment(date).format("MM-DD-Y");
    // return `${new Date(date).getDate()}-${
    //   new Date(date).getMonth() + 1
    // }-${new Date(date).getFullYear()}`;
  }

  function audioAware() {
    axios
      .get(
        `${API_URL}/audioaware/audioaware?userId=${userInfo.id}&clinicalTrialId=${clinicalTrialId}`
      )
      .then((res) => {
        setAudio(res.data)
      });
  }

  function visualShortTermMemory() {
    axios
      .get(
        `${API_URL}/traceaware/processingSpeed?userId=${userInfo.id}&clinicalTrialId=${clinicalTrialId}`
      )
      .then((res) => {
        if (res.data.length > 0) {
          setMemory(res.data);
        }
      });
  }

  function traceAwareGraphData() {
    axios
      .get(
        `${API_URL}/traceaware/traceawareDataAdmin?userId=${userInfo.id}&clinicalTrialId=${clinicalTrialId}`
      )
      .then((res) => {
        setTraceaware(res.data);
      });
  }

  function marksSixteen(data) {
    axios
      .get(
        `${API_URL}/scentaware/olfactorySixteen?userId=${userInfo.id}&studyNumber=${studyNumber}&participentId=${participentId}`
      )
      .then((res) => {
        const result = calculateEightScore(res.data, data)
        const allDatesresult = fillMissingDates(result)
        setSixteenOlfactory(allDatesresult)
      });
  }
  function marksEight(data) {
    axios
      .get(
        `${API_URL}/scentaware/olfactoryMarks?userId=${userInfo.id}&studyNumber=${studyNumber}&participentId=${participentId}`
      )
      .then((res) => {
        const result = calculateEightScore(res.data, data)
        const allDatesresult = fillMissingDates(result)
        setOlfactory(allDatesresult);
      });
  }

  function fillMissingDates(inputArray) {
    const currentDate = new Date();
    // const currentDate = moment();
    const firstDayOfMonth = moment(currentDate).startOf('month');
    const lastDayOfMonth = moment(currentDate).endOf('month');
    const allDatesInRange = [];

    for (let currentDate = moment(firstDayOfMonth); currentDate <= lastDayOfMonth; currentDate.add(1, 'day')) {
      const formattedDate = currentDate.format("MM-DD-Y");
      allDatesInRange.push(formattedDate);
    }

    const resultArray = allDatesInRange.map(date => {
      const formatedDate = getFormattedDate(date)
      const existingItem = inputArray.find(item => item.key === formatedDate);
      return existingItem ? existingItem : { key: formatedDate, average: 0 };
    });

    return resultArray;
  }

  function calculateEightScore(data, xaxisData) {
    const result = data.map((item) => ({
      ...item,
      CreatedDate: getFormattedDate(item.CreatedDate.slice(0, 10)),
      //  marks: calculateMarks(item.SelectedAnswer, item.TubeId),
    }));

    const groups = groupBy(result, "CreatedDate");

    const fianlResult = Object.keys(groups).map((key) => {
      const average = Math.round(
        groups[key].reduce(function (a, b) {
          if (b["IsAnswerCorrect"] == true) {
            return a + 1;
          } else {
            return a;
          }
        }, 0)
        /// groups[key].length
      );
      return { [key]: average };
    });

    return xaxisData.map((c) => {
      return {
        key: c,
        average: fianlResult.map((d) => Object.keys(d)[0]).includes(c)
          ? fianlResult.find((k) => Object.keys(k)[0] === c)[c]
          : 0,
      };
    });
  }

  function fetch(xaxisData) {
    axios
      .get(
        `${API_URL}/scentaware/olfactoryAdmin?userId=${userInfo.id}&studyNumber=${studyNumber}`
      )
      .then((res) => {
        let createdDates = res.data[0]?.eight.map((c) => c.CreatedDate)
        setCreatedDates(createdDates);

        let createdDateSixteen = xaxisData.map((c) =>
          res.data[1].sixteen.find((k) => {
            const formatedDate = getFormattedDate(k.CreatedDate.slice(0, 10))
            return formatedDate === c;
          }
          )
            ? res.data[1].sixteen.find((k) => {
              const formatedDate = getFormattedDate(k.CreatedDate.slice(0, 10))
              return formatedDate === c;
            }).AverageResponseTime.toFixed(0)

            : 0
        )
        setCreatedDateSixteen(createdDateSixteen);

        let createdDatesEight = xaxisData.map((c) =>
          res.data[0]?.eight.find(
            (k) => getFormattedDate(k.CreatedDate.slice(0, 10)) === c
          )
            ? res.data[0]?.eight
              .find(
                (k) => getFormattedDate(k.CreatedDate.slice(0, 10)) === c
              )
              .AverageResponseTime.toFixed(0)
            : 0
        )
        setEightx(createdDatesEight);
      });
  }

  const tubeAnswers = {
    "000001": "Banana",
    "000002": "Rose",
    "000003": "Lemon",
    "000004": "Fish",
    "000005": "Garlic",
    "000006": "Cinnamon",
    "000007": "Eucalyptus",
    "000008": "Orange",
    "000009": "Coffee",
    "000010": "Peppermint",
    "000011": "No Odor detected",
    "000012": "Peppermint",
    "000013": "Rose",
    "000014": "Cloves",
    "000015": "Peppermint",
    "000016": "Coffee",
  };

  const calculateMarks = (selectedAnswer, tubes) => {
    const answers = selectedAnswer.split(",");
    const tubeids = tubes.split(",");
    let i = 0;
    return tubeids.reduce((acc, cur) => {
      if (tubeAnswers[cur] === answers[i]) {
        acc++;
      }
      i++;
      return acc;
    }, 0);
  };
  const [isOlf, setIsOlf] = useState(true);
  const [isExe, setIsExe] = useState(false);

  const handleClickOlf = () => {
    setIsOlf(true);
    setIsExe(false);
  };
  const handleClickExe = () => {
    setIsExe(true);
    setIsOlf(false);
  };
  let visualShortTermMemoryGraphData = [
    {
      name: "TraceAware",
      //data: visualShortTermMemoryData.map((c) => c.average.toFixed(2)),
      data: visualShortTermMemoryData.map((c) =>
        Math.round(c.average).toFixed(0)
      ),
    },
  ];

  let visualShortTermMemoryGraphOptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },

    dataLabels: {
      enabled: false,
    },

    grid: {
      show: true, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },

    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.8,
        opacityFrom: 0.9,
        opacityTo: 0.2,
        gradientToColors: ["#33C9FC", "#FFFFFF"],
      },
    },

    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: ['#5800FF'],
      width: 3,
      dashArray: 0,
    },
    zoom: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      categories: visualShortTermMemoryData.map((c) => c.CreatedDate),
      // categories: words.map((c) => getFormattedDate(c.dates.slice(0, 10))),
      labels: {
        show: true,

        rotate: 0,
        hideOverlappingLabels: true,
        format: "MM-dd-yyyy",
        formatter: function (value, timestamp) {
          return getFormattedDate(timestamp); // The formatter function overrides format property
        },

        style: {
          colors: "black",
          fontSize: "12px",
          fontFamily: "Work Sans, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },

    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "black",
          fontSize: "12px",
          fontFamily: "Work Sans, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
      },

      title: {
        text: "Accuracy (%)",
        rotate: 270,
        style: {
          color: "black",
          fontSize: "13px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-title",
        },
      },

      axisBorder: {
        show: true,
        color: "black",
        offsetX: 0,
        offsetY: 0,
      },
    },
  };

  let visualShortTerm = [
    {
      name: "TraceAware",
      data: memory.map((c) => Math.round(c.Average).toFixed(0)),
    },
  ];

  let visualShortTermOptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },

    grid: {
      show: true, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },

    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.8,
        opacityFrom: 0.9,
        opacityTo: 0.2,
        gradientToColors: ["#33C9FC", "#FFFFFF"],
      },
    },

    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: ['#5800FF'],
      width: 3,
      dashArray: 0,
    },

    xaxis: {
      type: "datetime",
      categories: memory.map((c) =>
        getFormattedDate(c.CreatedDate.slice(0, 10))
      ),
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,

        formatter: function (value, timestamp) {
          return getFormattedDate(value); // The formatter function overrides format property
        },
        style: {
          colors: "black",
          fontSize: "12px",
          fontFamily: "Work Sans, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },

    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "black",
          fontSize: "12px",
          fontFamily: "Work Sans, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
      },

      title: {
        text: "Accuracy (%)",
        rotate: 270,
        style: {
          color: "black",
          fontSize: "13px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-title",
        },
      },

      axisBorder: {
        show: true,
        color: "black",
        offsetX: 0,
        offsetY: 0,
      },
    },
  };

  let motorAwareData = [
    {
      name: "TraceAware",
      // data: traceaware.map((c) => c.Average.toFixed(2)),
      data: traceaware.map((c) => Math.round(c.motor).toFixed(0)),
    },
  ];

  let motorAwareOptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },

    grid: {
      show: true, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },

    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.8,
        opacityFrom: 0.9,
        opacityTo: 0.2,
        gradientToColors: ["#33C9FC", "#FFFFFF"],
      },
    },

    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: ['#5800FF'],
      width: 3,
      dashArray: 0,
    },

    xaxis: {
      type: "datetime",
      categories: traceaware.map(
        (c) => getFormattedDate(c.CreatedDate.slice(0, 10))
        // getFormattedDate(moment(c.CreatedDate).format("MM:DD:Y hh:mm").slice(0, 10))
      ),
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,
        // format: 'MM-dd-yyyy',
        formatter: function (value, timestamp) {
          return getFormattedDate(value); // The formatter function overrides format property
        },
        style: {
          colors: "black",
          fontSize: "18px",
          fontFamily: "Work Sans, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },

    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "black",
          fontSize: "18px",
          fontFamily: "Work Sans, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
      },

      title: {
        text: "Accuracy (%)",
        rotate: 270,
        style: {
          color: "black",
          fontSize: "13px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-title",
        },
      },

      axisBorder: {
        show: true,
        color: "black",
        offsetX: 0,
        offsetY: 0,
      },
    },
  };

  let audioAwareOptions = {
    chart: {
      type: 'column',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: audio.map((c) => getFormattedDate((c.dates))),
      gridLineWidth: 0,
      plotLines: [
        {
          color: 'black',
          width: 1,
          value: -0.5,
        },
      ],
    },
    yAxis: {
      title: {
        text: "Score",
        style: {
          color: "black",
          fontSize: "18px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      gridLineWidth: 0,
    },
    plotOptions: {
      column: {
        pointWidth: 15,
        borderRadius: 15,
      },
    },
    series: [
      {
        name: "AudioAware",
        data: audio.map((c) => c.Average),
        color: '#5800FF'
      },
    ]
  };

  let glanceAwareOptions = {
    chart: {
      type: 'column',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: glanceAware.map((c) => c.key),
      gridLineWidth: 0,
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,
        formatter: function (value, timestamp) {
          return getFormattedDate(value);
        },
        style: {
          colors: "black",
          fontSize: "18px",
          fontFamily: "Work Sans, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
      },
      plotLines: [
        {
          color: 'black',
          width: 1,
          value: -0.5,
        },
      ],
    },
    yAxis: {
      title: {
        text: "Score",
        style: {
          color: "black",
          fontSize: "18px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      gridLineWidth: 0,
    },
    plotOptions: {
      column: {
        pointWidth: 15,
        borderRadius: 15,
      },
    },
    series: [
      {
        name: "GlanceAware",
        data: glanceAware.map((c) => parseFloat(c.average.toFixed(2))),
      },
    ]
  };

  let wordsAwareData = [
    {
      name: "WordsAware",
      data: words.map((c) => c.Average),
    },
  ];

  let wordsAwareOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.8,
        opacityFrom: 0.9,
        opacityTo: 0.2,
        gradientToColors: ["#33C9FC", "#FFFFFF"],
      },
    },

    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: ['#5800FF'],
      width: 3,
      dashArray: 0,
    },
    grid: {
      show: true, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },

    xaxis: {
      type: "datetime",
      categories: words.map((c) => getFormattedDate(c.dates.slice(0, 10))),
      labels: {
        rotate: 0,
        hideOverlappingLabels: true,
        // format: 'MM-dd-yyyy',
        formatter: function (value, timestamp) {
          return getFormattedDate(value); // The formatter function overrides format property
        },
      },
      style: {
        colors: "black",
        fontSize: "11px",
        fontFamily: "Work Sans, sans-serif",
        cssClass: "apexcharts-xaxis-label",
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "black",
          fontSize: "12px",
          fontFamily: "Work Sans, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
      },
      axisBorder: {
        show: true,
        color: "black",
        offsetX: -1,
        offsetY: 0,
      },
      title: {
        text: "Score",
        rotate: 270,
        style: {
          color: "black",
          fontSize: "13px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },
  };

  let grammarAwareData = [
    {
      name: "GrammarAware",
      data: grammer.map((c) =>
        c.Average ? parseFloat(c.Average.toFixed(0)) : 0
      ),
    },
  ];

  let grammarAwareOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.8,
        opacityFrom: 0.9,
        opacityTo: 0.2,
        gradientToColors: ["#33C9FC", "#FFFFFF"],
      },
    },

    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: ['#5800FF'],
      width: 3,
      dashArray: 0,
    },
    grid: {
      show: true, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },
    xaxis: {
      type: "datetime",
      categories: grammer.map((c) =>
        getFormattedDate(c.CreatedDate.slice(0, 10))
      ),
      labels: {
        rotate: 0,
        hideOverlappingLabels: true,
        formatter: function (value, timestamp) {
          return getFormattedDate(value);
        },
      },
      style: {
        colors: "black",
        fontSize: "12px",
        fontFamily: "Work Sans, sans-serif",
        cssClass: "apexcharts-xaxis-label",
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "black",
          fontSize: "12px",
          fontFamily: "Work Sans, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
      },
      axisBorder: {
        show: true,
        color: "black",
        offsetX: -1,
        offsetY: 0,
      },
      title: {
        text: "Accuracy (%)",
        rotate: 270,
        style: {
          color: "black",
          fontSize: "13px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },
  };

  const executiveFunctionOptions = {
    chart: {
      type: 'column',
      height: 350,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      categories: xaxis,
      labels: {
        style: {
          color: 'black',
          fontSize: '12px',
          fontFamily: 'Work Sans',
        },
      },
      title: {
        text: Highcharts.dateFormat('%b %Y', new Date()),
        style: {
          color: 'black',
          fontSize: '18px',
          fontFamily: 'Work Sans',
        },
      },
      gridLineWidth: 0,
      plotLines: [
        {
          color: 'black',
          width: 1,
          value: -0.5,
        },
      ],
    },
    yAxis: {
      title: {
        text: 'response time (secs)',
        rotation: -90,
        style: {
          color: 'black',
          fontSize: '13px',
          fontFamily: 'Work Sans',
        },
      },
      labels: {
        align: 'right',
        style: {
          color: 'black',
          fontSize: '18px',
          fontFamily: 'Work Sans',
          fontWeight: 400,
        },
      },
      gridLineWidth: 0,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        borderRadius: 10,
        dataLabels: {
          enabled: false,
        },
      },
      column: {
        pointPadding: 0,
        borderWidth: 0,
        borderRadius: 10,
        pointWidth: 10 // Adjust this value to control the column width
      },
    },
    series: [
      {
        name: '8 Tube test kit',
        data: eightx.map(x => parseInt(x)),
        color: '#33C9FC',
        visible: show8Tube,
        showInLegend: false,
      },
      {
        name: '16 Tube test kit',
        data: createdDateSixteen.map(x => parseInt(x)),
        color: '#5800FF',
        visible: show16Tube,
        showInLegend: false,
      },
    ],
  };

  const olfactoryFunctionOptions = {
    chart: {
      type: 'column',
      height: 350,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: xaxis,
      labels: {
        style: {
          color: 'black',
          fontSize: '12px',
          fontFamily: 'Work Sans',
        },
      },
      title: {
        text: Highcharts.dateFormat('%b %Y', new Date()),
        style: {
          color: 'black',
          fontSize: '18px',
          fontFamily: 'Work Sans',
        },
      },
      gridLineWidth: 0,
      plotLines: [
        {
          color: 'black',
          width: 1,
          value: -0.5,
        },
      ],
    },
    yAxis: {
      title: {
        text: 'Score',
        style: {
          color: 'black',
          fontSize: '18px',
          fontFamily: 'Work Sans',
        },
      },
      labels: {
        style: {
          color: 'black',
          fontSize: '12px',
          fontFamily: 'Work Sans',
        },
      },
      gridLineWidth: 0,
    },

    plotOptions: {
      series: {
        stacking: 'normal',
        borderRadius: 10,
        dataLabels: {
          enabled: false,
        },
      },
      column: {
        pointPadding: 0,
        borderWidth: 0,
        borderRadius: 10,
        pointWidth: 10 // Adjust this value to control the column width
      },
    },

    series: [
      {
        name: '8 Tube test kit',
        data: olfactory.map((c) => parseFloat(c.average.toFixed(2))),
        color: '#00D7FF',
        visible: show8Tube,
        showInLegend: false,
      },
      {
        name: '16 Tube test kit',
        data: sixteenOlfactory.map((c) => parseFloat(c.average.toFixed(2))),
        color: '#5800FF',
        visible: show16Tube,
        showInLegend: false,
      },
    ],
  };

  const [value, setValue] = useState("val1");
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const downloadAllData = async (event) => {
    setLoading(true);
    await getScentAwareData();
    await getWordsAwareData();
    await getTraceAwareData();
    await getAudioAwareData();
    await getGlanceAwareData();
    await getTrainingData();
    setDownload(true);
  }

  useEffect(() => {
    if (download) {
      downloadAllCSVs()
      setDownload(false);
      setLoading(false);
    }
  }, [download]);

  const downloadAllCSVs = () => {
    const zip = new JSZip();
    const folder = zip.folder('CSV_Files'); // Create a folder within the ZIP archive

    let dataSets = [
      { data: scentAwareCsv, filename: 'scentAware.csv' },
      { data: wordsAwareCsv, filename: 'wordsAware.csv' },
      { data: traceAwareCsv, filename: 'traceAware.csv' },
      { data: audioAwareCsv, filename: 'audioAware.csv' },
      { data: glanceAwareCsv, filename: 'glanceAware.csv' },
      { data: trainingDataCsv, filename: 'trainingData.csv' },
    ];

    dataSets.forEach(({ data, filename }) => {
      if (data) {
        // Add the file to the folder
        folder.file(filename, convertArrayOfObjectsToCSV(data));
      } else {
      }
    });

    zip.generateAsync({ type: 'blob' }).then((content) => {
      const url = URL.createObjectURL(content);

      const tempElement = document.createElement('a');
      tempElement.href = url;
      tempElement.download = 'csvFiles.zip';
      tempElement.click();

      // Release the object URL to free up resources
      URL.revokeObjectURL(url);
    });
  };

  const getTrainingData = async () => {
    try {
      let { data: { data } } = await TrainingDataApi?.getTrainingData();
      if (id) {
        data = data.filter((c) => c.UserId == id);
      }
      let newData = [];
      data.map((element, index) => {
        let newObj = {
          ...element,
          ['Date']: moment(element.Date).format("MM/DD/Y HH:mm:ss"),
        }
        newData = [...newData, newObj];
      });
      setTrainingData(newData);
    }
    catch (error) {
      console.log("tableError", error)
    }
  };
  const convertArrayOfObjectsToCSV = (data) => {
    const csvRows = [];
    if (data.length > 0) {
      const headers = Object.keys(data[0]);

      // Add header row
      csvRows.push(headers.join(','));

      // Add data rows
      for (const row of data) {
        const values = headers.map((header) => row[header]);
        csvRows.push(values.join(','));
      }

      return csvRows.join('\n');
    }

  };

  const getScentAwareData = async () => {
    try {
      var {
        data: { data },
      } = await scentawareApi?.getScentaware();

      if (id) {
        data = data.filter((c) => c.UserId == id);
      }

      const answerRes = await scentawareApi?.getScentawareAnswers();
      const answers = answerRes.data.data;
      const tubeIdData = [];

      data.forEach((el) => {
        let tubeIdVal = el.TubeId;
        let tubeIdArr = tubeIdVal.split(",");

        let selectedAns = el.SelectedAnswer;
        var ans = selectedAns.split(",");

        let scoreList = [];
        let totalScore1 = 0;

        // filter with 1 condition
        const filtered = data.filter((obj) => {
          return obj.SessionId == el.SessionId;
        });

        tubeIdArr.forEach((value, index) => {
          let correctAnswerObj = answers.find(
            (x) => parseInt(x.TubeId) == parseInt(value)
          );
          let tubeAnswer =
            correctAnswerObj && correctAnswerObj != "undefined"
              ? correctAnswerObj.Answer
              : "";
          let objIndex = scoreList.findIndex((x) => x.tubeId == value);

          if (objIndex != -1) {
            scoreList[objIndex].score += tubeAnswer == ans[index] ? 1 : 0;
          } else {
            let scoreObj = { tubeId: value };
            scoreObj.score = tubeAnswer == ans[index] ? 1 : 0;
            scoreList.push(scoreObj);
          }

          if (tubeAnswer == ans[index]) {
            totalScore1++;
          }
        });

        tubeIdArr.forEach((value, index) => {
          let correctAnswerObj = answers.find(
            (x) => parseInt(x.TubeId) == parseInt(value)
          );
          let tubeAnswer =
            correctAnswerObj && correctAnswerObj != "undefined"
              ? correctAnswerObj.Answer
              : "";
          let scoreIndex = scoreList.findIndex((x) => x.tubeId == value);


          let newObj = {
            Id: el.Id,
            TubeId: value,
            TestType: el.TestType,
            TestType: el.TestType,
            SessionId: el.SessionId,
            CreatedDate: moment(el.CreatedDate).format("MM/DD/Y HH:mm:ss"),
            // "SelectedAnswer": ans[index],
            SelectedAnswer: el.SelectedAnswer,
            AverageResponseTime: el.AverageResponseTime,
            UserId: el.UserId,
            IsStuffyNose: el.IsStuffyNose,
            tubeAnswer: el.CorrectAnswer,
            // "isCorrect": (tubeAnswer == ans[index])? "TRUE":"FALSE",
            isCorrect: el.IsAnswerCorrect,
            IncorrectSmell:
              el.CorrectAnswer != el.SelectedAnswer ? el.CorrectAnswer : "",
            score:
              typeof scoreList[scoreIndex] == "undefined"
                ? ""
                : scoreList[scoreIndex].score,
            totalScore: el.TotalScore + "/" + filtered.length,
            ScanToScanTime: el.ScanToScanTime,
            IsMedication: el.IsMedication,
          };
          tubeIdData.push(newObj);
        });
      });

      setScentAwareData(tubeIdData);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getWordsAwareData = async () => {
    try {
      var {
        data: { data },
      } = await wordsAwareApi?.getWordsaware(id);

      if (id) {
        data = data.filter((c) => c.UserId == id);
      }

      data = data.map((c) => ({
        ...c,
        Date_Created: moment(c.Date_Created).format("MM/DD/Y HH:mm:ss"),
        LongestWord: c.Words.split(",")
          .filter((w) =>
            w.trim().toLowerCase().startsWith(c.ReferenceLetters.toLowerCase())
          )
          ?.reduce(function (a, b) {
            return a.trim().length > b.trim().length ? a : b;
          }, ""),
        ShortestWord: c.Words.split(",").filter((w) =>
          w.trim().toLowerCase().startsWith(c.ReferenceLetters.toLowerCase())
        )?.length
          ? c.Words.split(",")
            .filter((w) =>
              w
                .trim()
                .toLowerCase()
                .startsWith(c.ReferenceLetters.toLowerCase())
            )
            ?.reduce(function (a, b) {
              return a.trim().length <= b.trim().length ? a : b;
            })
          : "",
        matchingWords: c.Words.split(",")
          .filter((w) =>
            w.trim().toLowerCase().startsWith(c.ReferenceLetters.toLowerCase())
          )
          .join(","),

        uniqueWordsCount: c.Words.split(",")
          .filter((w) =>
            w.trim().toLowerCase().startsWith(c.ReferenceLetters.toLowerCase())
          )
          .filter(unique).length,
      }));

      setWordsAwareData(data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const unique = (item, index, array) => {
    return array.indexOf(item) == index;
  };

  const getTraceAwareData = async () => {
    try {
      var {
        data: { data },
      } = await traceAwareApi?.getTraceaware();

      var dataList = [];

      data.master.forEach((element) => {
        let averageResponseTime = (
          (new Date(element.SessionEndTime.replace("T", " ")).getTime() -
            new Date(element.SessionStartTime.replace("T", " ")).getTime()) /
          (element.total * 1000) || 0
        ).toFixed(2);
        let recall = Math.round(getPercentage(element.Id, data.child, 1));
        let copy = Math.round(getPercentage(element.Id, data.child, 0));

        let SessionStartTime = moment(element.SessionStartTime).format(
          "MM/DD/Y HH:mm:ss"
        );

        let SessionEndTime = moment(element.SessionEndTime).format(
          "MM/DD/Y HH:mm:ss"
        );

        let dt_created = moment(element.dt_created).format("MM/DD/Y HH:mm:ss");

        const childerens = data.child.filter((ch) => ch.DSSTId === element.Id);

        childerens.forEach((childEle) => {
          var newObj = JSON.parse(JSON.stringify(element));

          newObj.recall = recall;
          newObj.copy = copy;
          newObj.SessionStartTime = SessionStartTime;
          newObj.SessionEndTime = SessionEndTime;
          newObj.dt_created = dt_created;
          newObj.averageResponseTime = averageResponseTime;
          newObj.ReferenceImageId = childEle.ReferenceImageId;
          newObj.RefernceImage = childEle.RefernceImage;
          newObj.DrawnImage = childEle.DrawnImage;
          newObj.GridVisibility = childEle.GridVisibility;
          newObj.FingerTouchCount = childEle.FingerTouchCount;

          dataList.push(newObj);
        });
      });

      if (id) {
        dataList = dataList.filter((c) => c.UserId == id);
      }

      setTraceAwareData(dataList);

    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getPercentage = (id, childs, checknumber) => {
    const childerens = childs.filter((ch) => ch.DSSTId === id);
    const recall = childerens.filter((c) => c.IsRecallImage == checknumber);
    const sum = recall.reduce((sum, cur) => {
      sum +=
        checknumber == 1 ? Number(cur.RecallAccuracy) : Number(cur.Accuracy);
      return sum;
    }, 0);
    return (sum / recall.length) * 100
      ? ((sum / recall.length) * 100).toFixed(2)
      : 0;
  };

  const getAudioAwareData = async () => {
    try {
      var {
        data: { data },
      } = await audioAwareApi?.getAudioaware();

      if (id) {
        data = data.filter((c) => c.UserId == id);
      }

      let audioAwareData = [];

      data.forEach((el) => {
        let scoreData = el.SelectedWords.split(",").reduce((acc, curr) => {
          if (el?.PlayedWords?.split(",")?.includes(curr)) {
            acc++;
          }
          return acc;
        }, 0);

        let playwordVal = el.PlayedWords;
        let playwordArr = playwordVal.split(",");

        let selectedAns = el.SelectedWords;
        var ans = selectedAns.split(",");

        // playwordArr.forEach((value, index) => {

        let newObj = {
          AudioAwareId: el.AudioAwareId,
          UserId: el.UserId,
          SessionId: el.SessionId,
          Date_Created: moment(el.Date_Created).format("MM/DD/Y HH:mm:ss"),
          IsThereLossOfMemory: el.IsThereLossOfMemory,
          score: el.Score,
          DisplayedWords: el.DisplayedWords,
          DisplayedWordsStartTime: moment(el.DisplayedWordsStartTime).format(
            "MM/DD/Y HH:mm:ss"
          ),
          DisplayedWordsEndTime: moment(el.DisplayedWordsEndTime).format(
            "MM/DD/Y HH:mm:ss"
          ),
          PlayedWord: el.PlayedWords,
          SelectedWord: el.SelectedWords,
          LevelType: el.LevelType,
        };
        audioAwareData.push(newObj);
      });
      setAudioAwareData(audioAwareData);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getGlanceAwareData = async () => {
    try {
      var {
        data: { data },
      } = await glanceAwareApi?.getGlanceAware(id);
      const glanceAwareData = [];
      var trueNameDataArray = [];
      const profDataArray = [];
      const array1 = [];
      data.forEach((el) => {

        let playwordVal = el.TrueNames;
        let trueNameArr = playwordVal.split(",");

        let trueProf = el.TrueProfession;
        let trueProfArr = trueProf.split(",");

        let nameLength = trueNameArr.length;
        let profLength = trueProfArr.length;

        let selectedAns = el.SelectedNames;
        var selectedNameArr = selectedAns.split(",");

        let selectedProf = el.SelectedProfession;
        var selectedProdArr = selectedProf.split(",");

        trueNameArr.forEach((value, index) => {
          if (value == selectedNameArr[index]) {
            var nameCheck = "true";
          } else {
            var nameCheck = "false";
          }

          if (trueProfArr[index] == selectedProdArr[index]) {
            var professionCheck = "true";
          } else {
            var professionCheck = "false";
          }

          if (el.IsNameCorrect == true && el.IsProfessionCorrect == true) {
            var bothCheck = "BothCorrect";
          } else if (
            el.IsNameCorrect == false &&
            el.IsProfessionCorrect == false
          ) {
            var bothCheck = "BothIncorrect";
          } else {
            var bothCheck = "SingleCorrect";
          }


          var nameObj = {
            TrueNames: el.TrueNames,
            SelectedNames: el.SelectedNames,
            OnlyNameCorrect: el.IsNameCorrect,
            FaceAwareId: el.FaceAwareId,
            UserId: el.UserId,
            TrueProfession: el.TrueProfession,
            SelectedProfession: el.SelectedProfession,
            OnlyProfessionCorrect: el.IsProfessionCorrect,
            SessionId: el.SessionId,
            IsThereLossOfMemory: el.IsThereLossOfMemory,
            BothAnswersCorrect: bothCheck,
            LevelType: el.LevelType,
            ReferencePhotoId: el.ReferencePhotoId,
            ShownName: el.ShownName,
            ShownProfession: el.ShownProfession,
            Score: el.score,
            Date_Created: moment(el.Date_Created).format("MM/DD/Y HH:mm:ss"),
          };
          trueNameDataArray.push(nameObj);
        });
      });

      if (id) {
        trueNameDataArray = trueNameDataArray.filter((c) => c.UserId == id);
      }

      setGlanceAwareData(trueNameDataArray);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const toggle8Tube = () => {
    setShow8Tube(!show8Tube);
  };

  const toggle16Tube = () => {
    setShow16Tube(!show16Tube);
  };

  return (
    <>
      <div className="page_wrapper">
        {/* <div className="d-flex align-items-end w-100 justify-content-end mb-3">
          <button className="btn btn-primary text-white" onClick={(event) => { downloadAllData(event) }}>{loading ? <span style={{ color: '#fff' }}>Downloading...</span> : <span style={{ color: '#fff' }}>Download All Data</span>}</button>
        </div> */}
        <div className="graph_card_outer">
          <div className="graph_header">
            <div className="row">
              <div className="colxl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                <div
                  className="d-flex align-items-lg-center align-items-md-center align-items-start justify-content-start flex-lg-row flex-md-row flex-column ali">
                  <div className="graph_title mb-lg-0 mb-md-0 mb-3">
                    <h3>ScentAware</h3>
                  </div>
                  <div className="graph_button ps-lg-4 ps-md-4 ps-0 d-flex align-items-lg-center align-items-md-center align-items-start">
                    <a className={isOlf ? "btn btn-primary " : "btn btn-primary bg-white"} onClick={handleClickOlf}>Olfactory
                      function</a>
                    {role === 'SuperAdmin' && (
                      <div>
                        <a className={isExe ? "btn btn-primary ms-3" : "btn btn-primary ms-3 bg-white"} onClick={handleClickExe}>Executive function</a>
                      </div>
                    )}
                    {/* <div>
                    <Link to="/dashboard/trainingdata">
                      <a className="btn btn-primary ms-3 bg-white" >Training Data</a>
                    </Link>
                    </div> */}
                    {role === 'SuperAdmin' && (
                      <div>
                        <Link to="/dashboard/trainingdata">
                          <a className="btn btn-primary ms-3 bg-white">Training Data</a>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-4 mt-3">
                <div
                  className="d-flex align-items-lg-center align-items-md-center align-items-start justify-content-lg-end justify-content-start flex-lg-row flex-md-row flex-column">
                  <ul className="d-flex align-items-center graph_idicate_outer">
                    <li className={!show8Tube ? 'line-through-text' : ' '} onClick={toggle8Tube}>
                      {/* <span className={`graph_indicator me-3 ${!show8Tube ? 'line-through-text' : ''}`}></span> 8 tube test kit */}
                      <span className="graph_indicator me-3" style={{ background: show8Tube ? '#00D7FF' : 'gray' }}></span> 8 tube test kit
                    </li>
                    <li className={`ms-4 ${!show16Tube ? 'line-through-text' : ' '}`} onClick={toggle16Tube}>
                      {/* <li className="ms-4" onClick={toggle16Tube}> */}
                      {/* <span className={`graph_indicator me-3 ${!show16Tube ? 'line-through-text' : ' persian_blue'}`}></span> 16 tube test */}
                      <span className="graph_indicator  me-3" style={{ background: show16Tube ? '#5800FF' : 'gray' }}></span> 16 tube test
                      kit
                    </li>
                    <li className="ms-xl-5 ms-lg-4 ms-md-4 ms-4">
                      <Link to={`/dashboard/scentaware/test-logs/${studyNumber}?participentId=${participentId}`}>
                        <a >
                          <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="38" height="38" rx="19"
                              stroke="#101A36" stroke-width="2" />
                            <path
                              d="M15.0001 21L22.5901 21L19.2901 24.29C19.1018 24.4783 18.996 24.7337 18.996 25C18.996 25.2663 19.1018 25.5217 19.2901 25.71C19.4784 25.8983 19.7338 26.0041 20.0001 26.0041C20.2664 26.0041 20.5218 25.8983 20.7101 25.71L25.7101 20.71C25.8011 20.6149 25.8725 20.5027 25.9201 20.38C26.0201 20.1365 26.0201 19.8635 25.9201 19.62C25.8725 19.4972 25.8011 19.3851 25.7101 19.29L20.7101 14.29C20.6171 14.1963 20.5065 14.1219 20.3847 14.0711C20.2628 14.0203 20.1321 13.9942 20.0001 13.9942C19.8681 13.9942 19.7374 14.0203 19.6155 14.0711C19.4937 14.1219 19.3831 14.1963 19.2901 14.29C19.1964 14.383 19.122 14.4936 19.0712 14.6154C19.0204 14.7373 18.9943 14.868 18.9943 15C18.9943 15.132 19.0204 15.2627 19.0712 15.3846C19.122 15.5064 19.1964 15.617 19.2901 15.71L22.5901 19L15.0001 19C14.7349 19 14.4805 19.1054 14.293 19.2929C14.1055 19.4804 14.0001 19.7348 14.0001 20C14.0001 20.2652 14.1055 20.5196 14.293 20.7071C14.4805 20.8946 14.7349 21 15.0001 21Z"
                              fill="#101A36" />
                          </svg>
                        </a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="graph_body mt-5">
            <div className="Chart">
              {isOlf && (
                <Charts
                  chartOptions={olfactoryFunctionOptions}
                />
              )}
              {isExe && (
                <Charts
                  chartOptions={executiveFunctionOptions}
                />
              )}
            </div>
          </div>
        </div>

        {(userInfo.role == "SuperAdmin" || allTestTypeForUser.includes("TraceAware")) &&
          (
            <div className="graph_card_outer mt-4">
              <div className="graph_header">
                <div className="row">
                  <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                    <div
                      className="d-flex align-items-lg-center align-items-md-center align-items-start justify-content-lg-start justify-content-md-start justify-content-between flex-lg-row flex-md-row flex-row ">
                      <div className="graph_title">
                        <h3>TraceAware</h3>
                      </div>
                      <div className="graph_button ps-4 ms-3 d-flex align-items-center">
                        <div className="dropdown">
                          <select value={value} onChange={handleChange}>
                            <option value="val1">Visual Short Term memory</option>
                            <option value="val2">Processing Speed</option>
                            <option value="val3">Motor function</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-3 d-lg-block d-md-block d-none">
                    <div
                      className="d-flex align-items-lg-center align-items-md-center align-items-start justify-content-lg-end justify-content-md-end justify-content-start flex-lg-row flex-md-row flex-column">
                      <ul className="d-flex align-items-center graph_idicate_outer">
                        <li>
                          <Link to="/dashboard/traceaware/test-logs">
                            <a >
                              <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="38" height="38" rx="19"
                                  stroke="#101A36" stroke-width="2" />
                                <path
                                  d="M15.0001 21L22.5901 21L19.2901 24.29C19.1018 24.4783 18.996 24.7337 18.996 25C18.996 25.2663 19.1018 25.5217 19.2901 25.71C19.4784 25.8983 19.7338 26.0041 20.0001 26.0041C20.2664 26.0041 20.5218 25.8983 20.7101 25.71L25.7101 20.71C25.8011 20.6149 25.8725 20.5027 25.9201 20.38C26.0201 20.1365 26.0201 19.8635 25.9201 19.62C25.8725 19.4972 25.8011 19.3851 25.7101 19.29L20.7101 14.29C20.6171 14.1963 20.5065 14.1219 20.3847 14.0711C20.2628 14.0203 20.1321 13.9942 20.0001 13.9942C19.8681 13.9942 19.7374 14.0203 19.6155 14.0711C19.4937 14.1219 19.3831 14.1963 19.2901 14.29C19.1964 14.383 19.122 14.4936 19.0712 14.6154C19.0204 14.7373 18.9943 14.868 18.9943 15C18.9943 15.132 19.0204 15.2627 19.0712 15.3846C19.122 15.5064 19.1964 15.617 19.2901 15.71L22.5901 19L15.0001 19C14.7349 19 14.4805 19.1054 14.293 19.2929C14.1055 19.4804 14.0001 19.7348 14.0001 20C14.0001 20.2652 14.1055 20.5196 14.293 20.7071C14.4805 20.8946 14.7349 21 15.0001 21Z"
                                  fill="#101A36" />
                              </svg>
                            </a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="graph_body mt-5">
                {value === "val1" && (
                  <AreaChart
                    chartData={visualShortTermMemoryGraphData}
                    chartOptions={visualShortTermMemoryGraphOptions}
                    width={"100%"}
                    height={300}
                  />
                )}
                {value === "val2" && (
                  <AreaChart
                    chartData={visualShortTerm}
                    chartOptions={visualShortTermOptions}
                    width={"100%"}
                    height={300}
                  />
                )}
                {value === "val3" && (
                  <AreaChart
                    chartData={motorAwareData}
                    chartOptions={motorAwareOptions}
                    width={"100%"}
                    height={300}
                  />
                )}
              </div>
            </div>
          )
        }

        {(userInfo.role == "SuperAdmin" || allTestTypeForUser.includes("AudioAware")) &&
          (<div className="graph_card_outer mt-4">
            <div className="graph_header">
              <div className="row">
                <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                  <div
                    className="d-flex align-items-lg-center align-items-md-center align-items-start justify-content-lg-start justify-content-md-start justify-content-between flex-lg-row flex-md-row flex-row">
                    <div className="graph_title">
                      <h3>AudioAware</h3>
                    </div>
                    <div className="graph_button ps-4 ms-3 d-flex align-items-center">
                      <h6>Verbal Memory</h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-3 d-lg-block d-md-block d-none">
                  <div
                    className="d-flex align-items-lg-center align-items-md-center align-items-start justify-content-lg-end justify-content-md-end justify-content-start flex-lg-row flex-md-row flex-column">
                    <ul className="d-flex align-items-center graph_idicate_outer">
                      <li>
                        <Link to="/dashboard/audioaware/test-logs">
                          <a >
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <rect x="1" y="1" width="38" height="38" rx="19"
                                stroke="#101A36" stroke-width="2" />
                              <path
                                d="M15.0001 21L22.5901 21L19.2901 24.29C19.1018 24.4783 18.996 24.7337 18.996 25C18.996 25.2663 19.1018 25.5217 19.2901 25.71C19.4784 25.8983 19.7338 26.0041 20.0001 26.0041C20.2664 26.0041 20.5218 25.8983 20.7101 25.71L25.7101 20.71C25.8011 20.6149 25.8725 20.5027 25.9201 20.38C26.0201 20.1365 26.0201 19.8635 25.9201 19.62C25.8725 19.4972 25.8011 19.3851 25.7101 19.29L20.7101 14.29C20.6171 14.1963 20.5065 14.1219 20.3847 14.0711C20.2628 14.0203 20.1321 13.9942 20.0001 13.9942C19.8681 13.9942 19.7374 14.0203 19.6155 14.0711C19.4937 14.1219 19.3831 14.1963 19.2901 14.29C19.1964 14.383 19.122 14.4936 19.0712 14.6154C19.0204 14.7373 18.9943 14.868 18.9943 15C18.9943 15.132 19.0204 15.2627 19.0712 15.3846C19.122 15.5064 19.1964 15.617 19.2901 15.71L22.5901 19L15.0001 19C14.7349 19 14.4805 19.1054 14.293 19.2929C14.1055 19.4804 14.0001 19.7348 14.0001 20C14.0001 20.2652 14.1055 20.5196 14.293 20.7071C14.4805 20.8946 14.7349 21 15.0001 21Z"
                                fill="#101A36" />
                            </svg>
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="graph_body mt-5">
              <Charts
                chartOptions={audioAwareOptions}
              />
            </div>
          </div>)}


        {(userInfo.role == "SuperAdmin" || allTestTypeForUser.includes("GlanceAware")) &&
          (<div className="graph_card_outer mt-4">
            <div className="graph_header">
              <div className="row">
                <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                  <div
                    className="d-flex align-items-lg-center align-items-md-center align-items-start justify-content-lg-start justify-content-md-start justify-content-between flex-lg-row flex-md-row flex-row">
                    <div className="graph_title">
                      <h3>GlanceAware</h3>
                    </div>
                    <div className="graph_button ps-4 ms-3 d-flex align-items-center">
                      <h6>Associative Memory</h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-3 d-lg-block d-md-block d-none">
                  <div
                    className="d-flex align-items-lg-center align-items-md-center align-items-start justify-content-lg-end justify-content-md-end justify-content-start flex-lg-row flex-md-row flex-column">
                    <ul className="d-flex align-items-center graph_idicate_outer">
                      <li>
                        <Link to="/dashboard/glanceaware/test-logs">
                          <a >
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <rect x="1" y="1" width="38" height="38" rx="19"
                                stroke="#101A36" stroke-width="2" />
                              <path
                                d="M15.0001 21L22.5901 21L19.2901 24.29C19.1018 24.4783 18.996 24.7337 18.996 25C18.996 25.2663 19.1018 25.5217 19.2901 25.71C19.4784 25.8983 19.7338 26.0041 20.0001 26.0041C20.2664 26.0041 20.5218 25.8983 20.7101 25.71L25.7101 20.71C25.8011 20.6149 25.8725 20.5027 25.9201 20.38C26.0201 20.1365 26.0201 19.8635 25.9201 19.62C25.8725 19.4972 25.8011 19.3851 25.7101 19.29L20.7101 14.29C20.6171 14.1963 20.5065 14.1219 20.3847 14.0711C20.2628 14.0203 20.1321 13.9942 20.0001 13.9942C19.8681 13.9942 19.7374 14.0203 19.6155 14.0711C19.4937 14.1219 19.3831 14.1963 19.2901 14.29C19.1964 14.383 19.122 14.4936 19.0712 14.6154C19.0204 14.7373 18.9943 14.868 18.9943 15C18.9943 15.132 19.0204 15.2627 19.0712 15.3846C19.122 15.5064 19.1964 15.617 19.2901 15.71L22.5901 19L15.0001 19C14.7349 19 14.4805 19.1054 14.293 19.2929C14.1055 19.4804 14.0001 19.7348 14.0001 20C14.0001 20.2652 14.1055 20.5196 14.293 20.7071C14.4805 20.8946 14.7349 21 15.0001 21Z"
                                fill="#101A36" />
                            </svg>
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="graph_body mt-5">
              <Charts
                chartOptions={glanceAwareOptions}
              />
            </div>
          </div>)}



        {(userInfo.role == "SuperAdmin" || allTestTypeForUser.includes("WordsAware")) &&
          (
            <div className="graph_card_outer mt-4">
              <div className="graph_header">
                <div className="row">
                  <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                    <div
                      className="d-flex align-items-lg-center align-items-md-center align-items-start justify-content-lg-start justify-content-md-start justify-content-between flex-lg-row flex-md-row flex-row">
                      <div className="graph_title">
                        <h3>WordsAware</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-3 d-lg-block d-md-block d-none">
                    <div
                      className="d-flex align-items-lg-center align-items-md-center align-items-start justify-content-lg-end justify-content-md-end justify-content-start flex-lg-row flex-md-row flex-column">
                      <ul className="d-flex align-items-center graph_idicate_outer">
                        <li>
                          <Link to="/dashboard/wordsaware/test-logs">
                            <a >
                              <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="38" height="38" rx="19"
                                  stroke="#101A36" stroke-width="2" />
                                <path
                                  d="M15.0001 21L22.5901 21L19.2901 24.29C19.1018 24.4783 18.996 24.7337 18.996 25C18.996 25.2663 19.1018 25.5217 19.2901 25.71C19.4784 25.8983 19.7338 26.0041 20.0001 26.0041C20.2664 26.0041 20.5218 25.8983 20.7101 25.71L25.7101 20.71C25.8011 20.6149 25.8725 20.5027 25.9201 20.38C26.0201 20.1365 26.0201 19.8635 25.9201 19.62C25.8725 19.4972 25.8011 19.3851 25.7101 19.29L20.7101 14.29C20.6171 14.1963 20.5065 14.1219 20.3847 14.0711C20.2628 14.0203 20.1321 13.9942 20.0001 13.9942C19.8681 13.9942 19.7374 14.0203 19.6155 14.0711C19.4937 14.1219 19.3831 14.1963 19.2901 14.29C19.1964 14.383 19.122 14.4936 19.0712 14.6154C19.0204 14.7373 18.9943 14.868 18.9943 15C18.9943 15.132 19.0204 15.2627 19.0712 15.3846C19.122 15.5064 19.1964 15.617 19.2901 15.71L22.5901 19L15.0001 19C14.7349 19 14.4805 19.1054 14.293 19.2929C14.1055 19.4804 14.0001 19.7348 14.0001 20C14.0001 20.2652 14.1055 20.5196 14.293 20.7071C14.4805 20.8946 14.7349 21 15.0001 21Z"
                                  fill="#101A36" />
                              </svg>
                            </a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="graph_body mt-5">
                <AreaChart
                  chartData={wordsAwareData}
                  chartOptions={wordsAwareOptions}
                  width={"100%"}
                  height={200}
                />
              </div>
            </div>
          )}


        {(userInfo.role == "SuperAdmin" || allTestTypeForUser.includes("GrammarAware")) &&
          (
            <div className="graph_card_outer mt-4">
              <div className="graph_header">
                <div className="row">
                  <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                    <div
                      className="d-flex align-items-lg-center align-items-md-center align-items-start justify-content-lg-start justify-content-md-start justify-content-between flex-lg-row flex-md-row flex-row">
                      <div className="graph_title">
                        <h3>GrammarAware</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-3 d-lg-block d-md-block d-none">
                    <div
                      className="d-flex align-items-lg-center align-items-md-center align-items-start justify-content-lg-end justify-content-md-end justify-content-start flex-lg-row flex-md-row flex-column">
                      <ul className="d-flex align-items-center graph_idicate_outer">
                        <li>
                          <Link to="/dashboard/grammaraware/test-logs">
                            <a >
                              <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="38" height="38" rx="19"
                                  stroke="#101A36" stroke-width="2" />
                                <path
                                  d="M15.0001 21L22.5901 21L19.2901 24.29C19.1018 24.4783 18.996 24.7337 18.996 25C18.996 25.2663 19.1018 25.5217 19.2901 25.71C19.4784 25.8983 19.7338 26.0041 20.0001 26.0041C20.2664 26.0041 20.5218 25.8983 20.7101 25.71L25.7101 20.71C25.8011 20.6149 25.8725 20.5027 25.9201 20.38C26.0201 20.1365 26.0201 19.8635 25.9201 19.62C25.8725 19.4972 25.8011 19.3851 25.7101 19.29L20.7101 14.29C20.6171 14.1963 20.5065 14.1219 20.3847 14.0711C20.2628 14.0203 20.1321 13.9942 20.0001 13.9942C19.8681 13.9942 19.7374 14.0203 19.6155 14.0711C19.4937 14.1219 19.3831 14.1963 19.2901 14.29C19.1964 14.383 19.122 14.4936 19.0712 14.6154C19.0204 14.7373 18.9943 14.868 18.9943 15C18.9943 15.132 19.0204 15.2627 19.0712 15.3846C19.122 15.5064 19.1964 15.617 19.2901 15.71L22.5901 19L15.0001 19C14.7349 19 14.4805 19.1054 14.293 19.2929C14.1055 19.4804 14.0001 19.7348 14.0001 20C14.0001 20.2652 14.1055 20.5196 14.293 20.7071C14.4805 20.8946 14.7349 21 15.0001 21Z"
                                  fill="#101A36" />
                              </svg>
                            </a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="graph_body mt-5">
                <AreaChart
                  chartData={grammarAwareData}
                  chartOptions={grammarAwareOptions}
                  width={"100%"}
                  height={200}
                />
              </div>
            </div>
          )}




        {/* <div>
    //   <div
    //     style={{
    //       display: 'flex',
    //       justifyContent: 'end',
    //       paddingRight: '50px',
    //       position: 'relative'
    //     }}>
    //     <button style={{
    //       background: '#0e84f2',
    //       boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    //       borderRadius: '16px',
    //       color: 'white',
    //       border: 'none',
    //       padding: '2px 16px',
    //       cursor: 'pointer', marginBottom: '15px',
    //       position: 'absolute',
    //       top: '-33px', zIndex: '9', fontSize: '12px'
    //     }} onClick={(event) => { downloadAllData(event) }}>{loading ? <span>Downloading...</span> : <span>Download All Data</span>}</button>
    //   </div>

    //   <Row className="spacing" gutter={[20, 20]}>

    //     <Row
    //       style={{
    //         width: "100%",
    //         display: "flex",
    //         justifyContent: "space-between",
    //       }}
    //       gutter={[15, 16]}
    //     >
    //       <Col span={16} lg={16} sm={24} className="ScentAware-chart">
    //         <GraphCard background={"#013259"}>
    //           <div className="table-header">
    //             <div className="title">ScentAware</div>
    //             <div className="sub-title">
    //               {isOlf ? "Olfactory function" : "Executive function"}
    //             </div>
    //             <div className="ScentAware-chart-button">
    //               <button
    //                 type="button"
    //                 className={isOlf ? "active" : "btn"}
    //                 onClick={handleClickOlf}
    //               >
    //                 Olfactory function
    //               </button>
    //               <button
    //                 type="button"
    //                 className={isExe ? "active" : "btn"}
    //                 onClick={handleClickExe}
    //               >
    //                 Executive function
    //               </button>
    //             </div>
    //             <div className="header-icon">
    //               <span style={{ marginRight: "5px" }}>
    //                 <Link to="/dashboard/trainingdata">
    //                   Training Data
    //                 </Link>
    //               </span>
    //               <Link to="/dashboard/scentaware/test-logs">
    //                 <RightOutlined />
    //               </Link>
    //             </div>
    //           </div>
    //           <div className="Chart">
    //             {isOlf && (
    //               <BarChart
    //                 chartData={olfactoryFunctionData}
    //                 chartOptions={olfactoryFunctionOptions}
    //                 width={"100%"}
    //                 height={472}
    //               />
    //             )}
    //             {isExe && (
    //               <BarChart
    //                 chartData={executiveFunctionData}
    //                 chartOptions={executiveFunctionOptions}
    //                 width={"100%"}
    //                 height={472}
    //               />
    //             )}
    //           </div>
    //         </GraphCard>
    //       </Col>

    //       <Col lg={8} sm={24}>
    //         <Col lg={24}>
    //           <GraphCard background={"#013259"}>
    //             <div className="table-header">
    //               <div className="title">WordsAware</div>
    //               <div className="header-icon">
    //                 <Link to="/dashboard/wordsaware/test-logs">
    //                   <RightOutlined />
    //                 </Link>
    //               </div>
    //             </div>
    //             <AreaChart
    //               chartData={wordsAwareData}
    //               chartOptions={wordsAwareOptions}
    //               width={"100%"}
    //               height={200}
    //             />
    //           </GraphCard>
    //         </Col>

    //         <Col lg={24} style={{ marginTop: "30px" }}>
    //           <GraphCard background={"#013259"}>
    //             <div className="table-header">
    //               <div className="title">GrammarAware</div>
    //               <div className="header-icon">
    //                 <Link to="/dashboard/grammaraware/test-logs">
    //                   <RightOutlined />
    //                 </Link>
    //               </div>
    //             </div>
    //             <AreaChart
    //               chartData={grammarAwareData}
    //               chartOptions={grammarAwareOptions}
    //               width={"100%"}
    //               height={200}
    //             />
    //           </GraphCard>
    //         </Col>
    //       </Col>
    //     </Row>

    //     <Row
    //       style={{
    //         width: "100%",
    //         display: "flex",
    //         justifyContent: "space-between",
    //         marginTop: "100px !important",
    //       }}
    //       gutter={[20, 16]}
    //     >
    //       <Col lg={8}>
    //         <GraphCard background={"#013259"}>
    //           <div className="table-header">
    //             <div className="title">TraceAware</div>
    //             <div className="header-icon">
    //               <Link to="/dashboard/traceaware/test-logs">
    //                 <RightOutlined />
    //               </Link>
    //             </div>
    //           </div>
    //           <h4 className="sub-heading" style={{ color: "#ffff" }}>
    //             <div className="traceaware-option">
    //               <select value={value} onChange={handleChange}>
    //                 <option value="val1">Visual Short Term memory</option>
    //                 <option value="val2">Processing Speed</option>
    //                 <option value="val3">Motor function</option>
    //               </select>
    //             </div>
    //           </h4>
    //           {value === "val1" && (
    //             <AreaChart
    //               chartData={visualShortTermMemoryGraphData}
    //               chartOptions={visualShortTermMemoryGraphOptions}
    //               width={"100%"}
    //               height={300}
    //             />
    //           )}
    //           {value === "val2" && (
    //             <AreaChart
    //               chartData={visualShortTerm}
    //               chartOptions={visualShortTermOptions}
    //               width={"100%"}
    //               height={300}
    //             />
    //           )}
    //           {value === "val3" && (
    //             <AreaChart
    //               chartData={motorAwareData}
    //               chartOptions={motorAwareOptions}
    //               width={"100%"}
    //               height={300}
    //             />
    //           )}
    //         </GraphCard>
    //       </Col>

    //       <Col lg={8}>
    //         <GraphCard background={"#013259"}>
    //           <div className="table-header">
    //             <div className="title">AudioAware</div>

    //             <div className="header-icon">
    //               <Link to="/dashboard/audioaware/test-logs">
    //                 <RightOutlined />
    //               </Link>
    //             </div>
    //           </div>
    //           <h4 className="sub-heading" style={{ color: "#ffff" }}>
    //             Verbal Memory
    //           </h4>

    //           <AreaChart
    //             chartData={audioAwareData}
    //             chartOptions={audioAwareOptions}
    //             width={"100%"}
    //             height={300}
    //           />
    //         </GraphCard>
    //       </Col>

    //       <Col lg={8}>
    //         <GraphCard background={"#013259"}>
    //           <div className="table-header">
    //             <div className="title">GlanceAware</div>

    //             <div className="header-icon">
    //               <Link to="/dashboard/glanceaware/test-logs">
    //                 <RightOutlined />
    //               </Link>
    //             </div>
    //           </div>
    //           <h4 className="sub-heading" style={{ color: "#ffff" }}>
    //             Associative Memory
    //           </h4>

    //           <AreaChart
    //             chartData={glanceAwareData}
    //             chartOptions={glanceAwareOptions}
    //             width={"100%"}
    //             height={300}
    //           />
    //         </GraphCard>
    //       </Col>
    //     </Row>
    //   </Row>
    // </div > */}
      </div>
    </>
  )
};

export default UserGraphs;
