import { axiosApi } from "../axios/axios_config";

export async function getTrainingData(pagination , filter) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  let endpoint = `trainingdata?userId=${userInfo.id}`;

  if (filter && filter?.filterBy && filter?.filterValue) {
    endpoint += `&filterBy=${filter.filterBy}&filterValue=${filter.filterValue}`;
  }

  if (pagination) {
    endpoint += `${pagination?.pageIndex ? `&pageIndex=${pagination.pageIndex}` : ''}${pagination?.pageSize ? `&pageSize=${pagination.pageSize}` : ''}`;
  }
  try {
    const { data, response } = await axiosApi({
      endpoint: endpoint,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}
