import { axiosApi } from "../axios/axios_config";

export async function loginApi(credentials) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/login`,
      method: "POST",
      bodyData: credentials,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function forgetPassword(credentials) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/forgot-password`,
      method: "POST",
      bodyData: credentials,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}
