import React from "react";
import { Card } from "antd";

const DefaultCard = ({ children, title, background, padding }) => (
  <Card
    title={title}
    bordered={true}
    style={{ background: background, padding: padding ? padding : "25px" }}
    className="graph-card"
  >
    {children}
  </Card>
);
export default DefaultCard;
