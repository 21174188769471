/** @format */

import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ chartOptions, chartData, width = 350, height = 350 }) => {
  return (
    <div className="scroll">
      <ReactApexChart
        options={chartOptions}
        series={chartData}
        type="bar"
        width={width}
        height={height}
      />
    </div>
  );
};

export default BarChart;
