import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Row, Table, Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import * as TrainingDataApi from "../../services/api/trainingdata";
import moment from "moment";
import CustomTable from "../../components/Table/CustomTable";
import useDebounce from '../../components/Table/debounce';

const TrainingData = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState({
    filterBy: '',
    filterValue: ''
  });

  // const columns = [
  //     {
  //         title: "ID",
  //         field: "ID",
  //     },
  //     {
  //         title: "UserId",
  //         field: "UserId",
  //     },
  //     {
  //         title: "SessionId",
  //         field: "SessionId",
  //     },
  //     {
  //         title: "TestType",
  //         field: "TestType",
  //     },
  //     {
  //         title: "TubeId ",
  //         field: "TubeId",
  //     },
  //     {
  //         title: "TubeAnswer",
  //         field: "TubeAnswer",
  //     },
  //     {
  //         title: "ResponseTime",
  //         field: "AverageResponse",
  //     },

  //     {
  //         title: "Scan To Scan Time",
  //         field: "ScantoScanTime",
  //     },
  //     {
  //         title: "SmellIntensity",
  //         field: "SmellIntensity",
  //     },
  //     {
  //         title: "SmellPerception",
  //         field: "SmellPerception",
  //     },
  //     {
  //         title: "DateTime",
  //         field: "Date",
  //     },
  // ];
  const columns = [
    { Header: "Sr No.", accessor: "ID" },
    { Header: "UserId", accessor: "UserId" },
    { Header: "SessionId", accessor: "SessionId" },
    { Header: "TestType", accessor: "TestType" },
    { Header: "TubeId", accessor: "TubeId" },
    { Header: "TubeAnswer", accessor: "TubeAnswer" },
    { Header: "ResponseTime", accessor: "AverageResponse" },
    { Header: "Scan To Scan Time", accessor: "ScantoScanTime" },
    { Header: "SmellIntensity", accessor: "SmellIntensity" },
    { Header: "SmellPerception", accessor: "SmellPerception" },
    { Header: "DateTime", accessor: "Date" }
  ];

  const [data, setData] = useState([]);
  var returnPath = "/dashboard";
  if (id) {
    returnPath = "/dashboard/user-details/" + id;
  }

  // useEffect(() => {
  //   getTrainingData();
  // }, [currentPage]);

  const getTrainingData = async () => {
    try {
      setLoading(true);
      let { data: { data, totalCount } } = await TrainingDataApi?.getTrainingData(pagination,filter);
      setPagination((state) => ({ ...state, totalCount: totalCount }));
      if (id) {
        data = data.filter((c) => c.UserId == id);
      }
      let newData = [];
      data.map((element, index) => {
        let newObj = {
          ...element,
          ['Date']: moment(element.Date).format("MM/DD/Y HH:mm:ss"),
        }
        newData = [...newData, newObj];
      });
      setData(newData);
      setLoading(false);
    }
    catch (error) {
      console.log("tableError", error)
    }
  };

  
  const debouncedFilterValue = useDebounce(filter.filterValue, 300);

  const handleSearchFilter = (e) => {
    setFilter((state) => ({ ...state, filterValue: e.target.value }))
  };

  useEffect(() => {
    if (debouncedFilterValue !== null) {
      getTrainingData();
    }
  }, [currentPage, debouncedFilterValue]);


  return (
    <>
      <CustomTable
        columns={columns}
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
        setCurrentPage={setCurrentPage}
        setFilter={setFilter}
        handleSearchFilter={handleSearchFilter}
        filter={filter}
      />
    </>
    // <div className="container">
    //     <div className="test-log">
    //         <span className="left-outlined">
    //             <Link to={returnPath}>
    //                 <LeftOutlined />
    //             </Link>
    //         </span>
    //         <h2 style={{ fontSize: "32px", color: "white" }}>Test logs</h2>
    //     </div>
    //     <Divider />
    //     <Row className="table-background" gutter={[22, 22]}>
    //         <Col lg={24}>
    //             <MaterialTable
    //                 title="Training Data"
    //                 columns={columns}
    //                 data={data}
    //                 options={{
    //                     exportAllData: true,
    //                     exportMenu: [
    //                         {
    //                             label: "Export PDF",
    //                             exportFunc: (columns, data) =>
    //                                 ExportPdf(columns, data, "Training Data"),
    //                         },
    //                         {
    //                             label: "Export CSV",
    //                             exportFunc: (columns, data) =>
    //                                 ExportCsv(columns, data, "Training Data"),
    //                         },
    //                     ],
    //                     filtering: true,
    //                     sorting: true,
    //                     headerStyle: {
    //                         backgroundColor: "#01579b",
    //                         color: "#FFF",
    //                     },
    //                     cellStyle: {
    //                         backgroundColor: "#01579b",
    //                         color: "#FFF",
    //                     },
    //                     pageSize: 10,
    //                     pageSizeOptions: [10, 50, 100],
    //                 }}
    //             />
    //         </Col>
    //     </Row>
    // </div>
  )
}

export default TrainingData