import React from "react";
import { Card } from "antd";

const GraphCard = ({ children, title, background }) => (
  <Card
    title={title}
    bordered={false}
    style={{ background: background }}
    className="graph-card"
  >
    {children}
  </Card>
);
export default GraphCard;
