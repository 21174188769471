import { Row, Table, Col } from "antd";
import React, { Component } from "react";
const DynamicTable = ({ columns, dataSource, loading }) => {
  return (
    <>
      <div className="table-style">
        <Table
          loading={loading}
          pagination={false}
          className="Table"
          columns={columns}
          dataSource={dataSource}
          rowKey="Id"
        />
      </div>
    </>
  );
};

export default DynamicTable;
