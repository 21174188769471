let tubeIdsValues = {
  "000001": "Banana",
  "000002": "Rose",
  "000003": "Lemon",
  "000004": "Fish",
  "000005": "Garlic",
  "000006": "Cinnamon",
  "000007": "Eucalyptus",
  "000008": "Orange",
  "000009": "Coffee",
  "000010": "Peppermint",
  "000011": "No Odor detected",
  "000012": "Peppermint",
  "000013": "Rose",
  "000014": "Cloves",
  "000015": "Peppermint",
  "000016": "Coffee",
};

//Same id in both iteration with correct answer increment count.

export const verifyTubeReadings = (readingsLength, data) => {
  const updatedData = data.map((item) => {
    let tubeIds = item.TubeId;
    let selectedAnswers = item.SelectedAnswer;
    let correctCounter = 0;
    let correctValues = "";
    let incorrectValues = "";
    let correlationPercentage = 0;

    tubeIds = tubeIds.split(",");
    selectedAnswers = selectedAnswers.split(",");
    const firstIteration = tubeIds.slice(0, readingsLength);
    const selectedAnswersFirstIteration = selectedAnswers.slice(
      0,
      readingsLength
    );
    const secondIteration = tubeIds.slice(readingsLength, tubeIds.length);
    const selectedAnswersSecondIteration = selectedAnswers.slice(
      readingsLength,
      selectedAnswers.length
    );

    for (let i = 0; i < firstIteration.length; i++) {
      for (let j = 0; j < secondIteration.length; j++) {
        if (firstIteration[i] == secondIteration[j]) {
          if (
            selectedAnswersFirstIteration[i] ==
              tubeIdsValues[firstIteration[i]] &&
            selectedAnswersSecondIteration[j] ==
              tubeIdsValues[secondIteration[j]]
          ) {
            correctCounter++;
            if (correctValues) {
              correctValues += `, ${firstIteration[i]}/${selectedAnswersFirstIteration[i]}`;
            } else {
              correctValues += `${firstIteration[i]}/${selectedAnswersFirstIteration[i]}`;
            }

            break;
          } else {
            if (incorrectValues) {
              incorrectValues += `, ${firstIteration[i]}/${selectedAnswersFirstIteration[i]}`;
            } else {
              incorrectValues += `${firstIteration[i]}/${selectedAnswersFirstIteration[i]}`;
            }

            break;
          }
        }
      }
    }

    correlationPercentage = (correctCounter / readingsLength) * 100;
    return {
      ...item,
      CorrectValues: correctValues,
      IncorrectValues: incorrectValues,
      CorrelationPercentage: correlationPercentage,
    };
  });
  return updatedData;
};
