import React from "react";
import { Col } from "antd";
import DefaultButton from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

const FinishForm = () => {
  let navigate = useNavigate();
  return (
    <>
      <Col className="Finish-Form" span={8}>
        <h5>
          Clinical trial created
          <br />
          successfully!
        </h5>
        <p>
          You have successfully created the project.
          <br /> {/* <span>Clinical trial 1.</span> */}
        </p>
        {/* <p>Assigned admin : Robert Lamb</p> */}
        <DefaultButton
          className="colored_btn"
          title="Back to home"
          onClick={() => navigate(`/dashboard`)}
        />
      </Col>
    </>
  );
};
export default FinishForm;
