/**
 * eslint-disable jsx-a11y/alt-text
 *
 * @format
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import Header from "../../components/Dashboard/Header";
import FinishForm from "../../pages/ClinicalTrial/FinishForm";
import { Routes, Route, Link, useRoutes } from "react-router-dom";
import ClinicalForm from "../../pages/ClinicalTrial/ClinicalForm";
import Scentaware from "../../pages/Scentaware";
import Traceaware from "../../pages/Traceaware";
import Audioaware from "../../pages/Audioaware";
import AddUser from "../../pages/AddUser";
import GlanceAware from "../../pages/GlanceAware";
import EightScentTest from "../../pages/Scentaware_Tests/Eight-Scent";
import SixteenScentTest from "../../pages/Scentaware_Tests/Sixteen-Scent";
import ClinicalTrial from "../../pages/Clinical-Trial";
import Logs from '../../pages/Logs/logs'
// import routes from "../../routes";
import UserDetail from "../../pages/user/UserDetail";
import ScentawareDetails from "../../pages/user/ScentawareDetails";
import Testlog from "../../pages/user/Testlog";
import TrainingData from "../../pages/TrainingData/TrainingData"

import CreateClinicalTrial from "../../pages/Clinical-Trial/multi-step-form";
import ScentAwareTestLogs from "../../pages/test-logs/ScentAware";
import TraceAwareTestLogs from "../../pages/test-logs/TraceAware";
import GlanceAwareTestLogs from "../../pages/test-logs/GlanceAware";
import AudioAwareTestLogs from "../../pages/test-logs/AudioAware";
import WordsAwareTestLogs from "../../pages/test-logs/WordsAware";
import GrammarAwareTestLogs from "../../pages/test-logs/GrammarAware";
import { useNavigate, useLocation } from "react-router-dom";
import UserGraphs from "../../pages/user/UserGraphs";
import Sidebar from "../../components/Dashboard/Sidebar";
// import ClinicalTrial from '../../pages/Clinical-Trial/clinicalTrial'
// import CreateClinicalTrial from "../../pages/Clinical-Trial/multi-step-form/trail";

const { Sider, Content } = Layout;

const AdminLayout = () => {
  const [sidebarMenu, setSidebarMenu] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const routePath = location.pathname;
  console.log('routePath', routePath);

  const routesData = [
    '/dashboard/scentaware/test-logs',
    '/dashboard/audioaware/test-logs',
    '/dashboard/glanceaware/test-logs',
    '/dashboard/grammaraware/test-logs',
    '/dashboard/traceaware/test-logs',
    '/dashboard/wordsaware/test-logs',
    '/dashboard/trainingdata']

  const commonRoutes = (
    <Routes>
      <Route path="/:id?" element={<UserGraphs />} />

      <Route path="/clinical-trial" element={<ClinicalTrial />} />
      {/* <Route path="/clinicaltrial" element={<ClinicalTrial/>} /> */}
      <Route
        path="/clinical-trial/create"
        element={<CreateClinicalTrial />}
      />
        <Route
        path="/logs"
        element={<Logs />}
      />
      <Route
        path="/clinical-trial/update/:id?"
        element={<CreateClinicalTrial />}
      />

      {/* <Route path="/users" element={<Users />} /> */}
      <Route path="/user-details/:id" element={<UserDetail />} />

      <Route path="/user-graphs" element={<UserGraphs />} />

      <Route
        path="/scentaware/test-logs/:id?"
        element={<ScentAwareTestLogs />}
      />
      <Route
        path="/wordsaware/test-logs/:id?"
        element={<WordsAwareTestLogs />}
      />
      <Route
        path="/grammaraware/test-logs/:id?"
        element={<GrammarAwareTestLogs />}
      />
      <Route
        path="/traceaware/test-logs/:id?"
        element={<TraceAwareTestLogs />}
      />
      <Route
        path="/audioaware/test-logs/:id?"
        element={<AudioAwareTestLogs />}
      />
      <Route
        path="/glanceaware/test-logs/:id?"
        element={<GlanceAwareTestLogs />}
      />
      <Route
        path="/scentaware-details"
        element={<ScentawareDetails />}
      />
      <Route path="/logs" element={<Testlog />} />
      <Route path="/clinical-trial" element={<ClinicalForm />} />
      <Route path="/scentaware" element={<Scentaware />} />
      <Route path="/traceaware" element={<Traceaware />} />
      <Route path="/audioaware" element={<Audioaware />} />
      <Route path="/add-user/:Id" element={<AddUser />} />
      <Route path="/glanceaware" element={<GlanceAware />} />
      <Route
        path="/scentaware/eight-scent"
        element={<EightScentTest />}
      />
      <Route
        path="/scentaware/sixteen-scent"
        element={<SixteenScentTest />}
      />
      <Route path="/form-submitted" element={<FinishForm />} />
      <Route path="/trainingdata/:id?" element={<TrainingData />} />
    </Routes>
  );
  return (
    <>
      <Header setSidebarMenu={setSidebarMenu} sidebarMenu={sidebarMenu} />
      {
        !routesData.some(route => routePath.startsWith(route)) ? (
          <section>
            <div className="container-fluid p-0">
              <div className="row m-0">
                <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 ps-xl-0">
                  <Sidebar sidebarMenu={sidebarMenu} />
                </div>
                <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 px-xl-4">
                  {commonRoutes}
                </div>
              </div>
            </div>
          </section>
        ) : (
          commonRoutes
        )
      }




      {/* <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{
            marginLeft: collapsed ? "20px" : "0px",
            marginTop: "40px",
            height: "600px",
          }}
        >
          <Menu theme="dark" mode="inline">
            {sidebarRoutes.map((item) => (
              <Menu.Item key={item.name} icon={item.icon}>
                <Link to={item.route}>
                  <a style={{ fontSize: 15, fontWeight: 600 }}>{item.name}</a>
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Layout>
          <Content style={{ margin: "0 16px 30px" }}>
            <Routes>
              <Route path="/:id?" element={<UserGraphs />} />

              <Route path="/clinical-trial" element={<ClinicalTrial />} />
              <Route
                path="/clinical-trial/create"
                element={<CreateClinicalTrial />}
              />

              <Route path="/users" element={<Users />} />
              <Route path="/user-details/:id" element={<UserDetail />} />

              <Route path="/user-graphs" element={<UserGraphs />} />

              <Route
                path="/scentaware/test-logs/:id?"
                element={<ScentAwareTestLogs />}
              />
              <Route
                path="/wordsaware/test-logs/:id?"
                element={<WordsAwareTestLogs />}
              />
              <Route
                path="/grammaraware/test-logs/:id?"
                element={<GrammarAwareTestLogs />}
              />
              <Route
                path="/traceaware/test-logs/:id?"
                element={<TraceAwareTestLogs />}
              />
              <Route
                path="/audioaware/test-logs/:id?"
                element={<AudioAwareTestLogs />}
              />
              <Route
                path="/glanceaware/test-logs/:id?"
                element={<GlanceAwareTestLogs />}
              />
              <Route
                path="/scentaware-details"
                element={<ScentawareDetails />}
              />
              <Route path="/logs" element={<Testlog />} />
              <Route path="/clinical-trial" element={<ClinicalForm />} />
              <Route path="/scentaware" element={<Scentaware />} />
              <Route path="/traceaware" element={<Traceaware />} />
              <Route path="/audioaware" element={<Audioaware />} />
              <Route path="/add-user/:Id" element={<AddUser />} />
              <Route path="/glanceaware" element={<GlanceAware />} />
              <Route
                path="/scentaware/eight-scent"
                element={<EightScentTest />}
              />
              <Route
                path="/scentaware/sixteen-scent"
                element={<SixteenScentTest />}
              />
              <Route path="/form-submitted" element={<FinishForm />} />
              <Route path="/trainingdata/:id?" element={<TrainingData />} />
            </Routes>
          </Content>
        </Layout>
      </Layout> */}
    </>
  );
};

export default AdminLayout;
