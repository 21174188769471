import { axiosApi } from "../axios/axios_config";

export async function getTraceaware() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  try {
    const { data, response } = await axiosApi({
      endpoint: `traceaware?userId=${userInfo.id}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}
