/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import logo from "../../assets/images/logo.png";
import searchicon from "../../assets/images/searchicon.png";
import notification from "../../assets/images/noti.png";
import { useLocation, useNavigate } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import { Dropdown, Button } from "antd";
import * as AuthApi from "../../services/api/auth";
import { toast } from "react-toastify";
// import '../../styles/header.css'

const Header = ({ sidebarMenu, setSidebarMenu }) => {
  const confirm = (e) => {
    localStorage.removeItem("userInfo");
    navigate("/login");
    message.success("Logged Out Successfully");
  };
  const cancel = (e) => {
    console.log(e);
    message.error("Logout Unsuccessful");
  };
  const navigate = useNavigate();
  const location = useLocation();
  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={async () => {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const credentials = {
              email: userInfo.email,
            };
            const {
              data,
              response: { status },
            } = await AuthApi.forgetPassword(credentials);
            localStorage.removeItem("forgotPassCredentials");
            localStorage.setItem(
              "forgotPassCredentials",
              JSON.stringify(credentials.email)
            );
            if (status === 200) {
              toast.success(data.message);
              navigate("/confirm-password");
            }
          }}
        >
          Change Password
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <Popconfirm
          title="Are You Sure you want to Logout?"
          description="Are you sure Logout?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            // href="https://www.aliyun.com"
          >
            Logout
          </a>
        </Popconfirm>
      ),
    },
  ];
  return (
    <>
      {/* <div className="menubar">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="mendiv">
            {location?.pathname == "/login" ||
            location?.pathname == "/forget-password" ||
            location?.pathname == "/change-password" ? (
              <a className="navbar-brand" href="#">
                <img src={logo}></img>
              </a>
            ) : (
              <Link to={"/dashboard"}>
                <img src={logo}></img>
              </Link>
            )}
            {location?.pathname == "/login" ||
            location?.pathname == "/forget-password" ||
            location?.pathname == "/change-password" ? null : (
              <>
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items,
                  }}
                  placement="bottomLeft"
                >
                  <div>
                    <a className="name-icon">
                      {JSON.parse(
                        localStorage.getItem("userInfo")
                      )?.name?.slice(0, 1)}
                      {JSON.parse(
                        localStorage.getItem("userInfo")
                      )?.lastName?.slice(0, 1)}
                    </a>
                  </div>
                </Dropdown>
              </>
            )}
          </div>
        </nav>
      </div> */}

      <header className="ps-xl-4 pe-xl-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="logo_wrap d-flex align-items-center justify-content-between">
                <div className="brand d-flex align-items-center">
                  <a href="javascript:void">
                    <img src={logo} alt="" />
                  </a>
                  <div
                    className="menu_toglle d-xl-none d-block ms-4"
                    onClick={() => setSidebarMenu(!sidebarMenu)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-list"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="user_info d-flex align-items-center pe-xl-1">
                  <div className="icon me-lg-4 me-md-3 me-2">
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items,
                      }}
                      placement="bottomLeft"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <path
                          d="M20.3681 24.544C19.3121 24.856 18.0641 25 16.6001 25H9.40007C7.93607 25 6.68808 24.856 5.63208 24.544C5.89608 21.424 9.10007 18.964 13.0001 18.964C16.9001 18.964 20.1041 21.424 20.3681 24.544Z"
                          stroke="#292D32"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16.6 1H9.4C3.4 1 1 3.4 1 9.4V16.6C1 21.136 2.368 23.62 5.632 24.544C5.896 21.424 9.1 18.964 13 18.964C16.9 18.964 20.104 21.424 20.368 24.544C23.632 23.62 25 21.136 25 16.6V9.4C25 3.4 22.6 1 16.6 1ZM13 15.604C10.624 15.604 8.704 13.672 8.704 11.296C8.704 8.92002 10.624 7 13 7C15.376 7 17.296 8.92002 17.296 11.296C17.296 13.672 15.376 15.604 13 15.604Z"
                          stroke="#292D32"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.2961 11.296C17.2961 13.672 15.3761 15.604 13.0001 15.604C10.6241 15.604 8.7041 13.672 8.7041 11.296C8.7041 8.92002 10.6241 7 13.0001 7C15.3761 7 17.2961 8.92002 17.2961 11.296Z"
                          stroke="#292D32"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Dropdown>
                  </div>
                  <div>
                    <h4 className="m-0">
                      {JSON.parse(localStorage.getItem("userInfo"))?.name}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
