/** @format */

import React, { useState } from "react";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { Col, Row, Avatar } from "antd";
import axios from "axios";
import GraphCard from "../../components/GraphCard/GraphCard";
import BarChart from "../../components/Charts/BarChart";
import AreaChart from "../../components/Charts/AreaChart";
import LineChart from "../../components/Charts/LineChart";
import DefaultCard from "../../components/Card/Card";
import { Link, useParams } from "react-router-dom";
import * as adminApi from "../../services/api/admin";
import arrow from "../../assets/images/BackArrow.png";
import upDown from "../../assets/images/upDown.png";
import { useEffect } from "react";
import { groupBy } from "underscore";
import moment from 'moment';
function UserDetail() {
  const [participant, setParticipant] = useState([]);

  const { id } = useParams();

  const user = JSON.parse(localStorage.getItem("userInfo"));

  const getparticipant = async () => {
    let {
      data: { data },
    } = await adminApi?.getClinicalTrialUserDetails(id);
    setParticipant(data);
  };
  const [audioawareuserdata, setAudioawareuserdata] = useState([]);
  const [grammeraware, setGrammeraware] = useState([]);
  const [wordsAwareUserDataa, setWordsAwareUserDataa] = useState([]);
  const [glanceAwareUserData, setGlanceawareUserData] = useState([]);
  const [traceAwareUserDatas, setTraceawareUserDatas] = useState([]);
  const [scentAwareUserDatas, setScentawareUserDatas] = useState([]);
  useEffect(() => {
    getparticipant();
    if (participant?.Id) {
      traceAwareUserData();
      audioAwareUserData();
      grammerAwareUserData();
      wordsAwareUserData();
      glanceAwareUserDataGraph();
      scentAwareUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant?.Id]);
  function scentAwareUserData() {
    console.log(participant.Id);
    const json = {
      userId: `${participant?.Id}`,
    };
    axios
      .post(
        "https://gokepiqof3.execute-api.us-east-1.amazonaws.com/prod/api/scentaware/olfactoryUserData",
        json
      )
      .then((res) => {

        const response = res.data;
        //const datesData = response[2].dates;
        // console.log("===>",datesData)
        const datesData = response[2].dates.map((c) => getFormattedDate(c.CreatedDate.slice(0, 10)));
        // console.log("datesData==>",datesData)
        const eight = calculateEightScore(response[0].eight, datesData);
        const sixteen = calculateEightScore(
          response[1].sixteen,
          datesData
        );
        /* console.log(datesData)
        console.log("sixteen",sixteen) */
        setScentawareUserDatas({ eight, sixteen, dates: datesData });
      });
  }

  function calculateEightScore(data, xaxisData) {
    const result = data.map((item) => ({
      ...item,
      //marks: calculateMarks(item.SelectedAnswer, item.TubeId),
      CreatedDate: getFormattedDate(item.CreatedDate.slice(0, 10)),
    }));
    const groups = groupBy(result, "CreatedDate");
    //  console.log("groups",groups)
    const fianlResult = Object.keys(groups).map((key) => {
      const average = Math.round(
        groups[key].reduce(function (a, b) {
          if (b["IsAnswerCorrect"] == true) {
            return a + 1;
          }
          else {
            return a;
          }
        }, 0)
        /// groups[key].length
      );
      return { [key]: average };
    });
    /*  console.log("xaxisData",xaxisData)
     console.log("fianlResult",fianlResult) */
    return xaxisData.map((c) => {
      return {
        key: c,
        average: fianlResult
          ?.map((d) => Object.keys(d)[0])
          ?.includes(c)
          ? fianlResult.find((k) => Object.keys(k)[0] === c)[
          c
          ]
          : 0,
      };
    });
  }
  const calculateMarks = (selectedAnswer, tubes) => {
    const answers = selectedAnswer.split(",");
    const tubeids = tubes.split(",");
    let i = 0;
    return tubeids.reduce((acc, cur) => {
      if (tubeAnswers[cur] === answers[i]) {
        acc++;
      }
      i++;
      return acc;
    }, 0);
  };

  const tubeAnswers = {
    "000001": "Banana",
    "000002": "Rose",
    "000003": "Lemon",
    "000004": "Fish",
    "000005": "Garlic",
    "000006": "Cinnamon",
    "000007": "Eucalyptus",
    "000008": "Orange",
    "000009": "Coffee",
    "000010": "Peppermint",
    "000011": "No Odor detected",
    "000012": "Peppermint",
    "000013": "Rose",
    "000014": "Cloves",
    "000015": "Peppermint",
    "000016": "Coffee",
  };

  function traceAwareUserData() {
    const json = {
      userId: `${participant?.Id}`,
    };
    axios
      .post(
        `https://gokepiqof3.execute-api.us-east-1.amazonaws.com/prod/api/traceaware/getTraceawareUserData`,
        json
      )
      .then((res) => {

        const response = res.data.data;

        response.master = response.master.map((c) => ({
          ...c,
          dt_created: getFormattedDate(c.dt_created),
          averageResponseTime:
            (new Date(c.SessionEndTime).getTime() -
              new Date(c.SessionStartTime).getTime()) /
            (c.total * 1000) || 0,

          recall: Math.round(getPercentage(c.Id, response.child, 1)),
          copy: Math.round(getPercentage(c.Id, response.child, 0)),

        }));
        const groups = groupBy(response.master, "dt_created");
        const averageWiseRecallData = Object.keys(groups).map((e) => {
          const total = groups[e].reduce(function (x, y) {
            return x + y.recall;
          }, 0);


          return {
            key: e,
            average: total / groups[e].length,
          };
        });
        const averageWiseCopyData = Object.keys(groups).map((e) => {
          const total = groups[e].reduce(function (x, y) {
            return x + y.copy;
          }, 0);

          return {
            key: e,
            average: total / groups[e].length,
          };
        });

        setTraceawareUserDatas({ averageWiseCopyData, averageWiseRecallData });
      });
  }

  function getFormattedDate(date) {
    return moment(date).format("MM-DD-Y");
    // return `${new Date(date).getDate()}-${
    //   new Date(date).getMonth() + 1
    // }-${new Date(date).getFullYear()}`;
  }
  const getPercentage = (id, childs, checknumber) => {
    const childerens = childs.filter((ch) => ch.DSSTId === id);

    const recall = childerens.filter(
      (c) =>
        /* c.GridVisibility?.split("|")?.length > 1 &&
        c.GridVisibility?.split("|")[1] == checknumber &&
        c.FingerTouchCount?.split("|")?.length > 1 */
        c.IsRecallImage == checknumber
    );
    const sum = recall.reduce((sum, cur) => {
      // sum += Number(cur.FingerTouchCount.split("|")[1]);
      sum += (checknumber == 1) ? Number(cur.RecallAccuracy) : Number(cur.Accuracy);
      return sum;
    }, 0);

    return (sum / recall.length) * 100
      ? ((sum / recall.length) * 100).toFixed(2)
      : 0;
  };

  function glanceAwareUserDataGraph() {
    const json = {
      userId: `${participant?.Id}`,
    };
    axios
      .post(
        "https://gokepiqof3.execute-api.us-east-1.amazonaws.com/prod/api/glanceaware/glanceAwareUserData",
        json
      )
      .then((res) => {

        if (res.data.length) {
          const result = res.data.map((c) => {
            const arr1 = c.TrueNames.split(",");
            const match1 = c.SelectedNames.split(",");
            const arr2 = c.TrueProfession.split(",");
            const match2 = c.SelectedProfession.split(",");
            let count = 0;
            arr1.forEach((item, index) => {
              if (item === match1[index] && arr2[index] === match2[index]) {
                count++;
              }
            });
            const date = new Date(c.CreatedDate);
            return {
              CreatedDate: getFormattedDate(c.CreatedDate.slice(0, 10)),
              count,
            };
          });
          const groups = groupBy(result, "CreatedDate");
          const averageWiseData = Object.keys(groups).map((e) => {
            const total = groups[e].reduce(function (x, y) {
              return x + y.count;
            }, 0);

            return {
              key: e,
              average: total /// groups[e].length,
            };
          });

          setGlanceawareUserData(averageWiseData);
        }
      });
  }
  function wordsAwareUserData() {
    const json = {
      userId: `${participant?.Id}`,
    };
    axios
      .post(
        "https://gokepiqof3.execute-api.us-east-1.amazonaws.com/prod/api/wordsaware/wordsAwareUserData",
        json
      )
      .then((res) => setWordsAwareUserDataa(res.data));
  }
  function grammerAwareUserData() {
    const json = {
      userId: `${participant?.Id}`,
    };
    axios
      .post(
        "https://gokepiqof3.execute-api.us-east-1.amazonaws.com/prod/api/grammaraware/grammerawareuserdata",
        json
      )
      .then((res) => {

        setGrammeraware(res.data)
      });
  }
  function audioAwareUserData() {
    const json = {
      userId: `${participant?.Id}`,
    };
    axios
      .post(
        "https://gokepiqof3.execute-api.us-east-1.amazonaws.com/prod/api/audioaware/audioawareuserdata",
        json
      )
      .then((res) => setAudioawareuserdata(res.data));
  }
  let chartOneData = [
    {
      name: "16 Tube test kit",
      data: scentAwareUserDatas?.sixteen?.map((c) => c.average),
    },
    {
      name: "8 Tube test kit",
      data: scentAwareUserDatas?.eight?.map((c) => c.average),
    },
  ];

  let chartOneOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },

    grid: {
      show: true, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },

    plotOptions: {
      bar: {
        // colors: {
        //   ranges: [
        //     {
        //       from: 1,
        //       to: 8,
        //       color: "#FF8181",
        //     },
        //     {
        //       from: 9,
        //       to: 16,
        //       color: "#33C9FC",
        //     },
        //   ],
        // },
        horizontal: false,
        columnWidth: "35%",
        borderRadius: 10,
        borderRadiusWhenStacked: "round",
      },
      dataLabels: {
        position: "top",
        maxItems: 100,
        hideOverflowingLabels: true,
        total: {
          enabled: false,
          formatter: undefined,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#ffffff",
            fontSize: "12px",
            fontFamily: undefined,
            fontWeight: 600,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
      width: 1,
    },



    xaxis: {
      type: "datetime",
      categories: scentAwareUserDatas?.dates?.map((c) => getFormattedDate(c)),
      labels: {
        show: true,
        style: {
          colors: "#FFFFFF",
          fontSize: "12px",
          fontFamily: "Work Sans",
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: "#ffffff",
          fontSize: "12px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
      },

      title: {
        text: "Olfactory Function",
        rotate: 270,
        style: {
          color: "#ffffff",
          fontSize: "12px",
          fontFamily: "Work Sans",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },

      axisBorder: {
        show: true,
        color: "#ffffff",
        offsetX: -1,
        offsetY: 0,
      },
    },
    legend: {
      show: true,
      width: 150,
      position: "top",
      offsetY: -8,
      offsetX: 550,
      labels: {
        colors: "#ffffff",
      },
      markers: {
        // fillColors: ["#FF8181", "#33C9FC"],
        width: 18,
        height: 12,
        strokeWidth: 0,
        radius: 5,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 0,
        vertical: 8,
      },
    },
    fill: {
      colors: ["#33C9FC", "#FF8181"],
      opacity: 1,
    },
  };

  let chartTwoData = [
    {
      name: "Recall Accuracy",
      data: traceAwareUserDatas?.averageWiseRecallData?.map((c) => c.average.toFixed(0)),
    },
    {
      name: "Copying Accuracy",
      data: traceAwareUserDatas?.averageWiseCopyData?.map((c) => c.average.toFixed(0)),
    },
  ];
  let chartTwoOption = {
    chart: {
      stacked: true,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      labels: {
        colors: "#ffffff",
      },
    },
    fill: {
      colors: ["#33C9FC", "#33C9FC"],
      opacity: 0.2,
    },
    markers: {
      strokeColors: "#33C9FC",
      strokeWidth: 3,
      colors: "#fff",
      size: 8,
      hover: {
        size: 9,
      },
    },
    xaxis: {
      categories: traceAwareUserDatas?.averageWiseCopyData?.map((c) => c.key),
      labels: {
        show: true,

        style: {
          colors: "#FFFFFF",
          fontSize: "12px",
          fontFamily: "Work Sans",
          cssClass: "apexcharts-xaxis-label",
        },
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        height: 2,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
        // borderType: 'solid',
        // color: '#78909C',
        // height: 6,
        // offsetX: 0,
        // offsetY: 0
      },
    },
    yaxis: {
      title: {
        text: "Accuracy(%)",
        style: {
          color: "#fff",
          fontSize: "10px",
        },
      },
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "12px",
          fontFamily: "Work Sans",
          cssClass: "apexcharts-xaxis-label",
        },
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        height: "100%",
        width: 2,
        offsetX: 0,
        offsetY: 0,
      },
    },
  };

  return (
    <div className="page_wrapper">
      <Row gutter={[20, 0]}>
        <Col lg={6}>
          <DefaultCard padding="0px" background="#013259" className="box-shadow">
            {user?.role === "Customer" ? (
              ""
            ) : (
              <Col lg={24}>

                <Link to="/dashboard/clinical-trial">
                  <img src={arrow} alt="" />
                </Link>
              </Col>
            )}

            <Avatar
              style={{
                backgroundColor: "#0E84F2",
                fontfamily: "WorkSans-Bold",
                width: 150,
                height: 150,
                padding: "48px",
                fontWeight: "600",
                fontSize: "56px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                margin: "0 auto",
              }}
              size="large"
            >
              {participant?.FirstName?.substring(0, 1)}
              {participant?.LastName?.substring(0, 1)}
            </Avatar>

            <h3 className="heading">
              {participant?.FirstName}
              {participant?.LastName}
            </h3>
            <h4
              className="user-personal-text"
              style={{ color: "#ffff", marginLeft: 28 }}
            >
              User ID : {participant?.Id}
            </h4>
            <h4
              className="user-personal-text"
              style={{ color: "#ffff", marginLeft: 28 }}
            >
              Age : {participant?.Age} Years
            </h4>
            <h4
              className="user-personal-text"
              style={{ color: "#ffff", marginLeft: 28 }}
            >
              {participant.Email}
            </h4>

            <hr></hr>

            {/* <div style={{ marginTop: 20 }}>
            <Col lg={22}>
              <div>
                <h4 className="user-bars-text">Olfactory function</h4>
                <Progress
                  percent={80}
                  status="success"
                  strokeColor={"#85E0A3"}
                  showInfo={false}
                />
              </div>
            </Col>
            <Col lg={22}>
              <h4 className="user-bars-text">Visual short term memory</h4>
              <Progress
                percent={30}
                status="exception"
                strokeColor={"#FF8181"}
                showInfo={false}
              />
            </Col>

            <Col lg={22}>
              <h4 className="user-bars-text">Motor function</h4>
              <Progress
                percent={60}
                status="success"
                strokeColor={"#85E0A3"}
                showInfo={false}
              />
            </Col>

            <Col lg={22}>
              <h4 className="user-bars-text">Associative memory</h4>
              <Progress
                percent={40}
                status="exception"
                strokeColor={"#FF8181"}
                showInfo={false}
              />
            </Col>
            <Col lg={22}>
              <h4 className="user-bars-text">Executive function</h4>
              <Progress
                percent={90}
                status="success"
                strokeColor={"#85E0A3"}
                showInfo={false}
              />
            </Col>
            <Col lg={22}>
              <h4 className="user-bars-text">Processing speed</h4>
              <Progress
                percent={19}
                status="exception"
                strokeColor={"#FF8181"}
                showInfo={false}
              />
            </Col>
          </div> */}
          </DefaultCard>
        </Col>

        <Col lg={11}>
          <Col>
            <GraphCard background={"#013259"} className="box-shadow">
              <div className="table-header">
                <div className="title">ScentAware</div>
                <div className="header-icon">
                  <Link
                    to={`/dashboard/scentaware/test-logs/${participant?.Id}`}
                  >
                    <RightOutlined />
                  </Link>
                </div>
              </div>
              <BarChart
                chartData={chartOneData}
                chartOptions={chartOneOptions}
                width={"100%"}
                height={400}
              />
            </GraphCard>
            <div style={{ marginTop: 30 }} className="">
              <GraphCard background={"#013259"}>
                <div className="table-header">
                  <div className="title">TraceAware</div>
                  <div className="header-icon">
                    <Link
                      to={`/dashboard/traceaware/test-logs/${participant?.Id}`}
                    >
                      <RightOutlined />
                    </Link>
                  </div>
                </div>
                <AreaChart
                  chartData={chartTwoData}
                  chartOptions={chartTwoOption}
                  width={"100%"}
                  height={200}
                />
              </GraphCard>
            </div>
          </Col>
        </Col>

        <Col lg={7}>
          <GraphCard background={"#013259"}>
            <div className="table-header">
              <div className="title">AudioAware</div>
              <div className="header-icon">
                <Link
                  to={`/dashboard/audioaware/test-logs/${participant?.Id}`}
                >
                  <RightOutlined />
                </Link>
              </div>
            </div>
            <LineChart
              chartOptions={{
                chart: {
                  background: "#013259",
                  color: "#fff",
                  foreColor: "#fff",
                  type: "line",
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                },

                grid: {
                  show: false,
                  row: {
                    colors: ["transparent"], // takes an array which will be repeated on columns
                    // opacity: 0.5,
                  },
                },
                xaxis: {
                  type: "datetime",
                  categories: audioawareuserdata.map((c) =>
                    getFormattedDate(c.dates.slice(0, 10))
                  ),
                  show: false,
                  // style: {
                  //   colors: "#FFFFFF",
                  //   fontSize: "12px",
                  //   fontFamily: "Work Sans",
                  //   cssClass: "apexcharts-xaxis-label",
                  // },
                  labels: {
                    show: false,
                  },
                  axisBorder: {
                    show: false,
                  },
                  axisTicks: {
                    show: false,
                  },
                },
                yaxis: {
                  labels: {
                    show: false,
                    // style: {
                    //   colors: "#FFFFFF",
                    //   fontSize: "12px",
                    //   fontFamily: "Work Sans",
                    //   cssClass: "apexcharts-xaxis-label",
                    // },
                  },
                },
                colors: ["#FE718E"],
              }}
              chartData={[
                {
                  name: "Average",
                  data: audioawareuserdata.map((c) => c.Average),
                },
              ]}
              width={"100%"}
              height={80}
            />
            <div style={{ marginLeft: "5px" }}>
              <img src={upDown} alt="" />
              <Link
                style={{
                  color: "#FE718E",
                  fontSize: "14px",
                  paddingLeft: "25px",
                  paddingRight: "10px",
                }}
              >
                Verbal Memory
              </Link>
              <ArrowDownOutlined style={{ color: "#FE718E", fontSize: "14px" }} />
            </div>
          </GraphCard>
          <div style={{ marginTop: 10 }}>
            <GraphCard background={"#013259"}>
              <div className="table-header">
                <div className="title">GlanceAware</div>
                <div className="header-icon">
                  <Link
                    to={`/dashboard/glanceaware/test-logs/${participant?.Id}`}
                  >
                    <RightOutlined />
                  </Link>
                </div>
              </div>
              <LineChart
                chartOptions={{
                  chart: {
                    background: "#013259",
                    color: "#fff",
                    foreColor: "#fff",
                    stroke: "#85E0A3",
                    type: "line",
                    toolbar: {
                      show: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "smooth",
                  },

                  grid: {
                    show: false, //hide grid
                    row: {
                      colors: ["transparent"], // takes an array which will be repeated on columns
                      opacity: 0.5,
                    },
                  },
                  xaxis: {
                    type: "datetime",

                    categories: glanceAwareUserData.map((c) =>
                      c.key
                    ),

                    crosshairs: {
                      show: false,
                    },
                    labels: {
                      show: false,
                    },
                    axisBorder: {
                      show: false,
                    },
                    axisTicks: {
                      show: false,
                    },

                    // style: {
                    //   colors: "#FFFFFF",
                    //   fontSize: "12px",
                    //   fontFamily: "Work Sans",
                    //   cssClass: "apexcharts-xaxis-label",
                    // },
                  },
                  yaxis: {
                    labels: {
                      show: false,
                      // style: {
                      //   colors: "#FFFFFF",
                      //   fontSize: "12px",
                      //   fontFamily: "Work Sans",
                      //   cssClass: "apexcharts-xaxis-label",
                      // },
                    },
                  },
                  colors: ["#85E0A3"],
                }}
                chartData={[
                  {
                    name: "Average",
                    data: glanceAwareUserData.map((c) => c.key),
                  },
                ]}
                width={"100%"}
                height={80}
              />
              <div style={{ marginLeft: "5px" }}>
                <img src={upDown} alt="" />
                <Link
                  style={{
                    color: "#85E0A3",
                    fontSize: "14px",
                    paddingLeft: "25px",
                    paddingRight: "10px",
                  }}
                >
                  Assosiate Memory
                </Link>
                <ArrowUpOutlined style={{ color: "#85E0A3", fontSize: "14px" }} />
              </div>
              {/* <ArrowDownOutlined /> */}
            </GraphCard>
          </div>

          <div style={{ marginTop: 10 }}>
            <GraphCard background={"#013259"}>
              <div className="table-header">
                <div className="title">WordsAware</div>
                <div className="header-icon">
                  <Link
                    to={`/dashboard/wordsaware/test-logs/${participant?.Id}`}
                  >
                    <RightOutlined />
                  </Link>
                </div>
              </div>
              <LineChart
                chartOptions={{
                  chart: {
                    background: "#013259",
                    color: "#fff",
                    foreColor: "#fff",
                    type: "line",
                    toolbar: {
                      show: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "smooth",
                  },

                  grid: {
                    show: false,
                    row: {
                      colors: ["transparent"], // takes an array which will be repeated on columns
                      // opacity: 0.5,
                    },
                  },
                  xaxis: {
                    type: "datetime",
                    categories: wordsAwareUserDataa.map((c) =>
                      getFormattedDate(c.dates.slice(0, 10))
                    ),
                    // categories: [],
                    show: false,
                    style: {
                      colors: "#FFFFFF",
                      fontSize: "12px",
                      fontFamily: "Work Sans",
                      cssClass: "apexcharts-xaxis-label",
                    },
                    labels: {
                      show: false,
                    },
                    axisBorder: {
                      show: false,
                    },
                    axisTicks: {
                      show: false,
                    },
                  },
                  yaxis: {
                    labels: {
                      show: false,
                      style: {
                        colors: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Work Sans",
                        cssClass: "apexcharts-xaxis-label",
                      },
                    },
                  },
                  colors: ["#85E0A3"],
                }}
                chartData={[
                  {
                    name: "Average",
                    data: wordsAwareUserDataa.map((c) => c.Average),
                  },
                ]}
                width={"100%"}
                height={80}
              />
            </GraphCard>
          </div>
          <div style={{ marginTop: 10 }}>
            <GraphCard background={"#013259"}>
              <div className="table-header">
                <div className="title">GrammarAware</div>
                <div className="header-icon">
                  <Link
                    to={`/dashboard/grammaraware/test-logs/${participant?.Id}`}
                  >
                    <RightOutlined />
                  </Link>
                </div>
              </div>
              <LineChart
                chartOptions={{
                  chart: {
                    background: "#013259",
                    color: "#fff",
                    foreColor: "#fff",
                    type: "line",
                    toolbar: {
                      show: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "smooth",
                  },

                  grid: {
                    show: false,
                    row: {
                      colors: ["transparent"], // takes an array which will be repeated on columns
                      opacity: 0.5,
                    },
                  },
                  xaxis: {
                    type: "datetime",
                    categories: grammeraware.map((c) =>
                      getFormattedDate(c.CreatedDate.slice(0, 10))
                    ),
                    show: false,
                    style: {
                      colors: "#FFFFFF",
                      fontSize: "12px",
                      fontFamily: "Work Sans",
                      cssClass: "apexcharts-xaxis-label",
                    },
                    labels: {
                      show: false,
                    },
                    axisBorder: {
                      show: false,
                    },
                    axisTicks: {
                      show: false,
                    },
                  },
                  yaxis: {
                    labels: {
                      show: false,
                      style: {
                        colors: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Work Sans",
                        cssClass: "apexcharts-xaxis-label",
                      },
                    },
                  },
                  colors: ["#85E0A3"],
                }}
                chartData={[
                  {
                    name: "Average",
                    data: grammeraware.map((c) => c.Average.toFixed(0)),
                  },
                ]}
                width={"100%"}
                height={80}
              />
            </GraphCard>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default UserDetail;
