import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Card from "../components/Header/Card";
import DynamicTable from "../pages/DynamicTable";
import Left from "../assets/images/Left.png";
import Print from "../assets/images/Print.png";
import Download from "../assets/images/Download.png";
import Doubleauro from "../assets/images/Doubleauro.png";
import * as traceawareApi from "../services/api/traceaware";
import { Link } from "react-router-dom";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import * as XLSX from "xlsx";

const columns = [
  {
    title: "Stuffy Nose",
    dataIndex: "IsStuffyNose",
    render: (IsStuffyNose) => (IsStuffyNose ? "True" : "False"),
  },
  {
    title: "There Loss Of Memory",
    dataIndex: "IsThereLossOfMemory",
    render: (IsThereLossOfMemory) => (IsThereLossOfMemory ? "True" : "False"),
  },
  {
    title: "Session End Time",
    dataIndex: "SessionEndTime",
  },
  {
    title: "Session Id",
    dataIndex: "SessionId",
  },
  {
    title: "Session Start Time",
    dataIndex: "SessionStartTime",
  },
  {
    title: "User Id",
    dataIndex: "UserId",
  },
  {
    title: "Created",
    dataIndex: "dt_created",
  },
];

const Traceaware = () => {
  const [traceawareTable, setTraceawareTable] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTraceawareData();
  }, []);

  const getTraceawareData = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await traceawareApi?.getTraceaware();
      setTraceawareTable(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleExport = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(traceawareTable);

    XLSX.utils.book_append_sheet(wb, ws, "traceaware");
    XLSX.writeFile(wb, "traceaware.xlsx");
  };

  console.log("traceawareTable", traceawareTable);
  return (
    <>
      <div className="Container">
        <Row className="Maincontainer">
          <Col span={8}>
            <div className="Leftside-col">
              <Link to={"/dashboard"}>
                <img src={Left}></img>
              </Link>
              <h2>
                Data collected on 8 scent<br></br> kit tests
              </h2>
            </div>
          </Col>

          <Col span={8}>
            <Card className="firstcard">
              <div className="card-digit-section">
                <div className="content">
                  <h4>
                    Total Tests Performed<br></br>Globally :
                  </h4>
                  <h4>
                    Tests Perfomed<br></br>this months :
                  </h4>
                </div>
                <div className="number">
                  <h5>11,308</h5>
                  <h5>8,020</h5>
                </div>
              </div>
            </Card>
          </Col>

          <Col span={8}>
            <Card>
              <div className="card-digit-section">
                <div className="content">
                  <h4>
                    Averege trancing<br></br>accurancy :
                  </h4>
                  <h4>
                    Averege response<br></br>time :
                  </h4>
                </div>
                <div className="number">
                  <h5>53%</h5>
                  <h5>23.1 sec</h5>
                </div>
              </div>
            </Card>
          </Col>

          <Col className="Trace-position-changed">
            <Col span={16} className="Trace-filter">
              <Col>
                <div className="Rightside-col">
                  <button>
                    {" "}
                    <img src={Doubleauro}></img>
                    Sort by
                  </button>
                </div>
              </Col>
              <Col>
                <div className="center-col">
                  <img src={Print}></img>
                  <h4>Print</h4>
                  <Col span={8}>
                    <div className="Download-col" onClick={handleExport}>
                      <img src={Download}></img>
                      <h4>Download</h4>
                    </div>
                  </Col>
                </div>
              </Col>
            </Col>
          </Col>
        </Row>
        <DynamicTable
          columns={columns}
          dataSource={traceawareTable}
          loading={loading}
        />
      </div>
    </>
  );
};
export default Traceaware;
