import axios from "axios";
import React, { useState, useEffect } from "react";
import { Col, Checkbox, Form, Input, message } from "antd";
import * as AuthApi from "../services/api/auth";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../components/Button/Button";
import AuthHeader from "../components/Dashboard/AuthHeader";
import * as adminApi from "../services/api/admin";
import { toast } from "react-toastify";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

function ConfirmPassword() {

  // console.log("0==================",userInfo.id);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();

  // Step 2: Reset the form on load
  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onFinish = async (credentials) => {
    try {
      setLoader(true);
      var email = localStorage.getItem("forgotPassCredentials");
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const json1 = {
        EmailAddress: JSON.parse(email),
        Url: "/change-password",
        ConfirmationCode: credentials.otp,
        NewPassword: credentials.password,
        // UserId: userInfo.id
      }

      axios
        .post(
          "https://3vvm8kk6z7.execute-api.us-east-1.amazonaws.com/prod",
          json1
        )
        .then((res) => {
          setLoader(false);
          if (res.data && res.data.IsSuccess) {
            toast.success("Password Updated Successfully.");
            navigate("/login");
          }
          else{
            toast.error(res.data.Message);
          }
        });

    } catch (error) {
      setLoader(false);
      console.log(error)
    }

    //   const json = {
    //     emailId: JSON.parse(email),
    //     newPassword: credentials.password,
    //     otp: credentials.otp,
    //   };
    //   axios
    //     .post(
    //       "https://gokepiqof3.execute-api.us-east-1.amazonaws.com/prod/api/admin/confirm-password",
    //       json
    //     )
    //     .then((res) => {
    //       toast.success("Password Updated Successfully.");
    //       navigate("/");
    //     });

    // } catch (error) {
    //   console.log(error)
    // }
  };

  return (
    <>
      <div className="login">
        {/* <AuthHeader/> */}
        <div className="forget-section">
          <Col>
            <Form
              className="login-form"
              name="basic"
              onFinish={onFinish}
              layout="vertical"
              style={{ marginTop: "0px" }}
              form={form}
            >
              <div className="input-field">
                <h4 style={{ color: "white" }}>Confirm Password</h4>
                <Form.Item
                  label={<label style={{ color: "White" }}>Email Verification Code:</label>}
                  name="otp"
                  rules={[
                    { required: true, message: "Please input your verification code!" },

                  ]}
                  style={{ color: "white" }}
                >
                  <Input type="otp" />
                </Form.Item>
                <Form.Item
                  label={<label style={{ color: "White" }}>New Password:</label>}
                  name="password"
                  rules={[
                    { required: true, message: "Please input your New Password!" },
                  ]}
                  style={{ color: "white" }}
                >
                  <Input type="password" />
                </Form.Item>
                <DefaultButton
                  className="colored_btn"
                  title={"Submit"}
                  loading={loader}
                  htmlType={"submit"}
                />
              </div>
            </Form>
          </Col>
        </div>
      </div>
    </>
  );
}
export default ConfirmPassword;
