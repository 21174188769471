import { axiosApi } from "../axios/axios_config";

export async function getLogsData(pagination) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  let endpoint = `admin/logs?userId=${userInfo.id}`;
  if (pagination) {
    endpoint += `${pagination?.pageIndex ? `&pageIndex=${pagination.pageIndex}` : ''}${pagination?.pageSize ? `&pageSize=${pagination.pageSize}` : ''}`;
  }
  try {
    const { data, response } = await axiosApi({
      endpoint: endpoint,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}
