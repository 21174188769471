import React, { useEffect, useState } from "react";
import {
  LeftOutlined,
  PrinterOutlined,
  DownloadOutlined,
  ExperimentOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Col, Row, Table, Divider } from "antd";
import * as audioAwareApi from "../../services/api/audioware";
import { Link, useParams } from "react-router-dom";
import tableExport from "antd-table-export";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import moment from "moment";
import CustomTable from "../../components/Table/CustomTable";
import useDebounce from '../../components/Table/debounce';

const AudioAwareTestLogs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState({
    filterBy: '',
    filterValue: ''
  });

  const getAudioAwareData = async () => {
    try {
      setLoading(true);
      var {
        data: { data, totalCount },
      } = await audioAwareApi?.getAudioaware(pagination, filter);

      setPagination((state) => ({ ...state, totalCount: totalCount }));
      if (id) {
        data = data.filter((c) => c.UserId == id);
      }

      const audioAwareData = [];

      data.forEach((el) => {
        let scoreData = el.SelectedWords.split(",").reduce((acc, curr) => {
          if (el?.PlayedWords?.split(",")?.includes(curr)) {
            acc++;
          }
          return acc;
        }, 0);

        let playwordVal = el.PlayedWords;
        let playwordArr = playwordVal.split(",");

        let selectedAns = el.SelectedWords;
        var ans = selectedAns.split(",");

        // playwordArr.forEach((value, index) => {

        let newObj = {
          AudioAwareId: el.AudioAwareId,
          UserId: el.UserId,
          SessionId: el.SessionId,
          Date_Created: moment(el.Date_Created).format("MM/DD/Y HH:mm:ss"),
          IsThereLossOfMemory: el.IsThereLossOfMemory,
          score: el.Score,
          DisplayedWords: el.DisplayedWords,
          DisplayedWordsStartTime: moment(el.DisplayedWordsStartTime).format(
            "MM/DD/Y HH:mm:ss"
          ),
          DisplayedWordsEndTime: moment(el.DisplayedWordsEndTime).format(
            "MM/DD/Y HH:mm:ss"
          ),
          PlayedWord: el.PlayedWords,
          SelectedWord: el.SelectedWords,
          LevelType: el.LevelType,
        };
        audioAwareData.push(newObj);
        // })
      });

      /* data = data.map((c) => ({
        ...c,
        score: c.SelectedWords.split(",").reduce((acc, curr) => {
          if (c?.PlayedWords?.split(",")?.includes(curr)) {
            acc++;
          }
          return acc;
        }, 0),
      })); */
      // let datao = data.master.map((c) => ({
      //   ...c,
      //   Score: c.SelectedWords.includes(c.PlayedWords),
      // }));
      // console.log("Harihar", data);
      setData(audioAwareData);
      // setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };


  const columns = [
    {
      Header: "AudioAware Id",
      accessor: "AudioAwareId",
    },
    {
      Header: "User Id",
      accessor: "UserId",
    },
    {
      Header: "Session Id",
      accessor: "SessionId",
    },
    {
      Header: "Level Type",
      accessor: "LevelType",
    },
    {
      Header: "Displayed Words",
      accessor: "DisplayedWords",
    },
    {
      Header: "Played Word",
      accessor: "PlayedWord",
    },
    {
      Header: "Selected Word",
      accessor: "SelectedWord",
    },
    {
      Header: "Score",
      accessor: "score",
    },
    {
      Header: "Displayed Words Start Time",
      accessor: "DisplayedWordsStartTime",
    },
    {
      Header: "Displayed Words End Time",
      accessor: "DisplayedWordsEndTime",
    },
    {
      Header: "Date",
      accessor: "Date_Created",
    },
  ];
  // const columns = [
  //   {
  //     title: "AudioAware Id",
  //     field: "AudioAwareId",
  //   },
  //   {
  //     title: "UserId",
  //     field: "UserId",
  //   },
  //   {
  //     title: "Session Id",
  //     field: "SessionId",
  //   },

  //   {
  //     title: "LevelType",
  //     field: "LevelType",
  //   },
  //   {
  //     title: "Displayed Words",
  //     field: "DisplayedWords",
  //   },

  //   {
  //     title: "PlayedWord",
  //     field: "PlayedWord",
  //   },
  //   {
  //     title: "SelectedWord",
  //     field: "SelectedWord",
  //   },
  //   {
  //     title: "Score",
  //     field: "score",
  //   },
  //   {
  //     title: "Displayed Words Start Time",
  //     field: "DisplayedWordsStartTime",
  //   },
  //   {
  //     title: "Displayed Words End Time",
  //     field: "DisplayedWordsEndTime",
  //   },
  //   {
  //     title: "Date",
  //     field: "Date_Created",
  //   },
  // ];

  const exportInstance = new tableExport(data, columns);
  function downloadExcel() {
    exportInstance.download("AudioAwareTestLogs");
  }


  const debouncedFilterValue = useDebounce(filter.filterValue, 300);

  const handleSearchFilter = (e) => {
    setFilter((state) => ({ ...state, filterValue: e.target.value }))
  };

  useEffect(() => {
    if (debouncedFilterValue !== null) {
      getAudioAwareData();
    }
  }, [currentPage, debouncedFilterValue]);


  var returnPath = "/dashboard";
  if (id) {
    returnPath = "/dashboard/user-details/" + id;
  }
  return (
    <>
      <CustomTable
        columns={columns}
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
        setCurrentPage={setCurrentPage}
        setFilter={setFilter}
        handleSearchFilter={handleSearchFilter}
        filter={filter}
        type={'audioAware'}
      />

    </>


    // <div className="container">
    //   <div className="test-log">
    //     <span className="left-outlined">
    //       <Link to={returnPath}>
    //         <LeftOutlined />
    //       </Link>
    //     </span>
    //     <h2 style={{ fontSize: "32px", color: "white" }}>Test logs</h2>
    //   </div>
    //   <Divider />
    //   <Row className="table-background" gutter={[22, 22]}>
    //     <Col lg={24}>
    //       <MaterialTable
    //         title="AudioAware"
    //         columns={columns}
    //         data={data}
    //         options={{
    //           tableLayout: "fixed",
    //           exportAllData: true,
    //           exportMenu: [
    //             {
    //               label: "Export PDF",
    //               exportFunc: (columns, data) =>
    //                 ExportPdf(columns, data, "AudioAware"),
    //             },
    //             {
    //               label: "Export CSV",
    //               exportFunc: (columns, data) =>
    //                 ExportCsv(columns, data, "AudioAware"),
    //             },
    //           ],
    //           filtering: true,
    //           sorting: true,
    //           headerStyle: {
    //             backgroundColor: "#01579b",
    //             color: "#FFF",
    //           },
    //           cellStyle: {
    //             backgroundColor: "#01579b",
    //             color: "#FFF",
    //             wordWrap: "break-word",
    //           },
    //           pageSize: 10,
    //           pageSizeOptions: [10, 50, 100],
    //         }}
    //       />
    //     </Col>
    //   </Row>
    // </div>
  );
};

export default AudioAwareTestLogs;
