import logo from "../../assets/images/logo.png";
import searchicon from "../../assets/images/searchicon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import React, { Component } from "react";

const AuthHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="menubar-2">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="mendiv">
            {location?.pathname == "/login" ||
            location?.pathname == "/forget-password" ||
            location?.pathname == "/change-password" ? (
              <a className="navbar-brand" href="#">
                <img src={logo}></img>
              </a>
            ) : (
              <Link to={"/dashboard"}>
                <img src={logo}></img>
              </Link>
            )}
            {location?.pathname == "/login" ||
            location?.pathname == "/forget-password" ||
            location?.pathname == "/change-password" ? null : (
              <>
                <div className="input-icons">
                  <img src={searchicon}></img>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Search"
                  ></input>
                </div>
                <a
                  className="hello"
                  onClick={() => {
                    localStorage.removeItem("userInfo");
                    navigate("/login");
                  }}
                >
                  Logout
                </a>
              </>
            )}
          </div>
        </nav>
      </div>
    </>
  );
};
export default AuthHeader;
