import React from "react";
import {
  LeftOutlined,
  PrinterOutlined,
  DownloadOutlined,
  ExperimentOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Col, Row, Table, Space, Divider } from "antd";

export default function Testlog() {
  const columns = [
    {
      title: "Session ID",
      dataIndex: "Session ID",
      key: "Session ID",
      render: (text) => <a>{text}</a>,
      width: 150,
    },
    {
      title: "User details",
      dataIndex: "User details",
      key: "address 0",
      width: 80,
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "address 1",
      ellipsis: true,
    },
    {
      title: "Kit type",
      dataIndex: "Kit type",
      key: "address 2",
      ellipsis: true,
    },
    {
      title: "Duration (mins)",
      dataIndex: "Duration (mins)",
      key: "address 3",
      ellipsis: true,
    },
    {
      title: "EXE function",
      dataIndex: "EXE function",
      key: "address 5",
      ellipsis: true,
    },
    {
      title: "OLF function",
      dataIndex: "OLF function",
      key: "address 6",
      ellipsis: true,
    },
    {
      title: "Stuffy nose?",
      dataIndex: "Stuffy nose?",
      key: "address 7",
      ellipsis: true,
    },
    {
      title: "Memory loss?",
      dataIndex: "Memory loss?",
      key: "address 8",
      ellipsis: true,
    },
  ];

  const data = [
    {
      key: "Session ID",
      name: "ABC00001",
      age: 32,
      address: " 1 Lake Park",
      tags: ["nice", "developer"],
    },
    {
      key: "address 1",
      name: "21-10-2022",
      age: 42,
      address: "London No. 2 Lake Park, London No. 2 Lake Park",
      tags: ["loser"],
    },
    {
      key: "3",
      name: "16 scent",
      age: 32,
      address: "Sidney No. 1 Lake Park, Sidney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
    {
      key: "3",
      name: "5:32",
      age: 32,
      address: "Sidney No. 1 Lake Park, Sidney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
    {
      key: "3",
      name: "12 sec",
      age: 32,
      address: "Sidney No. 1 Lake Park, Sidney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
    {
      key: "3",
      name: "30 / 36",
      age: 32,
      address: "Sidney No. 1 Lake Park, Sidney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
    {
      key: "3",
      name: "Yes",
      age: 32,
      address: "Sidney No. 1 Lake Park, Sidney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
    {
      key: "3",
      name: "Yes",
      age: 32,
      address: "Sidney No. 1 Lake Park, Sidney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
    {
      key: "3",
      name: "Yes",
      age: 32,
      address: "Sidney No. 1 Lake Park, Sidney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
  ];

  return (
    <div className="container">
      <div className="test-log">
        <span className="left-outlined">
          <LeftOutlined />
        </span>
        <h2>Test logs</h2>
      </div>
      <Row gutter={18}>
        <Col lg={3}>
          <div className="print">
            <h5>Print PDF </h5>
            <PrinterOutlined />
          </div>
        </Col>

        <Col lg={3}>
          <div className="download">
            <h5>Download</h5>
            <DownloadOutlined />
          </div>
        </Col>

        <Col lg={2.5}>
          <div className="filters">
            <h5>Filters</h5>
            <ExperimentOutlined />
          </div>
        </Col>

        <Col lg={3}>
          <div className="sort-by">
            <h5>Sort by</h5>
            <DownOutlined />
          </div>
        </Col>

        <Col lg={12}>
          <div className="search-input">
            <form action="/action_page.php">
              <SearchOutlined />
              <input
                type="text"
                placeholder="Search  bar"
                name="search"
              ></input>
            </form>
          </div>
        </Col>
      </Row>

      <Divider />
      <Row className="table-background" gutter={[22, 22]}>
        <Col lg={24}>
          <Table columns={columns} dataSource={data} />
        </Col>
      </Row>
    </div>
  );
}
