/** @format */

import React, { useEffect, useState } from "react";
import {
  LeftOutlined,
  PrinterOutlined,
  DownloadOutlined,
  ExperimentOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Col, Row, Table, Divider } from "antd";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import * as wordsAwareApi from "../../services/api/wordsaware";
import { Link, useParams } from "react-router-dom";
import tableExport from "antd-table-export";
import moment from "moment";
import CustomTable from "../../components/Table/CustomTable";
import useDebounce from '../../components/Table/debounce';

const WordsAwareTestLogs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  console.log("userInfo", userInfo);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState({
    filterBy: '',
    filterValue: ''
  });

  const getWordsAwareData = async () => {
    try {
      setLoading(true);
      var {
        data: { data, totalCount },
      } = await wordsAwareApi?.getWordsaware(id, pagination, filter);
      setPagination((state) => ({ ...state, totalCount: totalCount }));
      if (id) {
        data = data.filter((c) => c.UserId == id);
      }

      data = data.map((c) => ({
        ...c,
        Date_Created: moment(c.Date_Created).format("MM/DD/Y HH:mm:ss"),
        LongestWord: c.Words.split(",")
          .filter((w) =>
            w.trim().toLowerCase().startsWith(c.ReferenceLetters.toLowerCase())
          )
          ?.reduce(function (a, b) {
            return a.trim().length > b.trim().length ? a : b;
          }, ""),
        ShortestWord: c.Words.split(",").filter((w) =>
          w.trim().toLowerCase().startsWith(c.ReferenceLetters.toLowerCase())
        )?.length
          ? c.Words.split(",")
            .filter((w) =>
              w
                .trim()
                .toLowerCase()
                .startsWith(c.ReferenceLetters.toLowerCase())
            )
            ?.reduce(function (a, b) {
              return a.trim().length <= b.trim().length ? a : b;
            })
          : "",
        matchingWords: c.Words.split(",")
          .filter((w) =>
            w.trim().toLowerCase().startsWith(c.ReferenceLetters.toLowerCase())
          )
          .join(","),

        uniqueWordsCount: c.Words.split(",")
          .filter((w) =>
            w.trim().toLowerCase().startsWith(c.ReferenceLetters.toLowerCase())
          )
          .filter(unique).length,
      }));

      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const unique = (item, index, array) => {
    return array.indexOf(item) == index;
  };

  // const columns = [
  //   {
  //     title: "WordAwareId",
  //     field: "WordAwareId",
  //   },
  //   {
  //     title: "User Id",
  //     field: "UserId",
  //   },
  //   {
  //     title: "Session Id",
  //     field: "SessionId",
  //   },
  //   {
  //     title: "Words",
  //     field: "Words",
  //   },
  //   {
  //     title: "Reference Letters",
  //     field: "ReferenceLetters",
  //   },
  //   {
  //     title: "Longest Word",
  //     field: "LongestWord",
  //   },
  //   {
  //     title: "Shortest Word",
  //     field: "ShortestWord",
  //   },
  //   {
  //     title: "Matching Words",
  //     field: "matchingWords",
  //   },
  //   {
  //     title: "Unique Word Count",
  //     field: "uniqueWordsCount",
  //   },
  //   {
  //     title: "Date",
  //     field: "Date_Created",
  //   },
  // ];

  const columns = [
    {
      Header: "WordAware Id",
      accessor: "WordAwareId",
    },
    {
      Header: "User Id",
      accessor: "UserId",
    },
    {
      Header: "Session Id",
      accessor: "SessionId",
    },
    {
      Header: "Words",
      accessor: "Words",
    },
    {
      Header: "Reference Letters",
      accessor: "ReferenceLetters",
    },
    {
      Header: "Longest Word",
      accessor: "LongestWord",
    },
    {
      Header: "Shortest Word",
      accessor: "ShortestWord",
    },
    {
      Header: "Matching Words",
      accessor: "matchingWords",
    },
    {
      Header: "Unique Word Count",
      accessor: "uniqueWordsCount",
    },
    {
      Header: "Date",
      accessor: "Date_Created",
    },
  ];


  // const array = [];
  // const dataWord = data[1]?.Words.split(",");
  // for (let i = 0; i < dataWord?.length; i++) {
  //   array.push(dataWord[i]);
  // }

  // let word = [];
  // for (let i = 0; i < array.length; i++) {
  //   const length = array[i].length;
  //   const words = array[i];
  //   const obj = { words: { words, length } };
  //   word.push(obj);
  // }

  // const Max = [];
  // for (let i = 0; i < word.length; i++) {
  //   const data = word[i].words.length;
  //   const maxLength = Math.max(data);
  //   Max.push(maxLength);
  // }
  // Max.sort();
  // let maxElement = Max.pop();
  // console.log(maxElement);
  // let bigLetter = [];
  // console.log(word[0]?.words);
  // for (let i = 0; i < word.length; i++) {
  //   bigLetter.push(Object.keys(word[i]?.words?.length === maxElement) === false);
  // }

  // console.log("BiggestLetter", bigLetter);
  const exportInstance = new tableExport(data, columns);
  function downloadExcel() {
    exportInstance.download("WordsAwareTestLogs");
  }

  const debouncedFilterValue = useDebounce(filter.filterValue, 300);

  const handleSearchFilter = (e) => {
    setFilter((state) => ({ ...state, filterValue: e.target.value }))
  };

  useEffect(() => {
    if (debouncedFilterValue !== null) {
      getWordsAwareData();
    }
  }, [currentPage, debouncedFilterValue]);


  var returnPath = "/dashboard";
  if (id) {
    returnPath = "/dashboard/user-details/" + id;
  }

  return (
    <>


      <CustomTable
        columns={columns}
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
        setCurrentPage={setCurrentPage}
        setFilter={setFilter}
        handleSearchFilter={handleSearchFilter}
        filter={filter}
        type={'wordsAware'}
      />
    </>

    // <div className="container">
    //   <div className="test-log">
    //     <span className="left-outlined">
    //       <Link to={returnPath}>
    //         <LeftOutlined />
    //       </Link>
    //     </span>
    //     <h2 style={{ fontSize: "32px", color: "white" }}>Test logs</h2>
    //   </div>

    //   <Divider />
    //   <Row className="table-background" gutter={[22, 22]}>
    //     <Col lg={24}>
    //       <MaterialTable
    //         title="WordsAware"
    //         columns={columns}
    //         data={data}
    //         options={{
    //           tableLayout: "fixed",
    //           exportAllData: true,
    //           exportMenu: [
    //             {
    //               label: "Export PDF",
    //               exportFunc: (columns, data) =>
    //                 ExportPdf(columns, data, "Wordaware"),
    //             },
    //             {
    //               label: "Export CSV",
    //               exportFunc: (columns, data) =>
    //                 ExportCsv(columns, data, "Wordaware"),
    //             },
    //           ],
    //           filtering: true,
    //           sorting: true,
    //           headerStyle: {
    //             backgroundColor: "#01579b",
    //             color: "#FFF",
    //           },
    //           cellStyle: {
    //             backgroundColor: "#01579b",
    //             color: "#FFF",
    //             wordWrap: "break-word",
    //           },
    //           pageSize: 10,
    //           pageSizeOptions: [10, 50, 100],
    //         }}
    //       />
    //     </Col>
    //   </Row>
    // </div>
  );
};

export default WordsAwareTestLogs;
