import { Col, Row } from "antd";
import React, { useState } from "react";
import { toast } from 'react-toastify';

export default function AddModerators({
  form,
  nextStep,
  clinicalTrialDetails,
  setClinicalTrialDetails,
}) {
  const [moderator, setModerator] = useState({
    email: '',
    firstName: '',
    lastName: '',
    role: ''
  });

  const [error, setError] = useState({});
  const [isParticipantEdit, setIsParticipantEdit] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModerator((state) => ({ ...state, [name]: value }));
  }

  const addModerator = (e) => {
    try {
      e.preventDefault();

      // Validate moderator before adding
      if (validateModerator()) {
        return;
      }

      setClinicalTrialDetails((prevState) => ({
        ...prevState,
        moderators: [...prevState.moderators, moderator],
      }));

      // Reset moderator state
      setModerator({
        email: '',
        firstName: '',
        lastName: '',
        role: ''
      });
      setError({});
    } catch (error) {
      console.log(error);
    }
  };

  const validateModerator = () => {
    let hasError = false;
    const newError = {};

    // Validate email
    if (moderator.email.trim() === '') {
      hasError = true;
      newError.email = 'Email is required';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(moderator.email.trim())) {
      hasError = true;
      newError.email = 'Invalid email format';
    }

    // Validate email
    if (moderator.role.trim() === '') {
      hasError = true;
      newError.role = 'Role is required';
    }

    // Add more validation rules for other fields if needed

    // Update the error state
    setError(newError);

    return hasError;
  }


  return (
    <Row>
      <div className="form-content">
        {/* <Col lg={24}>
          <div className="title-input">
            <Form.Item
              label="Email"
              rules={[{ required: true, message: "Email is required" }]}
              name={"email"}
            >
              <Input placeholder="Provide a Email"
               className="w-100"
               name="email"
               value={moderator.email}
               onChange={(e) => {handleInputChange(e)}} />
            </Form.Item>
          </div>
        </Col>
        <Col lg={24}>
          <div className="title-input">
            <Form.Item
              label="First Name"
              rules={[{ required: true, message: "First Name is required" }]}
              name={"firstName"}
            >
              <Input placeholder="First Name" className="w-100"
              name="firstName"
              value={moderator.firstName}
              onChange={(e) => {handleInputChange(e)}} />
            </Form.Item>
          </div>
        </Col>
        <Col lg={24}>
          <div className="title-input">
            <Form.Item
              label="Last Name"
              rules={[{ required: true, message: "Last Name is required" }]}
              name={"lastName"}
            >
              <Input placeholder="Last Name" className="w-100"
              name="lastName"
              value={moderator.lastName}
              onChange={(e) => {handleInputChange(e)}}
               />
            </Form.Item>
          </div>
        </Col>
        <Col lg={24}>
          <div className="title-input">
            <Form.Item
              label="Role"
              rules={[{ required: true, message: "Role is required" }]}
              name={"role"}
            >
              <select class="form-select w-100" name="role"
                value={moderator.role}
                onChange={(e) => {handleInputChange(e)}} aria-label="Default select example">
                  <option selected>Select Role</option>
                  <option value="Reserchar">Reserchar</option>
                  <option value="PI">PI</option>
              </select>
            </Form.Item>
          </div>
          <Button disabled={!moderator} onClick={addModerator}>Add</Button>
        </Col> */}
        <form >
          <div className="row g-3">
            <div className="col-12">
              <label className="ant-form-item-required form_label w-100"><span>*</span> Email</label>
              <div className="input-group">
                <input type="text" disabled={isParticipantEdit} name="email" value={moderator.email} className="form-control" placeholder="Email" aria-label="email"
                  aria-describedby="basic-addon1" style={{ height: '55px' }}
                  onChange={(e) => handleInputChange(e)} />
              </div>
              {(error && error.email && <span style={{ color: 'red' }}>{error.email}</span>)}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <label className="ant-form-item-required form_label w-100"> Firstname</label>
              <div className="input-group">
                <input type="text" name="firstName" value={moderator.firstName} className="form-control" placeholder="First Name" aria-label="Username"
                  aria-describedby="basic-addon1" style={{ height: '55px' }}
                  onChange={(e) => handleInputChange(e)} />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <label className="ant-form-item-required form_label w-100"> Lastname</label>
              <div className="input-group">
                <input type="text" name="lastName" value={moderator.lastName} className="form-control" placeholder="Last Name" aria-label="Username"
                  aria-describedby="basic-addon1" style={{ height: '55px' }}
                  onChange={(e) => handleInputChange(e)} />
              </div>
            </div>
            <div className="col-12">
              <label className="ant-form-item-required form_label w-100"><span>*</span> Role</label>
              <div className="input-group">
                <select class="form-select" name="role" style={{ height: '55px' }} value={moderator.role} aria-label="Default select example"
                  onChange={(e) => handleInputChange(e)}>
                  <option selected value="">Select Role</option>
                  <option value="Researcher">Researcher</option>
                  <option value="PI">PI</option>
                </select>
              </div>
              {(error && error.role && <span style={{ color: 'red' }}>{error.role}</span>)}
            </div>
            <div className="col-12  d-flex align-items-center justify-content-end">
              <button type="button" onClick={(e) => addModerator(e)} className="btn btn-primary text-white">{isParticipantEdit ? "Update" : "Add"}</button>
            </div>
          </div>
        </form>
        <Col lg={24}>
          <table className="table">
            <thead>
              <tr>
                <th>Email</th>
                <th>FirstName</th>
                <th>LastName</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {
                clinicalTrialDetails
                &&
                clinicalTrialDetails.moderators.map((x, i) => {
                  return (
                    <tr>
                      <td>{x.email}</td>
                      <td>{x.firstName}</td>
                      <td>{x.lastName}</td>
                      <td>{x.role}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </Col>
      </div>
    </Row>
  );
}
