import { Col, Row } from "antd";
import React, { useState, useCallback } from "react";
import { toast } from "react-toastify";
import { isParticipantIdExists } from "../../../services/api/admin";
import debounce from "lodash/debounce";
import { useNavigate, useParams } from "react-router-dom";
export default function AddParticipants({
  form,
  nextStep,
  clinicalTrialDetails,
  setClinicalTrialDetails,
}) {
  // const [participant, setParticipant] = useState(null);
  // const [moderator, setModerator] = useState(null);
  const [isParticipantEdit, SetIsParticipantEdit] = useState(false);
  const [participant, setParticipant] = useState({
    participantId: "",
    firstName: "",
    lastName: "",
    age: "",
    gender: "",
    medicalHistoryDescription: "",
  });
  const [error, setError] = useState({});
  let { id: clinicalTrialId } = useParams();
  // const addModerator = () => {
  //   try {
  //     setClinicalTrialDetails((prevState) => ({
  //       ...prevState,
  //       moderator,
  //     }));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleAddParticipantChange = (event) => {
    const { name, value, checked } = event.target;
    // If the field is "isActive", convert the string value to a boolean
    const newValue = name === "isActive" ? value === "true" : value;

    if (name === "researchCenter" || name === "home") {
      setParticipant((prevParticipant) => ({
        ...prevParticipant,
        [name]: true,
      }));
      setSelection(name);
    } else {
      setParticipant((prevParticipant) => ({
        ...prevParticipant,
        [name]: newValue,
      }));
    }
  };

  const [selection, setSelection] = useState("researchCenter");

  const addParticipant = (e) => {
    try {
      e.preventDefault();

      if (validateParticipant()) {
        return;
      }

      setClinicalTrialDetails((prevState) => ({
        ...prevState,
        participants: [...prevState.participants, participant],
      }));
      setParticipant({
        participantId: "",
        firstName: "",
        lastName: "",
        age: "",
        gender: "",
        medicalHistoryDescription: "",
      });
      setError({});
    } catch (error) {
      console.log(error);
    }
  };

  const validateParticipant = () => {
    let hasError = false;
    const newError = {};

    // Validate participantId presence
    // const isValid = /^\d+$/.test(participant.participantId);
    // if (!isValid) {
    //   hasError = true;
    //   newError.participantId = 'Participant Id should be a number';
    // }

    if (participant.participantId.trim() === "") {
      hasError = true;
      newError.participantId = "Participant Id is required";
    }

    // Validate age (allowing empty string)
    const age = participant.age.trim();
    if (age !== "" && (isNaN(parseInt(age)) || parseInt(age) <= 0)) {
      hasError = true;
      newError.age = "Age must be a positive number";
    }

    // Update the error state
    setError(newError);

    return hasError;
  };

  // Debounce the API call
  // const debouncedCheck = useCallback(debounce(checkParticipantId, 800), []);

  // const debouncedCheckParticipantId = useCallback(
  //   debounce((participantId) => {
  //     checkParticipantId(participantId);
  //   }, 800),
  //   [] // dependencies array, empty if there are no dependencies
  // );

  // const checkParticipantId = async (participantId) => {
  //   try {
  //     const { data: { data } } = await isParticipantIdExists(participantId);

  //     console.log("checking study number ", data)
  //     if (data) {
  //       setError((state) => ({ ...state, participantId: "Participant Id is already exists" }));
  //     }
  //   } catch (error) {
  //     setError((state) => ({ ...state, participantId: "Error checking Participant Id Number uniqueness" }));
  //   }
  // }
  const exportParticipants = () => {
    const headers = [
      "Email",
      "Participant ID",
      "First Name",
      "Last Name",
      "Age",
      "Gender",
      "Medical History Description",
    ];

    const participantsCSV = [headers.join(",")];
    clinicalTrialDetails.participants.forEach((participant) => {
      const participantData = [
        participant.email,
        participant.participantId,
        participant.firstName,
        participant.lastName,
        participant.age,
        participant.gender,
        participant.medicalHistoryDescription,
      ];
      participantsCSV.push(participantData.join(","));
    });
    const csvContent = participantsCSV.join("\n");
    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    // Create a temporary <a> element and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "participants.csv";
    document.body.appendChild(a);
    a.click();
    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <Row>
      <div className="form-content">
        <Col lg={24}>
          <div className="add-participants-section ms-0">
            <div className="row">
              <Col lg={24}>
                <div className="d-flex align-items-center justify-content-between mb-3">
                <h2 className="mb-0">Add Participants</h2>
                {clinicalTrialId && (
                  <button
                    type="button"
                    onClick={exportParticipants}
                    className="btn btn-primary"
                  >
                    Export{" "}
                  </button>
                )}
                </div>
              </Col>
              
            </div>
          </div>
          <div className="fwidth-search-input">
            <form>
              <div className="row g-3">
                <div className="d-flex col-lg-6 col-md-6 col-sm-12 col-12 ">
                  <div className="d-flex input_redio">
                    <label className="ant-form-item-required form_label w-100">
                      <span>*</span> Research center
                    </label>
                    <input
                      type="radio"
                      name="researchCenter"
                      checked={selection === "researchCenter"}
                      onChange={(e) => {
                        handleAddParticipantChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex col-lg-6 col-md-6 col-sm-12 col-12 ">
                  <div className="d-flex input_redio">
                    <label className="ant-form-item-required form_label">
                      {" "}
                      Home
                    </label>
                    <input
                      type="radio"
                      name="home"
                      checked={selection === "home"}
                      onChange={(e) => {
                        handleAddParticipantChange(e);
                      }}
                    />
                  </div>
                </div>
                {selection === "home" && (
                  <div className="col-12">
                    <label className="ant-form-item-required form_label w-100">
                      <span>*</span> Email
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        disabled={isParticipantEdit}
                        name="email"
                        value={participant.email}
                        className="form-control"
                        placeholder="Participant Id"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        style={{ height: "55px" }}
                        onChange={(e) => {
                          handleAddParticipantChange(e);
                        }}
                      />
                    </div>
                    {error && error.email && (
                      <span style={{ color: "red" }}>{error.email}</span>
                    )}
                  </div>
                )}

                <div className="col-12">
                  <label className="ant-form-item-required form_label w-100">
                    <span>*</span> Participant Id
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      disabled={isParticipantEdit}
                      name="participantId"
                      value={participant.participantId}
                      className="form-control"
                      placeholder="Participant Id"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ height: "55px" }}
                      onChange={(e) => {
                        handleAddParticipantChange(e);
                      }}
                    />
                  </div>
                  {error && error.participantId && (
                    <span style={{ color: "red" }}>{error.participantId}</span>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <label className="ant-form-item-required form_label w-100">
                    {" "}
                    First Name
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="firstName"
                      value={participant.firstName}
                      className="form-control"
                      placeholder="First Name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ height: "55px" }}
                      onChange={(e) => handleAddParticipantChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <label className="ant-form-item-required form_label w-100">
                    {" "}
                    Last Name
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="lastName"
                      value={participant.lastName}
                      className="form-control"
                      placeholder="Last Name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ height: "55px" }}
                      onChange={(e) => handleAddParticipantChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <label className="ant-form-item-required form_label w-100">
                    {" "}
                    Age
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="age"
                      min="0"
                      value={participant.age}
                      className="form-control"
                      placeholder="Age"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ height: "55px" }}
                      onChange={(e) => handleAddParticipantChange(e)}
                    />
                  </div>
                  {error && error.age && (
                    <span style={{ color: "red" }}>{error.age}</span>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <label className="ant-form-item-required form_label w-100">
                    {" "}
                    Gender
                  </label>
                  <div className="input-group">
                    <select
                      class="form-select"
                      name="gender"
                      style={{ height: "55px" }}
                      value={participant.gender}
                      aria-label="Default select example"
                      onChange={(e) => handleAddParticipantChange(e)}
                    >
                      <option selected>Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <label className="ant-form-item-required form_label w-100">
                    {" "}
                    Medical History Description
                  </label>
                  <div className="input-group">
                    <textarea
                      class="form-control"
                      name="medicalHistoryDescription"
                      value={participant.medicalHistoryDescription}
                      id="exampleFormControlTextarea1"
                      placeholder="Medical History Description"
                      rows="3"
                      onChange={(e) => handleAddParticipantChange(e)}
                    ></textarea>
                  </div>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-end">
                  <button
                    type="button"
                    onClick={(e) => addParticipant(e)}
                    className="btn btn-primary text-white ms-0"
                    style={{ width: "auto" }}
                  >
                    {isParticipantEdit ? "Update" : "Add"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Col>

        <div className="participants-scroll">
          <table className="table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Participant ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Medical History Description</th>
              </tr>
            </thead>
            <tbody>
              {clinicalTrialDetails.participants.map((row, index) => (
                <tr key={index}>
                  <td>{row.email}</td>
                  <td>{row.participantId}</td>
                  <td>{row.firstName}</td>
                  <td>{row.lastName}</td>
                  <td>{row.age}</td>
                  <td>{row.gender}</td>
                  <td>{row.medicalHistoryDescription}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* {clinicalTrialDetails.participants.map((email) => (
            <Col lg={24}>
              <div className="avatar-details">
                <Avatar
                  style={{
                    backgroundColor: "#0E84F2",
                    fontfamily: "WorkSans-Bold",
                    width: 30,
                    height: 30,
                    fontWeight: "600",
                    fontSize: "13px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                  size="large"
                >
                  {email.substring(0, 1)}
                </Avatar>
                <label style={{ marginLeft: 20 }}>{email}</label>
              </div>
            </Col>
          ))} */}
        </div>
      </div>
    </Row>
  );
}
