import { Row, Table, Col, Card } from "antd";
import React, { useEffect, useState } from "react";
import DynamicTable from "../pages/DynamicTable";
import Left from "../assets/images/Left.png";
import Print from "../assets/images/Print.png";
import Download from "../assets/images/Download.png";
import Doubleauro from "../assets/images/Doubleauro.png";
import * as audioawareApi from "../services/api/audioware";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";

const columns = [
  {
    title: "Session Id",
    dataIndex: "SessionId",
  },
  {
    title: "AudioAware Id",
    dataIndex: "AudioAwareId",
  },
  {
    title: "Date",
    dataIndex: "Date_Created",
  },
  {
    title: "There Loss Of Memory",
    dataIndex: "IsThereLossOfMemory",
  },
  {
    title: "Played Words",
    dataIndex: "PlayedWords",
  },
  {
    title: "Selected Words",
    dataIndex: "SelectedWords",
  },

  {
    title: "UserId",
    dataIndex: "UserId",
  },
];

const Audioaware = () => {
  const [audiowareTable, setAudiowareTable] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAudioawareData();
  }, []);

  const handleExport = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(audiowareTable);

    XLSX.utils.book_append_sheet(wb, ws, "AudioWare");
    XLSX.writeFile(wb, "AudioWare.xlsx");
  };

  const getAudioawareData = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await audioawareApi?.getAudioaware();
      setAudiowareTable(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className="Container">
        <Row className="Maincontainer">
          <Col span={8}>
            <div className="Leftside-col">
              <Link to={"/dashboard"}>
                <img src={Left}></img>
              </Link>
              <h2>Data collected on AudioAware tests</h2>
            </div>
            <br></br>
          </Col>
        </Row>
        <div className="Audioaware">
          <Col span={8}>
            <div className="Rightside-col Audioaware">
              <button>
                {" "}
                <img src={Doubleauro}></img>
                Sort by
              </button>
            </div>
          </Col>

          <Col span={8}>
            <div className="center-col">
              <img src={Print}></img>
              <h4>Print</h4>

              <Col span={8}>
                <div className="Download-col" onClick={handleExport}>
                  <img src={Download}></img>
                  <h4>Download</h4>
                </div>
              </Col>
            </div>
          </Col>
        </div>

        <DynamicTable
          columns={columns}
          dataSource={audiowareTable}
          loading={loading}
        />
      </div>
    </>
  );
};
export default Audioaware;
