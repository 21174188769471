import { Steps, Form, Col, message } from "antd";
import React, { useState, useContext, useEffect } from "react";
import DefaultButton from "../../components/Button/Button";
import ClinicalEmail from "./ClinicalEmail";
import ProjectDetail from "./ProjectDetail";
import * as adminApi from "../../services/api/admin";
// import * as clinicalTrailApi from "../../services/api/clinicalTrail";
import { useNavigate } from "react-router-dom";

export default function ClinicalForm() {
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({});
  const { Step } = Steps;

  const steps = [
    {
      content: <ProjectDetail />,
    },
    {
      content: <ClinicalEmail />,
    },
  ];

  const onSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      if (current === 0) {
        const info = {
          ...values,
        };
        setFormData({
          ...formData,
          projectInfo: { ...info },
        });
        next();
      } else {
        const { projectInfo } = formData;
        const clinicalTrial = { ...projectInfo, ...values };

        const response = await adminApi.createClinicalTrial(clinicalTrial);

        if (response?.response?.status == 200) {
          navigate(`/form-submitted`);
          setLoading(false);
        }
      }
      setLoading(false);
    } catch (error) {
      let { errorFields } = error;
      if (errorFields.length) {
        message.error(error.errorFields[0].errors[0]);
      } else {
        message.error(error?.response?.data?.message);
      }

      setLoading(false);
    }
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <div className="kyc-wrapper">
        <Form
          form={form}
          initialValues={{
            isIndividual: false,
          }}
        >
          <div className="steps-wrapper">
            <div className="steps-content">
              {steps[current].content}
              <Col span={8}>
                <div className="steps-action">
                  <>
                    <Form.Item>
                      <DefaultButton
                        loading={loading}
                        className="colored_btn"
                        title={
                          current === steps.length - 1 ? "Finish" : "Continue"
                        }
                        onClick={() => onSubmit()}
                      />
                    </Form.Item>
                  </>
                  {current > 0 && (
                    <Form.Item>
                      <DefaultButton
                        className="transparent_btn"
                        title="Previous"
                        onClick={() => prev()}
                      />
                    </Form.Item>
                  )}
                </div>
              </Col>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}
