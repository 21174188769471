import React from "react";
import { Button, Col, Form, Input } from "antd";

const ProjectDetail = () => {
  return (
    <>
      <Col span={8}>
        <Form.Item
          label="Project name"
          name="name"
          rules={[{ required: true, message: "Please input project name" }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Description" name="description">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="City" name="city">
          <Input />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="State" name="state">
          <Input />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Country" name="country">
          <Input />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Zip Code" name="zipCode">
          <Input />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Phone No" name="phoneNumber">
          <Input />
        </Form.Item>
      </Col>
    </>
  );
};

export default ProjectDetail;
