import { axiosApi } from "../axios/axios_config";

export async function getClinicalTrialPatients(userId) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `patient/clinical-trial?userId=${userId}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function getClinicalTrialPatientsByDoctorId(userId) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `patient/clinical-trial-id?userId=${userId}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}


export async function addPatientToClinicalTrial(tableDataForParticipant) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/add-participant`,
      method: "POST",
      bodyData: tableDataForParticipant,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}
export async function updatePatientToClinicalTrial(userId, participant) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/update-participant?userId=${userId}`,
      method: "PUT",
      bodyData: participant,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function checkTestPerformedByPatient(userId) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/test-performed-checking?userId=${userId}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function deleteParticipant(userId) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/delete-participant?userId=${userId}`,
      method: "DELETE",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}