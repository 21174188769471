import React, { useEffect, useState } from "react";
import {
  LeftOutlined,
  PrinterOutlined,
  DownloadOutlined,
  ExperimentOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Col, Row, Table, Divider } from "antd";
import * as grammarAwareApi from "../../services/api/grammeraware";
import { Link, useParams } from "react-router-dom";
import tableExport from "antd-table-export";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import moment from "moment";
import CustomTable from "../../components/Table/CustomTable";
import useDebounce from '../../components/Table/debounce';

const GrammarAwareTestLogs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0
  });
  const [currentPage, setCurrentPage] = useState(0);

  const [filter, setFilter] = useState({
    filterBy: '',
    filterValue: ''
  });

  const getGrammarAwareData = async () => {
    try {
      setLoading(true);
      var {
        data: { data, totalCount },
      } = await grammarAwareApi?.getGrammaraware(id, pagination, filter);

      setPagination((state) => ({ ...state, totalCount: totalCount }));
      if (id) {
        data = data.filter((c) => c.UserId == id);
      }

      const tubeIdData = [];

      data.forEach((el) => {
        let newObj = {
          Id: el.Id,
          UserId: el.UserId,
          SessionId: el.SessionId,
          // "Sentences": el.Sentences.split('|')[0],
          Sentences: el.Sentences,
          // "GrammaticalAccuracy":el.Sentences.split('|')[1],
          GrammaticalAccuracy: el.GrammaticalAccuracy,
          ReferenceWords: el.ReferenceWords,
          SpeakStartTime: moment(el.SpeakStartTime).format("MM/DD/Y HH:mm:ss"),
          SpeakEndTime: moment(el.SpeakEndTime).format("MM/DD/Y HH:mm:ss"),
          Date_Created: moment(el.Date_Created).format("MM/DD/Y HH:mm:ss"),
          GrammerAwareId: el.GrammerAwareId,
        };
        tubeIdData.push(newObj);
      });

      // setData(data);
      setData(tubeIdData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // const columns = [
  //   {
  //     title: "GrammerAwareId",
  //     field: "GrammerAwareId",
  //   },
  //   {
  //     title: "UserId",
  //     field: "UserId",
  //   },
  //   {
  //     title: "SessionId",
  //     field: "SessionId",
  //   },
  //   {
  //     title: "ReferenceWords",
  //     field: "ReferenceWords",
  //   },
  //   {
  //     title: "SpokenSentence",
  //     field: "Sentences",
  //   },
  //   {
  //     title: "GrammaticalAccuracy",
  //     field: "GrammaticalAccuracy",
  //   },
  //   {
  //     title: "SpeakStartTime",
  //     field: "SpeakStartTime",
  //   },

  //   {
  //     title: "SpeakEndTime",
  //     field: "SpeakEndTime",
  //   },
  //   {
  //     title: "Date",
  //     field: "Date_Created",
  //   },
  // ];

  const columns = [
    {
      Header: "GrammerAware Id",
      accessor: "GrammerAwareId",
    },
    {
      Header: "User Id",
      accessor: "UserId",
    },
    {
      Header: "Session Id",
      accessor: "SessionId",
    },
    {
      Header: "Reference Words",
      accessor: "ReferenceWords",
    },
    {
      Header: "Spoken Sentence",
      accessor: "Sentences",
    },
    {
      Header: "Grammatical Accuracy",
      accessor: "GrammaticalAccuracy",
    },
    {
      Header: "Speak Start Time",
      accessor: "SpeakStartTime",
    },
    {
      Header: "Speak End Time",
      accessor: "SpeakEndTime",
    },
    {
      Header: "Date",
      accessor: "Date_Created",
    },
  ];

  const exportInstance = new tableExport(data, columns);
  function downloadExcel() {
    exportInstance.download("GrammarAwareTestLogs");
  }

  const debouncedFilterValue = useDebounce(filter.filterValue, 300);

  const handleSearchFilter = (e) => {
    setFilter((state) => ({ ...state, filterValue: e.target.value }))
  };

  useEffect(() => {
    if (debouncedFilterValue !== null) {
      getGrammarAwareData();
    }
  }, [currentPage, debouncedFilterValue]);

  // useEffect(() => {
  //   getGrammarAwareData();
  // }, [currentPage]);

  var returnPath = "/dashboard";
  if (id) {
    returnPath = "/dashboard/user-details/" + id;
  }
  return (

    <>


      <CustomTable
        columns={columns}
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
        setCurrentPage={setCurrentPage}
        setFilter={setFilter}
        handleSearchFilter={handleSearchFilter}
        filter={filter}
        type={'grammarAware'}
      />

    </>

    // <div className="container">
    //   <div className="test-log">
    //     <span className="left-outlined">
    //       <Link to={returnPath}>
    //         <LeftOutlined />
    //       </Link>
    //     </span>
    //     <h2 style={{ fontSize: "32px", color: "white" }}>Test logs</h2>
    //   </div>

    //   <Divider />
    //   <Row className="table-background" gutter={[22, 22]}>
    //     <Col lg={24}>
    //       <MaterialTable
    //         title="GrammerAware"
    //         columns={columns}
    //         data={data}
    //         options={{
    //           exportAllData: true,
    //           exportMenu: [
    //             {
    //               label: "Export PDF",
    //               exportFunc: (columns, data) =>
    //                 ExportPdf(columns, data, "GrammerAware"),
    //             },
    //             {
    //               label: "Export CSV",
    //               exportFunc: (columns, data) =>
    //                 ExportCsv(columns, data, "GrammerAware"),
    //             },
    //           ],
    //           filtering: true,
    //           sorting: true,
    //           headerStyle: {
    //             backgroundColor: "#01579b",
    //             color: "#FFF",
    //           },
    //           cellStyle: {
    //             backgroundColor: "#01579b",
    //             color: "#FFF",
    //           },
    //           pageSize: 10,
    //           pageSizeOptions: [10, 50, 100],
    //         }}
    //       />
    //     </Col>
    //   </Row>
    // </div>
  );
};

export default GrammarAwareTestLogs;
