import { axiosApi } from "../axios/axios_config";

export async function getClinicalTrial() {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/clinical-trial`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function getClinicalTrialDetail(studyNumber) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/clinical-trial-detail?studyNumber=${studyNumber}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function getAllDataForClinicalTrial(id) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/clinical-trial-all-details?id=${id}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function updateClinicalTrialDetail(clinicalTrail) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/update-clinical-trial`,
      method: "PUT",
      bodyData: clinicalTrail,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function getClinicalTrialParticipants(id) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/clinical-trial-participants?id=${id}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function getStats() {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/stats`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function createClinicalTrial(clinicalTrial) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/clinical-trial`,
      method: "POST",
      bodyData: clinicalTrial,
    });
    return { data, response };
  } catch (err) {
    throw err;
  }
}

export async function getClinicalTrailWithCount() {
  try {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const { data, response } = await axiosApi({
      endpoint: `admin/clinical-trial-with-count?userId=${userInfo.id}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function getClinicalTrailWithCountForDoctor() {
  try {
    const { data, response } = await axiosApi({
      endpoint: `doctor/clinical-trial-with-count`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function getClinicalTrialUserDetails(id) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/clinical-trial-user-details?id=${id}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function getParticipantClinicalTrials(id) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/participant-clinical-trial?id=${id}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function changePasswordAPI(userId,credentials) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/change-password/`+userId,
      method: "POST",
      bodyData: credentials,
    });
    alert("ye");
    console.log(data); return false;
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function isStudyStudyNumber(studyNumber) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/check-study-number?studyNumber=${studyNumber}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}


export async function isParticipantIdExists(participantId) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `admin/check-participant-id?participantId=${participantId}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}