import { axiosApi } from "../axios/axios_config";
export async function getGrammaraware(id, pagination, filter) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let endpoint = (id) ? `grammaraware?userId=${id}` : `grammaraware?userId=${userInfo.id}`;

  if (pagination) {
    endpoint = (id) ? `grammaraware?userId=${id}&pageIndex=${pagination.pageIndex}&pageSize=${pagination.pageSize}` : `grammaraware?userId=${userInfo.id}&pageIndex=${pagination.pageIndex}&pageSize=${pagination.pageSize}`;
  }

  if (filter && filter.filterBy && filter.filterValue) {
    endpoint += `&filterBy=${filter.filterBy}&filterValue=${filter.filterValue}`;
  }
  try {
    const { data, response } = await axiosApi({
      endpoint: endpoint,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}
