import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import '../../styles/sidebar.css'

const Sidebar = ({ sidebarMenu }) => {

    const navigate = useNavigate();
    const location = useLocation();
    let tabs = [
        {
            label: 'Dashboard',
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="28"
                    className="me-3 position-reltive dasboard"
                    viewBox="0 0 20 28"
                    fill="none"
                >
                    <path
                        d="M0 1.91877C0 0.859063 0.859063 0 1.91877 0H6.64215C7.70186 0 8.56092 0.859063 8.56092 1.91877V6.64215C8.56092 7.70186 7.70186 8.56092 6.64215 8.56092H1.91877C0.859063 8.56092 0 7.70186 0 6.64215V1.91877Z"
                        fill="#00D7FF" />
                    <path
                        d="M11.4391 13.3579C11.4391 12.2981 12.2982 11.4391 13.3579 11.4391H18.0812C19.1409 11.4391 20 12.2982 20 13.3579V18.0812C20 19.1409 19.1409 20 18.0812 20H13.3579C12.2981 20 11.4391 19.1409 11.4391 18.0812V13.3579Z"
                        fill="#00D7FF" />
                    <path
                        d="M11.4391 1.91877C11.4391 0.859063 12.2982 0 13.3579 0H18.0812C19.1409 0 20 0.859063 20 1.91877V6.64215C20 7.70186 19.1409 8.56092 18.0812 8.56092H13.3579C12.2981 8.56092 11.4391 7.70186 11.4391 6.64215V1.91877Z"
                        fill="#00D7FF" />
                    <path
                        d="M0 13.3579C0 12.2981 0.859063 11.4391 1.91877 11.4391H6.64215C7.70186 11.4391 8.56092 12.2982 8.56092 13.3579V18.0812C8.56092 19.1409 7.70186 20 6.64215 20H1.91877C0.859063 20 0 19.1409 0 18.0812V13.3579Z"
                        fill="#00D7FF" />
                    {/* <rect x="2" y="26" width="16" height="2" rx="1" fill="#00D7FF" /> */}
                </svg>
            ),
            route: '/dashboard',
        },
        {
            label: 'My Study',
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="20"
                    className="me-3"
                    viewBox="0 0 18 20"
                    fill="none"
                >
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.9994 -3.15986e-08C13.4136 -1.41472e-08 13.7494 0.32365 13.7494 0.722892L13.7494 3.61446C13.7494 4.0137 13.4136 4.33735 12.9994 4.33735C12.5852 4.33735 12.2494 4.0137 12.2494 3.61446L12.2494 0.722892C12.2494 0.32365 12.5852 -4.905e-08 12.9994 -3.15986e-08Z"
                        fill="#00D7FF" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M4.99915 -3.15986e-08C5.41336 -1.41472e-08 5.74915 0.32365 5.74915 0.722892L5.74915 3.61446C5.74915 4.0137 5.41336 4.33735 4.99915 4.33735C4.58493 4.33735 4.24915 4.0137 4.24915 3.61446L4.24915 0.722892C4.24915 0.32365 4.58493 -4.905e-08 4.99915 -3.15986e-08Z"
                        fill="#00D7FF" />
                    <path opacity="0.4"
                        d="M14 2.16867H4C1.79086 2.16867 0 3.8948 0 6.02409V16.1446C0 18.2739 1.79086 20 4 20H14C16.2091 20 18 18.2739 18 16.1446V6.02409C18 3.8948 16.2091 2.16867 14 2.16867Z"
                        fill="#00D7FF" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M4.25 7.46996C4.25 7.07072 4.58579 6.74707 5 6.74707H9C9.41421 6.74707 9.75 7.07072 9.75 7.46996C9.75 7.8692 9.41421 8.19285 9 8.19285H5C4.58579 8.19285 4.25 7.8692 4.25 7.46996Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M4.25 11.3252C4.25 10.9259 4.58579 10.6023 5 10.6023H13C13.4142 10.6023 13.75 10.9259 13.75 11.3252C13.75 11.7244 13.4142 12.0481 13 12.0481H5C4.58579 12.0481 4.25 11.7244 4.25 11.3252Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M4.25 15.1807C4.25 14.7814 4.58579 14.4578 5 14.4578H13C13.4142 14.4578 13.75 14.7814 13.75 15.1807C13.75 15.5799 13.4142 15.9035 13 15.9035H5C4.58579 15.9035 4.25 15.5799 4.25 15.1807Z"
                        fill="white" />
                </svg>
            ),
            route: '/dashboard/clinical-trial',
            // route: '/dashboard/clinicaltrial',
        },


        {
            label: 'Logs',
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="20"
                    className="me-3"
                    viewBox="0 0 18 20"
                    fill="none"
                >
                    <path opacity="0.4"
                        d="M6.92154 0.571247C8.2077 -0.190416 9.7923 -0.190416 11.0785 0.571247L15.9215 3.43932C17.2077 4.20099 18 5.6086 18 7.13192V12.8681C18 14.3914 17.2077 15.799 15.9215 16.5607L11.0785 19.4288C9.7923 20.1904 8.20769 20.1904 6.92154 19.4288L2.07846 16.5607C0.792305 15.799 0 14.3914 0 12.8681V7.13192C0 5.6086 0.792305 4.20099 2.07846 3.43932L6.92154 0.571247Z"
                        fill="#00D7FF" />
                    <path
                        d="M6.9218 19.4286C8.20795 20.1903 9.79256 20.1903 11.0787 19.4286L15.3013 16.928C14.0434 14.698 11.6926 13.1978 9.00025 13.1978C6.30788 13.1978 3.95712 14.698 2.69922 16.928L6.9218 19.4286Z"
                        fill="#00D7FF" />
                    <circle cx="3" cy="3" r="3" transform="matrix(1 0 0 -1 6 11)" fill="#00D7FF" />

                </svg>
            ),
            route: '/dashboard/logs',
        }


        // {
        //     label: 'Active clinical trial',
        //     icon: (
        //         <svg
        //             xmlns="http://www.w3.org/2000/svg"
        //             width="18"
        //             height="20"
        //             className="me-3"
        //             viewBox="0 0 18 20"
        //             fill="none"
        //         >
        //             <path opacity="0.4"
        //                 d="M6.92154 0.571247C8.2077 -0.190416 9.7923 -0.190416 11.0785 0.571247L15.9215 3.43932C17.2077 4.20099 18 5.6086 18 7.13192V12.8681C18 14.3914 17.2077 15.799 15.9215 16.5607L11.0785 19.4288C9.7923 20.1904 8.20769 20.1904 6.92154 19.4288L2.07846 16.5607C0.792305 15.799 0 14.3914 0 12.8681V7.13192C0 5.6086 0.792305 4.20099 2.07846 3.43932L6.92154 0.571247Z"
        //                 fill="#00D7FF" />
        //             <path
        //                 d="M6.9218 19.4286C8.20795 20.1903 9.79256 20.1903 11.0787 19.4286L15.3013 16.928C14.0434 14.698 11.6926 13.1978 9.00025 13.1978C6.30788 13.1978 3.95712 14.698 2.69922 16.928L6.9218 19.4286Z"
        //                 fill="#00D7FF" />
        //             <circle cx="3" cy="3" r="3" transform="matrix(1 0 0 -1 6 11)" fill="#00D7FF" />

        //         </svg>
        //     ),
        //     route: '/dashboard/users',
        // },




        // {
        //     label: 'Settings',
        //     icon: (
        //         <svg
        //             xmlns="http://www.w3.org/2000/svg"
        //             width="18"
        //             height="18"
        //             className="me-3"
        //             viewBox="0 0 18 18"
        //             fill="none"
        //         >
        //             <path opacity="0.4"
        //                 d="M9.95455 0H8.04545C6.99109 0 6.13635 0.805888 6.13635 1.8C6.13635 2.93761 4.91917 3.66087 3.92 3.11697L3.81852 3.06172C2.90541 2.56467 1.73782 2.85964 1.21064 3.72057L0.256091 5.27942C-0.271092 6.14034 0.0417618 7.24121 0.954869 7.73826C1.95451 8.28242 1.95451 9.71758 0.954868 10.2617C0.0417603 10.7588 -0.271093 11.8597 0.256089 12.7206L1.21064 14.2794C1.73782 15.1404 2.90541 15.4353 3.81851 14.9383L3.92 14.883C4.91917 14.3391 6.13635 15.0624 6.13635 16.2C6.13635 17.1941 6.99109 18 8.04545 18H9.95455C11.0089 18 11.8636 17.1941 11.8636 16.2C11.8636 15.0624 13.0808 14.3391 14.08 14.883L14.1815 14.9383C15.0946 15.4353 16.2622 15.1403 16.7894 14.2794L17.7439 12.7206C18.2711 11.8596 17.9582 10.7588 17.0451 10.2617C16.0455 9.71757 16.0455 8.28243 17.0451 7.73828C17.9582 7.24122 18.2711 6.14036 17.7439 5.27943L16.7894 3.72058C16.2622 2.85966 15.0946 2.56468 14.1815 3.06174L14.08 3.11698C13.0808 3.66088 11.8636 2.93762 11.8636 1.8C11.8636 0.805887 11.0089 0 9.95455 0Z"
        //                 fill="#00D7FF" />
        //             <circle cx="9" cy="9" r="3" fill="#00D7FF" />
        //         </svg>
        //     ),
        //     route: '/settings',
        // },
    ];
    const [sidebar, setSidebar] = useState(tabs)

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("userInfo"));
        tabs = tabs.filter(tab => {
            // Check if the user's role is 'admin' and the label is not 'Clinical trials'
            return !((user?.role === 'Customer' || user?.role === 'Admin' || user?.role === 'PI') && tab.label === 'Logs');
        });
        setSidebar(tabs)
    }, [])

    const handleLogout = () => {
        localStorage.removeItem("userInfo");
        navigate("/login");
    }

    console.log("sidebar", sidebar);
    return (
        <div className={`sidebar ${sidebarMenu ? 'show' : ''}`}>
            <ul className="nav flex-column">
                {sidebar.map((tab, index) => (
                    <li className={`nav-item ${location.pathname === tab.route ? ' active' : ''}`}>
                        <Link to={tab.route} key={index}>
                            <a className="nav-link d-flex align-items-center position-reltive">
                                {tab.icon}
                                {tab.label}
                            </a>
                        </Link>
                    </li>
                ))}
                <li className="nav-item">
                    <a className="nav-link d-flex align-items-center" onClick={() => handleLogout()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" className="me-3" height="20"
                            viewBox="0 0 19 20" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12.8661 8.28033C13.159 7.98744 13.159 7.51256 12.8661 7.21967C12.5732 6.92678 12.0984 6.92678 11.8055 7.21967L10.5126 8.51256C9.82915 9.19598 9.82915 10.304 10.5126 10.9874L11.8055 12.2803C12.0983 12.5732 12.5732 12.5732 12.8661 12.2803C13.159 11.9874 13.159 11.5126 12.8661 11.2197L12.1464 10.5L17.3358 10.5C17.75 10.5 18.0858 10.1642 18.0858 9.75C18.0858 9.33579 17.75 9 17.3358 9L12.1464 9L12.8661 8.28033Z"
                                fill="#00D7FF" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M8 2.51611L11 2.51612C12.5188 2.51612 13.75 3.74734 13.75 5.26612C13.75 5.68033 13.4142 6.01612 13 6.01612C12.5858 6.01612 12.25 5.68033 12.25 5.26612C12.25 4.57576 11.6904 4.01612 11 4.01612L8 4.01611V2.51611ZM13 14.5161C13.4142 14.5161 13.75 14.8519 13.75 15.2661C13.75 16.7849 12.5188 18.0161 11 18.0161H8V16.5161H11C11.6904 16.5161 12.25 15.9565 12.25 15.2661C12.25 14.8519 12.5858 14.5161 13 14.5161Z"
                                fill="#00D7FF" />
                            <path opacity="0.4"
                                d="M0 3.99676C0 3.27511 0.381946 2.60121 1.01783 2.20091L4.44642 0.366124C5.9654 -0.590105 8.00002 0.438116 8.00002 2.16198V17.838C8.00002 19.5619 5.9654 20.5901 4.44642 19.6339L1.01785 17.7959C0.381963 17.3956 1.71661e-05 16.7217 1.71661e-05 16L0 3.99676Z"
                                fill="#00D7FF" />
                        </svg>
                        Log out
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Sidebar