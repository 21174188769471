import React, { useEffect } from "react";
import { Form, Steps, Button, message, Card } from "antd";
import BasicDetails from "./BasicDetails";
import AddDuration from "./AddDuration";
import AddParticipants from "./AddParticipants";
import AddModerators from "./AddModerators";
import Sites from "./sites";
import Review from "./Review";
import moment from "moment";
import { useState } from "react";
import * as adminApi from "../../../services/api/admin";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { Modal } from "antd";

const { Step } = Steps;

function CreateClinicalTrial() {
  let navigate = useNavigate();
  const testType = Object.freeze({
    ScentAware: "ScentAware",
    TraceAware: "TraceAware",
    WordsAware: "WordsAware",
    GlanceAware: "GlanceAware",
    GrammarAware: "GrammarAware",
    AudioAware: "AudioAware",
  });
  let { id: clinicalTrialId } = useParams();
  const [form] = Form.useForm();
  const { getFieldDecorator, setFieldsValue } = form;
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  let [clinicalTrialDetails, setClinicalTrialDetails] = useState({
    Id: '',
    studyNumber: '',
    name: '',
    testType: 'ScentAware',
    description: '',
    instituteName: '',
    start_date: '',
    end_date: '',
    participants: [],
    moderators: [],
    // sites: [],
  });
  const user = JSON.parse(localStorage.getItem('userInfo'));


  useEffect(() => {
    setFieldsValue({
      testType: 'ScentAware', // Set your default test type here
    });
    if (clinicalTrialId) {
      getAllDataForClinicalTrial();
    }
    else {
      if (user.role == "Admin" || user.role == "PI") {
        setClinicalTrialDetails((state) => ({
          ...state,
          moderators: [{
            Id: user.id,
            email: user.email,
            firstName: user.name ? user.name : '',
            lastName: user.lastName ? user.lastName : '',
            role: user.roleId == 23 ? 'Researcher' : 'PI'
          }]
        }));
      }
    }
  }, []);

  const getAllDataForClinicalTrial = async () => {
    const { data: { data }, response } = await adminApi.getAllDataForClinicalTrial(clinicalTrialId);

    if (response.status === 200) {

      const { clinicalTrial, moderators, participants, sites } = data;

      const moderatorData = moderators.map((x) => ({
        Id: x.Id,
        email: x.Email,
        firstName: x.FirstName,
        lastName: x.LastName,
        role: x.RoleId === 23 ? 'Researcher' : 'PI'
      }));

      const startDate = moment(clinicalTrial.Start_Date).format('YYYY-MM-DD');
      const endDate = moment(clinicalTrial.End_Date).format('YYYY-MM-DD');

      const testTypeField = clinicalTrial.TestType;

      let testType;

      // Check if testTypeField is a string
      if (typeof testTypeField === 'string') {
        try {
          // Try to parse it as JSON
          testType = JSON.parse(testTypeField);

          // If parsing fails (for example, if it's a single string without square brackets),
          // treat it as a single-element array
          if (!Array.isArray(testType)) {
            testType = [testTypeField];
          }
        } catch (error) {
          // If parsing as JSON fails, treat it as a single-element array
          testType = [testTypeField];
        }
      } else if (Array.isArray(testTypeField)) {
        // If it's already an array, use it as is
        testType = testTypeField;
      } else {
        // Handle other cases if needed
        testType = [];
      }


      const basicDetail = {
        Id: clinicalTrial.Id,
        ModeratorId: clinicalTrial.ModeratorId,
        studyNumber: clinicalTrial.StudyNumber,
        name: clinicalTrial.Name,
        testType: testType,
        description: clinicalTrial.Description,
        instituteName: clinicalTrial.InstituteName,
        start_date: moment(startDate),
        end_date: moment(endDate),
        researchCenter: clinicalTrial.ResearchCenter,
        home: clinicalTrial.Home
      }

      const participantData = participants.map(x => ({
        Id: x.UserId,
        email: (!x.Email || x.Email != "null") ? x.Email : "",
        participantId: x.ParticipantId,
        firstName: x.FirstName,
        lastName: x.LastName,
        age: x.Age,
        gender: x.Gender,
        medicalHistoryDescription: x.MedicalHistoryDescription,
      }))

      const uniqueParticipantsMap = new Map();
      participantData.forEach(participant => {
        uniqueParticipantsMap.set(participant.participantId, participant);
      });

      // Convert the Map values to an array to get the unique participants
      const uniqueParticipants = Array.from(uniqueParticipantsMap.values());

      console.log(uniqueParticipants);

      // const sitesData = sites.map(x => ({
      //   Id: x.Id,
      //   siteName: x.SiteName
      // }))

      //new commented for sites
      // const uniqueSitesData = [];
      // const uniqueSiteNames = {};

      //new commented for sites
      // sites.forEach(site => {
      //   const { Id, SiteName } = site;
      //   if (!uniqueSiteNames[SiteName]) {
      //     uniqueSiteNames[SiteName] = true;
      //     uniqueSitesData.push({ Id, siteName : SiteName });
      //   }
      // });
      setClinicalTrialDetails((state) => ({
        ...basicDetail,
        participants: uniqueParticipants,
        moderators: moderatorData,
        // sites: uniqueSitesData,
      }))

      form.setFieldsValue(basicDetail);
    }

  }

  const steps = [
    {
      title: "Basic details",
      content: <BasicDetails form={form} nextStep={nextStep} testType={testType} />,
    },
    {
      title: "Duration",
      content: <AddDuration form={form} nextStep={nextStep} />,
    },
    // {
    //   title: "Moderators",
    //   content: <AddModerators
    //     form={form}
    //     nextStep={nextStep}
    //     clinicalTrialDetails={clinicalTrialDetails}
    //     setClinicalTrialDetails={setClinicalTrialDetails}
    //   />,
    // },
    // {
    //   title: "Sites",
    //   content: <Sites
    //     form={form}
    //     nextStep={nextStep}
    //     clinicalTrialDetails={clinicalTrialDetails}
    //     setClinicalTrialDetails={setClinicalTrialDetails}
    //   />,
    // },
    {
      title: "Participants",
      content: (
        <AddParticipants
          form={form}
          nextStep={nextStep}
          clinicalTrialDetails={clinicalTrialDetails}
          setClinicalTrialDetails={setClinicalTrialDetails}
        />
      ),
    },
    {
      title: "Review",
      content: <Review clinicalTrialDetails={clinicalTrialDetails} />,
    },
  ];

  if (user.role === "SuperAdmin") {
    const moderatorsStep = {
      title: "Moderators",
      content: <AddModerators
        form={form}
        nextStep={nextStep}
        clinicalTrialDetails={clinicalTrialDetails}
        setClinicalTrialDetails={setClinicalTrialDetails}
      />,
    };
    steps.splice(2, 0, moderatorsStep);
  }

  function nextStep(formValues = {}) {
    if (formValues.start_date && formValues.end_date) {
      let start_date = moment(formValues.start_date).format("YYYY-MM-DD");
      let end_date = moment(formValues.end_date).format("YYYY-MM-DD");

      setClinicalTrialDetails((prevState) => ({
        ...prevState,
        start_date,
        end_date,
      }));
      setCurrentStep((currentStep) => currentStep + 1);
      return;
    }

    if (currentStep === 2 && clinicalTrialDetails.moderators.length === 0 && user.role === "SuperAdmin") {
      toast.error('Please add a moderator first');
      return;
    }

    // if (currentStep === 3 && clinicalTrialDetails.participants.length === 0) {
    //   toast.error('Please add a participant first');
    //   return;
    // }

    // if (currentStep === 3 && clinicalTrialDetails.sites.length === 0) {
    //   toast.error('Please add a site first');
    //   return;
    // }

    setClinicalTrialDetails((prevState) => ({
      ...prevState,
      ...formValues,
    }));
    setCurrentStep((currentStep) => currentStep + 1);
  }

  function previousStep() {
    setCurrentStep((currentStep) => currentStep - 1);
  }

  async function createClinicalTrial() {
    try {
      setLoading(true);
      let {
        response: { status },
      } = await adminApi.createClinicalTrial(clinicalTrialDetails);

      if (status === 200) {
        message.success("Clinical Trial created successfully");
        setLoading(false);
        navigate("/dashboard/clinical-trial");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  }

  async function updateClinicalTrial(e) {
    e.preventDefault();
    try {
      setLoading(true);
      let {
        response: { status },
      } = await adminApi.updateClinicalTrialDetail(clinicalTrialDetails);

      if (status === 200) {
        message.success("Clinical Trial created successfully");
        setLoading(false);
        navigate("/dashboard/clinical-trial");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  }

  const openUpdateModel = () => {
    setOpenModal(true);
  }

  const handleCancel = () => {
    setOpenModal(false);
  }

  const cancelUpdate = () => {
    navigate("/dashboard/clinical-trial");
  }



  console.log("clinicalTrialDetails", clinicalTrialDetails);
  return (
    <>

      <div className="page_wrapper">
        <Card className="Card">
          <h1 className="create-clinic">{clinicalTrialId ? "Update " : "Create "} New Study {user.role === "SuperAdmin" && 'Accounts'}</h1>
          <Steps current={currentStep}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>

          <div className="steps-content">{steps[currentStep].content}</div>
          <div className="steps-action">
            {currentStep > 0 && (
              <Button
                className="nextStep-page"
                style={{ margin: "0 8px" }}
                onClick={() => previousStep()}
              >
                Previous
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button
                className="nextStep-page"
                type="primary"
                onClick={() => {
                  (currentStep === 2 || currentStep === 3 || currentStep === 4)
                    ? nextStep()
                    : form.submit()
                }}
              >
                Next
              </Button>
            )}
            {( currentStep === steps.length - 1 && clinicalTrialId )&& (
              <Button
                // loading={loading}
                className="nextStep-page"
                type="primary"
                onClick={cancelUpdate}
              >
                Cancel
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button
                loading={loading}
                className="nextStep-page mx-2"
                type="primary"
                onClick={!clinicalTrialId ? createClinicalTrial : updateClinicalTrial}
              >
                {!clinicalTrialId ? 'Done' : 'Update'}
              </Button>
            )}
          </div>
        </Card>
      </div>

      {/* <Modal
        title={"Update Study"}
        open={openModal}
        // onOk={false}
        onCancel={handleCancel}
        footer={null}
        className="add_participend"
      >

        <div className="search_form w-100">
          <h5 className="mb-3">Please submit to update new study accounts</h5>
          <div className="w-100 d-flex align-items-center justify-content-end gap-4">
            <button type="button" className="btn btn-outline-primary" onClick={(e) => handleCancel(e)}>Cancel</button>
            <button type="button" className="btn btn-primary" style={{minWidth : '90px'}} loading={loading}  onClick={(e) => updateClinicalTrial(e)}>{loading ? (<span class="loader"></span>) : 'Submit'}</button>
          </div>
        </div>
      </Modal> */}
    </>
  );
}

export default CreateClinicalTrial;
