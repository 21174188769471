/** @format */

import React from "react";
import ReactApexChart from "react-apexcharts";

const AreaChart = ({ chartOptions, chartData, width, height }) => {
  return (
    <ReactApexChart
      options={chartOptions}
      series={chartData}
      type="area"
      width={width}
      height={height}
    />
  );
};

export default AreaChart;
