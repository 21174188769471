import React, { useState } from "react";
import { Layout, Menu } from "antd";
import Header from "../../components/Dashboard/Header";
import { Routes, Route, Link } from "react-router-dom";
import Login from "../../pages/Login";
import ForgetPassword from "../../pages/ForgetPassword";
import ChangePassword from "../../pages/ChangePassword";
import ConfirmPassword from "../../pages/ConfirmPassword";
import AuthHeader from "../../components/Dashboard/AuthHeader"

const { Sider, Content, Footer } = Layout;

const AuthLayout = () => {
  return (
    <>
      {/* <Header /> */}
      {/* <AuthHeader/> */}
      <Layout style={{ margin: 0, padding: 0 }}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/confirm-password" element={<ConfirmPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Routes>
      </Layout>
    </>
  );
};

export default AuthLayout;
