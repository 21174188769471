/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input } from "antd";
import DefaultButton from "../components/Button/Button";
import * as patientApi from "../services/api/patient";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { addClinicalTrial } from "../services/api/doctor";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const [clinicalTrialPatients, setClinicalTrialPatients] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const getClinicalTrialPatients = async () => {
      let {
        data: { data },
      } = await patientApi.getClinicalTrialPatients();
      setClinicalTrialPatients(data);
    };
    getClinicalTrialPatients();
  }, []);

  let { Id } = useParams();

  const onFinish = async (data) => {
    try {
      setLoader(true);
      const AddUser = {
        email: data?.email,
        clinicalTrialId: Id,
      };
      const response = await addClinicalTrial(AddUser);
      if (response?.response?.status === 200) {
        navigate("/dashboard");
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  return (
    <>
      <div className="adduser">
        <Row className="adduser-header">
          <Col span={6}>
            <h5>Add users</h5>
          </Col>
        </Row>
        <Row className="steps-content form-input">
          <Col span={12}>
            <Form
              className="login-form"
              name="adduser-form"
              onFinish={onFinish}
              layout="vertical"
            >
              <Col span={16}>
                <Form.Item
                  label="Enter users’ email address :"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                    {
                      type: "email",
                      message: "Please enter valid email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8} className="button-section-adduser">
                <DefaultButton
                  htmlType={"submit"}
                  className="colored_btn"
                  title={"Add user"}
                  loading={loader}
                />
              </Col>
            </Form>
          </Col>
        </Row>
        <Row style={{ width: "100%" }} gutter={[30]}>
          <Col span={8} className="Dashboard-Table">
            <h3> Enrolled users :</h3>
            <Col span={24} className="Dashboard-Table">
              {clinicalTrialPatients.slice(0, 10).map((patient) => {
                return (
                  <>
                    <div className="adduser-table">
                      <li className="list list-three">
                        <p>
                          {patient.UserEmail ? patient.UserEmail : "No Name"}
                        </p>{" "}
                        <p>F</p> <p>32</p>
                      </li>
                      <MinusCircleOutlined />
                    </div>
                  </>
                );
              })}
            </Col>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AddUser;
