/** @format */

import React from "react";
import "antd/dist/antd.css";
import { Row, Col } from "antd";
import Left from "../assets/images/Left.png";
import Print from "../assets/images/Print.png";
import Download from "../assets/images/Download.png";
import Doubleauro from "../assets/images/Doubleauro.png";
import DynamicTable from "../pages/DynamicTable";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "Serial no",
    dataIndex: "number",
  },
  {
    title: "Session ID",
    dataIndex: "Session",
  },
  {
    title: "User Details",
    dataIndex: "Details",
  },
  {
    title: "Date",
    dataIndex: "address",
  },
  {
    title: "Level of Difficulty",
    dataIndex: "Level",
  },
  {
    title: "Delay (minutes)",
    dataIndex: "address",
  },
  {
    title: "Number of images",
    dataIndex: "address",
  },
  {
    title: "Tracing accurancy",
    dataIndex: "address",
  },
  {
    title: "Recall accurancy",
    dataIndex: "address",
  },
  {
    title: "Avg.response time (min.sec)",
    dataIndex: "address",
  },
];
const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    name: `ABC0001 ${i}`,
    age: 32,
    userdetails: `Intermediate. ${i}`,
  });
}
const Spreedsheet = () => {
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className="container">
      <Row className="Maincontainer">
        <Col span={8}>
          <div className="Leftside-col">
            <Link to={"/dashboard"}>
              <img src={Left} alt=""></img>
            </Link>
            <h2>
              Data collected on 8 scent<br></br> kit tests
            </h2>
          </div>
        </Col>

        <Col span={8}>
          <div className="center-col">
            <img src={Print} alt=""></img>
            <h4>Print</h4>

            <Col span={8}>
              <div className="Download-col">
                <img src={Download} alt=""></img>
                <h4>Download</h4>
              </div>
            </Col>
          </div>
        </Col>

        <Col span={8}>
          <div className="Rightside-col">
            <button>
              {" "}
              <img src={Doubleauro} alt=""></img>
              Sort by
            </button>
          </div>
        </Col>
      </Row>
      <DynamicTable columns={columns} dataSource={data} />
    </div>
  );
};
export default Spreedsheet;
