import { Col, Row } from "antd";
import React, { useState, useCallback, useEffect } from "react";
import { Form, Input, Select, message,Checkbox } from "antd";
import { useParams } from "react-router-dom";
import debounce from 'lodash/debounce';
import { isStudyStudyNumber } from '../../../services/api/admin'
export default function BasicDetails({ form, nextStep, testType }) {
  const { TextArea } = Input;
  // const testType = Object.freeze({
  //   ScentAware: 1,
  //   TraceAware: 2,
  //   WordsAware: 3,
  //   GlanceAware: 4,
  //   GrammarAware: 5,
  //   AudioAware: 6,
  // });

  let { id: clinicalTrialId } = useParams();

  const [validating, setValidating] = useState(false);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   // manually validate the field since input is not validated by form once state update.
  //   form.validateFields(['studyNumber']);
  // }, [validating]);

  const checkStudynumber = async (studyNumber) => {
    try {
      setValidating(true);
      const { data: { data } } = await isStudyStudyNumber(studyNumber);

      console.log("checking study number ", data)
      if (!data) {
        setError(null);
        form.setFields([
          {
            name: 'studyNumber',
            errors: [], // No errors
          },
        ]);

      } else {
        setError("Study Number is already exists");
        form.setFields([
          {
            name: 'studyNumber',
            errors: ["Study Number is already in use"], // Set custom error
          },
        ]);
      }
    } catch (error) {
      message.error('Error checking Study Number uniqueness');
      setError("Error checking Study Number uniqueness");
    } finally {
      setValidating(false);
    }
  };

  // Debounce the API call
  const debouncedCheck = useCallback(debounce(checkStudynumber, 800), []);

  const handleStudyNumberChange = (event) => {
    const studyNumber = event.target.value;
    if (studyNumber) {
      debouncedCheck(studyNumber);
    } else {
      setError("Study Number is required");
    }
  };

  console.log("testType.ScentAware", testType.ScentAware);
  return (
    <Form form={form} onFinish={!error && nextStep}>
      <Row>
        <div className="form-content">
        <Col lg={24}>
            <Row>
              <Col span={12}>
                <div className="checkbox-group">
                  <Form.Item
                    label="Research Center"
                    name="researchCenter"
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="checkbox-group">
                  <Form.Item
                    label="Home"
                    name="home"
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={24}>
            <div className="title-input">
              <Form.Item
                label="Study Number"
                rules={[
                  { required: true, message: "Study Number is required" },
                  // { pattern: /^\d+$/, message: "Please enter a valid number" }
                ]}
                name={"studyNumber"}
                validateStatus={validating ? 'validating' : error ? 'error' : ''}
                help={error || ''}
              >
                <Input placeholder="Provide a Study Number" disabled={clinicalTrialId} onChange={handleStudyNumberChange} />
              </Form.Item>
            </div>
          </Col>
          <Row>
            <Col lg={12} className="pe-2">
              <div className="title-input">
                <Form.Item
                  label="Title"
                  rules={[{ required: true, message: "Title is required" }]}
                  name={"name"}
                >
                  <Input placeholder="Provide a suitable title" />
                </Form.Item>
              </div>
            </Col>
            <Col lg={12} className="ps-2">
              <div className="title-input">
                <Form.Item
                  label="Test Type"
                  rules={[{ required: false, message: "Test Type is required" }]}
                  name={"testType"}
                >
                  <Select
                    placeholder="Select a test type"
                    defaultValue={testType.ScentAware}
                    mode="multiple"
                  >
                    {Object.entries(testType).map(([key, value]) => (
                      <Select.Option key={key} value={value}>
                        {key}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Col lg={24}>
            <div className="description-input">
              <Form.Item
                label="Description"
                rules={[{ required: true, message: "Description is required" }]}
                name={"description"}
              >
                <TextArea placeholder="Describe the trial briefly" />
              </Form.Item>
            </div>
          </Col>

          <Col lg={24}>
            <div className="title-input">
              <Form.Item
                label="Institute Name"
                rules={[{ required: true, message: "Institute Name is required" }]}
                name={"instituteName"}
              >
                <Input placeholder="Provide a Institute Name" />
              </Form.Item>
            </div>
          </Col>
        </div>
      </Row>
    </Form>
  );
}
