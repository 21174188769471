/** @format */

import { Col, Row } from "antd";
import React from "react";
import { LeftCircleOutlined } from "@ant-design/icons";
import GraphCard from "../../components/GraphCard/GraphCard";
import BarChart from "../../components/Charts/BarChart";
import ReactApexChart from "react-apexcharts";
import AreaChart from "../../components/Charts/AreaChart";

export default function ScentawareDetails() {
  let olfactoryFunctionData = [
    {
      name: "8 Tube test kit",
      data: [2, 7, 8, 5, 4, 10],
    },
    {
      name: "16 Tube test kit",
      data: [8, 4, 6, 2, 9, 5],
    },
  ];

  let olfactoryFunctionOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },

    grid: {
      show: true, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        borderRadius: 4,
        borderRadiusWhenStacked: "round",
      },
    },

    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: [15, 20, 25, 30, 35, 40],
      labels: {
        show: true,
        style: {
          colors: "#FFFFFF",
          fontSize: "12px",
          fontFamily: "Work Sans",
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },

    yaxis: {
      show: true,
      showAlways: true,
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: "#ffffff",
          fontSize: "12px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
      },

      title: {
        text: "Olfactory function",
        rotate: 270,
        style: {
          color: "#ffffff",
          fontSize: "13px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-title",
        },
      },

      axisBorder: {
        show: true,
        color: "#ffffff",
        offsetX: -1,
        offsetY: 0,
      },
    },

    legend: {
      show: true,
      width: 150,
      position: "top",
      offsetY: -8,
      offsetX: 400,
      labels: {
        colors: "#ffffff",
      },
      markers: {
        fillColors: ["#FF8181", "#33C9FC"],
        width: 18,
        height: 12,
        strokeWidth: 0,
        radius: 5,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 0,
        vertical: 8,
      },
    },

    fill: {
      colors: ["#FF8181", "#33C9FC"],
      opacity: 1,
    },
  };

  let executiveFunctionData = [
    {
      name: "8 Tube test kit",
      data: [2, 7, 8, 5, 4, 10],
    },
    {
      name: "16 Tube test kit",
      data: [8, 4, 6, 2, 9, 5],
    },
  ];

  let executiveFunctionOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },

    grid: {
      show: true, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        borderRadius: 4,
        borderRadiusWhenStacked: "round",
      },
    },

    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: [15, 20, 25, 30, 35, 40],
      labels: {
        show: true,
        style: {
          colors: "#FFFFFF",
          fontSize: "12px",
          fontFamily: "Work Sans",
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },

    yaxis: {
      show: true,
      showAlways: true,
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: "#ffffff",
          fontSize: "12px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
      },

      title: {
        text: "Avg. response time (secs)",
        rotate: 270,
        style: {
          color: "#ffffff",
          fontSize: "13px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-title",
        },
      },

      axisBorder: {
        show: true,
        color: "#ffffff",
        offsetX: -1,
        offsetY: 0,
      },
    },

    legend: {
      show: true,
      width: 150,
      position: "top",
      offsetY: -8,
      offsetX: 400,
      labels: {
        colors: "#ffffff",
      },
      markers: {
        fillColors: ["#33C9FC", "#0E84F2"],
        width: 18,
        height: 12,
        strokeWidth: 0,
        radius: 5,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 0,
        vertical: 8,
      },
    },

    fill: {
      colors: ["#33C9FC", "#0E84F2"],
      opacity: 1,
    },
  };

  let areaChartData = [
    {
      name: "8 Tube test kit",
      data: [2, 7, 8, 5, 4, 10],
    },
    {
      name: "16 Tube test kit",
      data: [8, 4, 6, 2, 9, 5],
    },
  ];

  let areaChartOptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    grid: {
      show: true, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },
    colors: ["#FF8181", "#33C9FC"],
    stroke: {
      curve: "smooth",
      colors: ["#FF8181", "#33C9FC"],
    },

    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: [15, 20, 25, 30, 35, 40],
      labels: {
        show: true,
        style: {
          colors: "#FFFFFF",
          fontSize: "12px",
          fontFamily: "Work Sans",
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },

    yaxis: {
      show: true,
      showAlways: true,
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: "#ffffff",
          fontSize: "12px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
      },

      title: {
        text: "Time (minutes)",
        rotate: 270,
        style: {
          color: "#ffffff",
          fontSize: "13px",
          fontFamily: "Work Sans",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-title",
        },
      },

      axisBorder: {
        show: true,
        color: "#ffffff",
        offsetX: -1,
        offsetY: 0,
      },
    },

    legend: {
      show: true,
      width: 150,
      position: "top",
      offsetY: -8,
      offsetX: 400,
      labels: {
        colors: "#ffffff",
      },
      markers: {
        fillColors: ["#FF8181", "#33C9FC"],
        width: 18,
        height: 12,
        strokeWidth: 0,
        radius: 5,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 0,
        vertical: 8,
      },
    },
    fill: {
      colors: ["#FF8181", "#33C9FC"],
    },
  };

  let donutChartData = [44, 70, 41];

  let donutChartOptions = {
    chart: {
      type: "donut",
      colors: "#ff",
      foreColor: "#fff",
      width: 550,
      height: 380,
    },
    colors: ["#0E84F2", "#99E7FD", "#004AB7"],
    plotOptions: {
      pie: {
        // startAngle: -90,
        // endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      markers: {
        fillColors: ["#0E84F2", "#99E7FD", "#004AB7"],
      },
    },
    fill: {
      colors: ["#0E84F2", "#99E7FD", "#004AB7"],
    },
    labels: [
      "Users who had neither a stuffy nose, nor were on any medication.",
      "Users who only had a stuffy nose.",
      "Users who experienced a  stuffy nose, and were on medication.",
    ],
  };

  return (
    <Row gutter={[10, 15]}>
      <Col
        lg={4}
        style={{
          fontfamily: "WorkSans-Light",
          fontWeight: "300",
          fontSize: "24px",
          padding: "0px 0px 0px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LeftCircleOutlined
          style={{
            color: "#85E0A3",
            fontSize: "31px",
          }}
        />

        <h1
          style={{
            margin: "0 auto",
            fontfamily: "WorkSans-Light",
            fontWeight3: "300",
            color: "#fff",
          }}
        >
          ScentAware
        </h1>
      </Col>

      <Col lg={16}>
        <Row
          style={{
            background: "#013259",
            borderRadius: "13px",
            padding: "10px",
            height: "38px",
            paddingLeft: "30px",
          }}
        >
          <Col
            lg={8}
            style={{
              display: "flex",
              fontfamily: "WorkSans-Regular",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            <h3
              style={{
                color: "#76DCFF",
                fontSize: "12px",
                fontWeight: "700",
                fontFamily: "WorkSans-Regular",
              }}
            >
              Total tests performed:{" "}
            </h3>

            <h4
              style={{
                fontFamily: "WorkSans-Regular",
                fontWeight: "700",
                fontSize: "12px",
                margin: "0 auto",
                color: "#ffff",
              }}
            >
              13,571
            </h4>
          </Col>
          <Col
            lg={8}
            style={{
              display: "flex",
              fontfamily: "WorkSans-Regular",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            <h3
              style={{
                color: "#76DCFF",
                fontSize: "12px",
                fontWeight: "700",
                fontFamily: "WorkSans-Regular",
              }}
            >
              Total tests performed:{" "}
            </h3>

            <h4
              style={{
                fontFamily: "WorkSans-Regular",
                fontWeight: "700",
                fontSize: "12px",
                margin: "0 auto",
                color: "#ffff",
              }}
            >
              13,571
            </h4>
          </Col>

          <Col
            lg={8}
            style={{
              display: "flex",
              fontfamily: "WorkSans-Regular",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            <h3
              style={{
                color: "#76DCFF",
                fontSize: "12px",
                fontWeight: "700",
                fontFamily: "WorkSans-Regular",
              }}
            >
              Total tests performed:{" "}
            </h3>

            <h4
              style={{
                fontFamily: "WorkSans-Regular",
                fontWeight: "700",
                fontSize: "12px",
                margin: "0 auto",
                color: "#ffff",
              }}
            >
              13,571
            </h4>
          </Col>
        </Row>
      </Col>

      <Col lg={4}>
        <button
          style={{
            backgroundColor: "#013259",
            color: "#33C9FC",
            borderColor: "#33C9FC",
            padding: "5px",
            borderRadius: "13px",
            height: "38px",
            width: "127px",
            fontfamily: "WorkSans-Regular",
            marginleft: "33px",
          }}
        >
          View test logs 
        </button>
      </Col>

      <Col lg={12} className="ScentAware-chart">
        <GraphCard
          background={"#013259"}
          border={"0px"}
          title="Executive function"
        >
          <BarChart
            chartData={executiveFunctionData}
            chartOptions={executiveFunctionOptions}
            width={600}
          />
        </GraphCard>
      </Col>

      <Col lg={12} className="ScentAware-chart">
        <GraphCard background={"#013259"} title="Olfactory function">
          <BarChart
            chartData={olfactoryFunctionData}
            chartOptions={olfactoryFunctionOptions}
            width={600}
          />
        </GraphCard>
      </Col>

      <Col lg={12}>
        <div className="Donut-chart" id="chart">
          <GraphCard background={"#013259"}>
            <ReactApexChart
              options={donutChartOptions}
              series={donutChartData}
              type="donut"
            />
          </GraphCard>
        </div>
      </Col>

      <Col lg={12} className="ScentAware-chart">
        <div className="spline-area" id="chart">
          <GraphCard background={"#013259"} title="Avg. session time">
            <AreaChart
              chartData={areaChartData}
              chartOptions={areaChartOptions}
              height={250}
              width={550}
            />
          </GraphCard>
        </div>
      </Col>
    </Row>
  );
}
