import React from "react";
import { Button, Col, Form, Input } from "antd";

const ClinicalEmail = () => {
  return (
    <>
      <Col span={8}>
        {/* <h5>Create a clinical trial</h5> */}
        <Form.Item
          label="Assign an adminstrator:"
          name="email"
          rules={[
            { required: true, message: "Please input your email!" },
            {
              type: "email",
              message: "Please enter valid email",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
      </Col>
    </>
  );
};
export default ClinicalEmail;
