import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Charts = ({ chartOptions, width = 100, height = 400 }) => {

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { width: `${width}%`, height: `${height}px` } }}
      />
    </div>
  );
};

export default Charts;
