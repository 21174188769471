import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ chartOptions, chartData, width, height }) => {
  return (
    <ReactApexChart
      options={chartOptions}
      series={chartData}
      type="line"
      height={height}
      width={width}
    />
  );
};

export default LineChart;
