import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Row, Table, Col } from "antd";
import Left from "../../assets/images/Left.png";
import Print from "../../assets/images/Print.png";
import Download from "../../assets/images/Download.png";
import Doubleauro from "../../assets/images/Doubleauro.png";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DynamicTable from "../../pages/DynamicTable";
import { Link } from "react-router-dom";
import * as scentawareApi from "../../services/api/scentaware";
import * as XLSX from "xlsx";
import { verifyTubeReadings } from "../../helpers/verifyTubeReadings";

const columns = [
  {
    title: "Tube Id",
    dataIndex: "TubeId",
  },
  {
    title: "Test Type",
    dataIndex: "TestType",
  },
  {
    title: "Session Id",
    dataIndex: "SessionId",
  },
  {
    title: "Created Date",
    dataIndex: "CreatedDate",
  },
  {
    title: "Selected Answer",
    dataIndex: "SelectedAnswer",
  },
  {
    title: "Correct Answers",
    dataIndex: "CorrectValues",
  },
  {
    title: "Wrong Answers",
    dataIndex: "IncorrectValues",
  },
  {
    title: "Correlation %",
    dataIndex: "CorrelationPercentage",
    width: "10%",
  },
  {
    title: "Average Response Time",
    dataIndex: "AverageResponseTime",
  },
  {
    title: "User Id",
    dataIndex: "UserId",
  },
  {
    title: "Stuffy Nose",
    dataIndex: "IsStuffyNose",
    render: (IsStuffyNose) => (IsStuffyNose ? "True" : "False"),
  },
];

const SixteenScentTest = () => {
  const [scentawareTable, setScentawareTable] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getScentawareData();
  }, []);

  const getScentawareData = async () => {
    try {
      setLoading(true);
      let testTube = "sixteen";
      const {
        data: { data },
      } = await scentawareApi?.getScentaware(testTube);
      let formattedData = verifyTubeReadings(16, data);
      setScentawareTable(formattedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleExport = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(scentawareTable);

    XLSX.utils.book_append_sheet(wb, ws, "Sixteen-Scent");
    XLSX.writeFile(wb, "Sixteen-Scent.xlsx");
  };

  return (
    <div className="container spacing">
      <Row className="Maincontainer">
        <Col span={8}>
          <div className="Leftside-col">
            <Link to={"/scentaware"}>
              <img src={Left}></img>
            </Link>
            <h2>
              Data collected on 16 scent<br></br> kit tests
            </h2>
          </div>
        </Col>

        <Col span={8}>
          <div className="center-col">
            <img src={Print}></img>
            <h4>Print</h4>

            <Col span={8}>
              <div className="Download-col" onClick={handleExport}>
                <img src={Download}></img>
                <h4>Download</h4>
              </div>
            </Col>
          </div>
        </Col>

        <Col span={8}>
          <div className="Rightside-col">
            <button>
              {" "}
              <img src={Doubleauro}></img>
              Sort by
            </button>
          </div>
        </Col>
      </Row>
      <DynamicTable
        columns={columns}
        dataSource={scentawareTable}
        loading={loading}
      />
    </div>
  );
};
export default SixteenScentTest;
