/** @format */

import React from "react";
import { Col, Row, Avatar, Checkbox } from "antd";

export default function Review({ clinicalTrialDetails }) {
  const startDate = clinicalTrialDetails?.start_date;
  const endDate = clinicalTrialDetails?.end_date;

  // const newStarDate = new Date(startDate);
  // const newEndDate = new Date(endDate);
  // const oneDay = 24 * 60 * 60 * 1000;
  // const diffDays = Math.round(Math.abs((newStarDate - newEndDate) / oneDay));
  // const option = { year: "numeric", month: "short", day: "numeric" };

  console.log("clinical", clinicalTrialDetails);

  return (
    <Row>
      <div className="form-content5 px-0 pe-2">
        <div className="review-details">
          <Col lg={24}>
            <div className="review_card">
              <h5>Basic details</h5>
              <div className="row g-3">
                <div className="col-12">
                  <div className="col-6 d-flex align-items-center gap-3">
                      {clinicalTrialDetails.researchCenter && (
                        <>
                          <div className="d-flex align-items-center gap-2">
                            <label>Research Center</label>
                            <div className="">
                              {/* <h6>{clinicalTrialDetails.name}</h6> */}
                              <Checkbox checked={true} />
                            </div>
                          </div>
                        </>
                      )}
                      {clinicalTrialDetails.home && (
                        <>
                          <div className="d-flex align-items-center gap-2">
                            <label>Home</label>
                            <div className="">
                              {/* <h6>{clinicalTrialDetails.name}</h6> */}
                              <Checkbox checked={true} />
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  {clinicalTrialDetails.studyNumber && (
                    <>
                      <label>Study Number</label>
                      <div className="review_detail">
                        <h6>{clinicalTrialDetails.studyNumber}</h6>
                      </div>
                    </>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  {clinicalTrialDetails.name && (
                    <>
                      <label>Title</label>
                      <div className="review_detail">
                        <h6>{clinicalTrialDetails.name}</h6>
                      </div>
                    </>
                  )}
                </div>

                <div className="col-12">
                  {clinicalTrialDetails.description && (
                    <>
                      <label>Description</label>
                      <div className="review_detail">
                        <h6>{clinicalTrialDetails.description}</h6>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-12">
                  {clinicalTrialDetails.instituteName && (
                    <>
                      <label>Institute Name</label>
                      <div className="review_detail">
                        <h6>{clinicalTrialDetails.instituteName}</h6>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="review_card mt-4">
              <h5>Duration</h5>
              <div className="row g-3">
                {startDate && (
                  <>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <label>Start Date</label>
                      <div className="review_detail">
                        <h6>{startDate}</h6>
                      </div>
                    </div>
                  </>
                )}
                {endDate && (
                  <>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <label>End Date</label>
                      <div className="review_detail">
                        <h6>{endDate}</h6>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="review_card mt-4">
              <h5>Moderators</h5>
              <div className="row">
                <div className="col-12">
                  <table className="table m-0 moderator_table">
                    <thead>
                      <tr>
                        <th>Email</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Role</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clinicalTrialDetails.moderators.map((x) => (
                        <tr>
                          <td>{x.email}</td>
                          <td>{x.firstName}</td>
                          <td>{x.lastName}</td>
                          <td>{x.role}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="review_card mt-4">
              <h5>Participants</h5>
              <div className="row">
                <div className="col-12">
                  <table className="table m-0 moderator_table">
                    <thead>
                      <tr>
                        <th>Email</th>
                        <th>Participant ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Age</th>
                        <th>Gender</th>
                        <th>Medical History Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clinicalTrialDetails.participants.map((x) => (
                        <tr>
                          <td>{x.email}</td>
                          <td>{x.participantId}</td>
                          <td>{x.firstName}</td>
                          <td>{x.lastName}</td>
                          <td>{x.age}</td>
                          <td>{x.gender}</td>
                          <td>{x.medicalHistoryDescription}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div className="review_card mt-4">
              <h5>Sites</h5>
              <div className="row g-3">
                {clinicalTrialDetails.sites.map((x) => (
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <label>Sites</label>
                    <div className="review_detail">
                      <h6>{x.siteName}</h6>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
          </Col>
        </div>
        {/* <div className="review-date">
          <p className="title">Duration</p>
          <p className="days">{diffDays} days</p>
          <div className="title">{`${newStarDate.toLocaleDateString(
            "en-us",
            option
          )} - ${newEndDate.toLocaleDateString("en-us", option)}`}</div>
        </div>

        <div className="review-details">
          <h4 className="participants">{`Participants (${clinicalTrialDetails.moderators.length})`}</h4>
        </div>

        <Col lg={24}>
          <div className="review-details">
            <h4>Moderators</h4>
          </div>
          <h2 className="Moderators">{clinicalTrialDetails.moderators.map(x => {
            <label style={{ marginLeft: 20 }}>{x.email}</label>
          })}</h2>
        </Col>

        <div className="review-details">
          <h4 className="participants">{`Participants (${clinicalTrialDetails.participants.length})`}</h4>
        </div>

        {clinicalTrialDetails.participants.map((x) => (
          <Col lg={24}>
            <div style={{ display: "flex", marginBottom: "8px" }}>
              <label style={{ marginLeft: 20 }}>{x.participantId}</label>
            </div>
          </Col>
        ))} */}
      </div>
    </Row>
  );
}
