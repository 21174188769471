import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Row, Table, Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import * as LogsData from "../../services/api/logsdata";
import moment from "moment";
import CustomTable from "../../components/Table/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";

const Logs = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0
  });
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userInfo"));
  if((user?.role === 'Customer' || user?.role === 'Admin' || user?.role === 'PI')){
    navigate("/dashboard");
  }
  const columns = [
    { Header: "UserId", accessor: "UserId" },
    { Header: "Event", accessor: "Event" },
    { Header: "EventMessage", accessor: "EventMessage" },
    { Header: "Error", accessor: "ErrorMessage" },
    { Header: "Date", accessor: "Date" },
    { Header: "Type", accessor: "Type" },
  ];

  const [data, setData] = useState([]);
  var returnPath = "/dashboard";
  if (id) {
    returnPath = "/dashboard/user-details/" + id;
  }

  const getTrainingData = async () => {
    try {
      setLoading(true);
      let { data: { data, totalRecords } } = await LogsData?.getLogsData(pagination);
      setPagination((state) => ({ ...state, totalCount: totalRecords }));
      let newData = [];
      data.map((element, index) => {
        let newObj = {
          ...element,
          ['Date']: moment(element.Date).format("MM/DD/Y HH:mm:ss"),
        }
        newData = [...newData, newObj];
      });
      setData(newData);
      setLoading(false);
    }
    catch (error) {
      console.log("tableError", error)
    }
  };

  useEffect(() => {
      getTrainingData();
  }, [currentPage]);


  return (
    <>
      <CustomTable
        columns={columns}
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
        setCurrentPage={setCurrentPage}
        type={"logs"}
      />
    </>
  )
}

export default Logs