/** @format */

import { axiosApi } from "../axios/axios_config";

export async function getDoctor() {
  try {
    const { data, response } = await axiosApi({
      endpoint: `doctor`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function getClinicalTrial(userId) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `doctor/clinical-trial?userId=${userId}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function addClinicalTrial(AddUser) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `doctor/clinical-trial`,
      method: "POST",
      bodyData: AddUser,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

export async function getClinicalTrialWithCount(userId) {
  try {
    const { data, response } = await axiosApi({
      endpoint: `doctor/clinical-trial-with-count?userId=${userId}`,
      method: "GET",
      bodyData: null,
    });
    return { data, response };
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}
