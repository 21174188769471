/** @format */

import React, { useEffect, useState } from "react";
import { Avatar, List, Empty } from "antd";
import * as adminApi from "../../services/api/admin";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CSVLink } from 'react-csv';

const ClinicalTrial = () => {
  let navigate = useNavigate();
  const [csvData, setCsvData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [clinicalTrial, setClinicalTrial] = useState([]);
  const [allClinicalTrial, setAllClinicalTrial] = useState([]);
  const [clinicalTrialParticipants, setClinicalTrialParticipants] = useState(
    []
  );
  const [clinicalTrialDetail, setClinicalTrialDetail] = useState([]);
  // const [clinicalTrialDetail, setClinicalTrialDetail] = useState({
  //   Description: "",
  //   FirstName: "",
  //   LastName: "",
  //   Name: "",
  // });
  const [selectedClinicalTrialIndex, setSelectedClinicalTrialIndex] = useState(null);
  const [selectedClinicalTrial, setSelectedClinicalTrial] = useState({
    Description: "",
    Name: "",
    StudyNumber: ""
  });
  const [userRole, setUserRole] = useState('');
  const user = JSON.parse(localStorage.getItem("userInfo"));

  // const [clinicalTrialDoctor, setClinicalTrialDoctor] = useState([]);

  useState(() => {
    setTimeout(() => {
      if (clinicalTrial !== []) {
        setLoader(false);
      }
    }, 2000);
  });
  useEffect(() => {
    const userInfoString = localStorage.getItem('userInfo');
    if (userInfoString) {
      const userInfo = JSON.parse(userInfoString);
      setUserRole(userInfo.role);
    }
  }, []);

  useEffect(() => {
    (async () => {
      // if (user?.role !== "Admin" && user?.role !== "Customer") {
      let {
        data: { data: clinicalTrials },
      } = await adminApi.getClinicalTrailWithCount();

      if (!clinicalTrials) return;
      const uniqueStudyNumbers = new Set();

      let distinctClinicalTrials = clinicalTrials.filter((current) => {
        const { StudyNumber } = current;
        if (uniqueStudyNumbers.has(StudyNumber)) {
          return false; // Skip if already seen
        }
        uniqueStudyNumbers.add(StudyNumber);
        return true; // Include in the distinctClinicalTrials array
      });
      if (user?.role === "Admin" || user.role === "PI" || user?.role === "Customer") {
        distinctClinicalTrials = distinctClinicalTrials.filter(x => x.Name.toLowerCase() != "default")
      }
      setClinicalTrial(distinctClinicalTrials);

      if (!distinctClinicalTrials.length) return;
      // const newRecords = Object.keys(records);
      const studyNumber = distinctClinicalTrials[0].StudyNumber;
      const currentClinicalTrial = distinctClinicalTrials[0];
      setAllClinicalTrial(clinicalTrials)
      if (currentClinicalTrial && currentClinicalTrial.Id) {
        setSelectedClinicalTrialIndex(0);

        let {
          data: { data: clinicalTrialDetail },
        } = await adminApi.getClinicalTrialDetail(studyNumber);

        setSelectedClinicalTrial({
          Description: clinicalTrialDetail[0].Description ? clinicalTrialDetail[0].Description : '',
          Name: clinicalTrialDetail[0].Name ? clinicalTrialDetail[0].Name : '',
          StudyNumber: clinicalTrialDetail[0].StudyNumber
        })

        let {
          data: { data: clinicalTrialParticipants },
        } = await adminApi.getClinicalTrialParticipants(currentClinicalTrial.Id);

        const uniqueParticipant = new Set();
        const distinctParticipant = clinicalTrialParticipants.filter((current) => {
          const { UserId } = current;
          if (uniqueParticipant.has(UserId)) {
            return false; // Skip if already seen
          }
          uniqueParticipant.add(UserId);
          return true; // Include in the distinctClinicalTrials array
        });
        setClinicalTrialDetail(clinicalTrialDetail);


        let csvData = distinctParticipant.filter(x => x.Email).map(x => ({
          UserId: x.UserId,
          Email: x.Email,
          FirstName: x.FirstName,
          ParticipantId: x.ParticipantId,
          OriginalPassword: x.OriginalPassword
        }))

        setCsvData(csvData);
        setClinicalTrialParticipants(distinctParticipant);
      }

      // }
    })();
  }, []);

  const handleSelectedClinicalTrial = async (id, index) => {
    try {
      setLoader(true);
      let {
        data: { data: clinicalTrialDetail },
      } = await adminApi.getClinicalTrialDetail(clinicalTrial[index].StudyNumber);
      let {
        data: { data: clinicalTrialParticipants },
      } = await adminApi.getClinicalTrialParticipants(id);
      setSelectedClinicalTrial({
        Description: clinicalTrialDetail[0].Description ? clinicalTrialDetail[0].Description : '',
        Name: clinicalTrialDetail[0].Name ? clinicalTrialDetail[0].Name : '',
        StudyNumber: clinicalTrialDetail[0].StudyNumber
      })
      setSelectedClinicalTrialIndex(index);

      setClinicalTrialDetail(clinicalTrialDetail);
      const uniqueParticipant = new Set();
      const distinctParticipant = clinicalTrialParticipants.filter((current) => {
        const { UserId } = current;
        if (uniqueParticipant.has(UserId)) {
          return false; // Skip if already seen
        }
        uniqueParticipant.add(UserId);
        return true; // Include in the distinctClinicalTrials array
      });


      setClinicalTrialParticipants(distinctParticipant);

      let csvData = distinctParticipant.filter(x => x.Email).map(x => ({
        UserId: x.UserId,
        Email: x.Email,
        FirstName: x.FirstName,
        ParticipantId: x.ParticipantId,
        OriginalPassword: x.OriginalPassword
      }))

      setCsvData(csvData);
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClinicalTrail = async (item, index) => {
    try {
      navigate(`/dashboard/clinical-trial/update/${item.Id}`);
    } catch (error) {
      console.log(error);
    }
  }

  // console.log('clinicalTrialDetail', clinicalTrialDetail);
  // console.log('clinicalTrial', clinicalTrial);
  // console.log('selectedClinicalTrial', selectedClinicalTrial);

  // const downlaodParticipantPassword = () => {
  //   console.log('clinicalTrialParticipants export wala', clinicalTrialParticipants);


  // }

  console.log('csvData', csvData);
  return (
    <>
      <div className="page_wrapper">
        <div className="row flex-lg-row flex-md-column-reverse flex-column-reverse">
          <div className="col-lg-3 col-md-12 col-sm-12 col-12 pe-lg-0 mt-lg-0 mt-4">
            <div className="new_trial">
              <div className="text-center mb-3 w-100">
                <Link to="/dashboard/clinical-trial/create">
                  <button className="btn btn-primary">
                    {
                      userRole !== 'SuperAdmin' ? <span>+ Create New Study</span> : <span>+ Create New Study Accounts</span> 
                    }
                  </button>
                </Link>
              </div>
              {/* <hr className="mb-2  mx-2" /> */}

              <List
                style={{ height: 550, overflowY: "scroll" }}
                loading={loader}
                dataSource={clinicalTrial
                  // user?.role === "Admin" || user?.role === "Customer"
                  //   ? doctorFilter
                  //   : adminFilter
                }
                renderItem={(item, index) => (
                  <List.Item
                    key={item.Id}
                    onClick={(e) => {
                      const isEditIconClicked = e.target.closest('.edit_iconclinic');
                      // Execute handleSelectedClinicalTrial only if the edit icon is not clicked
                      if (!isEditIconClicked) {
                        handleSelectedClinicalTrial(
                          item.Id,
                          index
                        );
                      }
                    }
                    }
                    style={{ display: "block", padding: "0", border: "0" }}
                  >
                    <hr className="my-2 mx-2" />
                    <div className={`prince_ton_card ${selectedClinicalTrialIndex === index && 'prince_active'} p-3`}>
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="mb-1">{item.Name}</h4>
                      </div>
                      <ul className="d-flex align-items-center justify-content-between">
                        <li>{`Participants: ${item.totalPatients}`}</li>
                        <li className="d-flex align-items-center justify-content-between">
                          <button type="button" className="btn btn-primary h-auto" onClick={() => handleEditClinicalTrail(item, index)}>
                          {/* <button type="button" className="edit_iconclinic" onClick={() => handleEditClinicalTrail(item, index)}> */}
                            {/* <div className="edit_delete d-flex align-items-center"> */}
                              {/* <span className="edit active users_status d-flex align-items-center justify-content-center"> */}
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                </svg> */}
                                Edit
                              {/* </span> */}
                            {/* </div> */}
                          </button></li>
                      </ul>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </div>
          <div
            className="col-lg-9 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-4 ps-lg-4 ps-md-4 pt-lg-5 pt-md-5">
            <div className="small_acutiy_card h-100">

              {loader ? (
                <div className="w-100 text-center loading h-100 d-flex align-items-center justify-content-center">
                  <h1 style={{ color: "black" }}>Loading...</h1>
                </div>
              ) : selectedClinicalTrialIndex != null ? (
                <>
                  <div className="mb-4 d-flex align-items-lg-center align-items-md-center align-items-start justify-content-between flex-lg-row flex-md-row flex-column pe-lg-5 me-lg-5">
                    <h3 className="m-0" style={{ fontSize: '20px' }}>{selectedClinicalTrial?.Name}</h3>
                    {/* <h3 className="m-0">{selectedClinicalTrial?.StudyNumber}</h3> */}
                    <div className="d-flex align-items-center">
                      <span className="me-0" style={{ fontSize: '15px' }}>{`Study Number : ${selectedClinicalTrial?.StudyNumber}`}</span>
                      {/* <h3 className="m-0" style={{ fontSize: '20px' }}>{selectedClinicalTrial?.StudyNumber}</h3> */}
                    </div>


                    <div className="d-flex align-items-center">
                      {/* <CSVLink
                        data={csvData}
                        filename={"clinical-trial-participants-detail.csv"}
                        className="btn btn-primary"
                        target="_blank"
                        onClick={() => {
                          // This check is necessary to prevent the CSVLink from triggering download with empty data
                          return csvData.length > 0;
                        }}
                      >
                       Export
                      </CSVLink> */}
                      {userRole !== 'SuperAdmin' && (
                        <CSVLink
                          data={csvData}
                          filename={"clinical-trial-participants-detail.csv"}
                          className="btn btn-primary"
                          target="_blank"
                          onClick={() => {
                            // This check is necessary to prevent the CSVLink from triggering download with empty data
                            return csvData.length > 0;
                          }}
                        // onClick={(e) => {
                        //   if (csvData.length === 0) {
                        //     e.preventDefault();
                        //   }
                        // }}
                        >
                          Export
                        </CSVLink>
                      )}
                    </div>
                    <Link to={`/dashboard/${selectedClinicalTrial.StudyNumber}?type=clinicalTrialId`}>
                      <a className="mt-lg-0 mt-md-0 mt-3">
                        View test logs
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                          viewBox="0 0 24 24" fill="none"
                          className="ms-1">
                          <path
                            d="M7.0001 13L14.5901 13L11.2901 16.29C11.1018 16.4783 10.996 16.7337 10.996 17C10.996 17.2663 11.1018 17.5217 11.2901 17.71C11.4784 17.8983 11.7338 18.0041 12.0001 18.0041C12.2664 18.0041 12.5218 17.8983 12.7101 17.71L17.7101 12.71C17.8011 12.6149 17.8725 12.5027 17.9201 12.38C18.0201 12.1365 18.0201 11.8635 17.9201 11.62C17.8725 11.4972 17.8011 11.3851 17.7101 11.29L12.7101 6.29C12.6171 6.19627 12.5065 6.12188 12.3847 6.07111C12.2628 6.02034 12.1321 5.9942 12.0001 5.9942C11.8681 5.9942 11.7374 6.02034 11.6155 6.07111C11.4937 6.12188 11.3831 6.19627 11.2901 6.29C11.1964 6.38296 11.122 6.49356 11.0712 6.61542C11.0204 6.73728 10.9943 6.86799 10.9943 7C10.9943 7.13201 11.0204 7.26272 11.0712 7.38458C11.122 7.50644 11.1964 7.61703 11.2901 7.71L14.5901 11L7.0001 11C6.73489 11 6.48053 11.1054 6.293 11.2929C6.10546 11.4804 6.0001 11.7348 6.0001 12C6.0001 12.2652 6.10546 12.5196 6.293 12.7071C6.48053 12.8946 6.73489 13 7.0001 13Z"
                            fill="#101A36" />
                        </svg>
                      </a>
                    </Link>
                  </div>
                  <div className="row">
                    <div className="col-lg-7 col-md-12 col-sm-12 col-12 pe-lg-3">
                      <p className="m-0">{selectedClinicalTrial?.Description}</p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                      <div
                        className="moderators d-flex align-items-start flex-lg-row flex-md-row flex-column">
                        <h3 className="m-0">Moderators</h3>
                        <div>
                          <div className="maderators_info ms-lg-4 ms-md-4 mt-lg-0 mt-md-0 mt-3 ps-lg-3 ps-md-3">
                            {
                              clinicalTrialDetail &&
                              clinicalTrialDetail.map((x) => (
                                <div className="d-flex flex-column ml-3" key={x.Id}>
                                  <Avatar
                                    style={{
                                      backgroundColor: "#9747FF",
                                      verticalAlign: "middle",
                                    }}
                                    size="large"
                                  >
                                    {(x.FirstName ? x.FirstName.substring(0, 1) : "N/A") +
                                      (x.LastName ? x.LastName.substring(0, 1) : "")}
                                  </Avatar>
                                  <div className="ms-3">
                                    <h3>
                                      {(x.FirstName ? x.FirstName : "No Name") + " "} {x?.LastName}
                                    </h3>
                                  </div>
                                </div>
                              ))
                            }

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-lg-5 mt-md-4 mt-4">
                    <div className="col-12">
                      <div
                        className="moderators d-flex align-items-start flex-lg-row flex-md-row flex-column">
                        <h3 className="m-0">Participants ({clinicalTrialParticipants.length})</h3>
                      </div>

                      <div className="test_table mt-2">
                        <table className="table m-0">
                          <thead>
                            <tr>
                              <th scope="col">User ID</th>
                              <th scope="col">Email</th>
                              <th scope="col">Participant ID</th>
                              <th scope="col">Username</th>
                              <th scope="col">Age</th>
                              <th scope="col">Gender</th>
                              {/* <th scope="col"></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {clinicalTrialParticipants.length ? (
                              clinicalTrialParticipants.map((participant) => (
                                <>
                                  <tr>
                                    <td scope="row">{participant?.UserId}</td>
                                    <td scope="row">{participant?.Email && participant?.Email != 'null' ? participant?.Email : 'N/A'}</td>
                                    <td scope="row">{participant?.ParticipantId}</td>
                                    <td>{(participant.UserName && participant.UserName != 'undefined')
                                      ? participant.UserName
                                      : "No Name"}</td>
                                    <td>{(participant.Age && participant.Age != 'undefined') ? participant.Age : 'Age not available'}</td>
                                    <td>{(participant.Gender && participant.Gender != 'undefined') ? participant.Gender : 'Gender not available'}</td>
                                    {/* <td className="pe-0">
                                      <Link
                                        to={`/dashboard/user-details/${participant?.UserId}`}
                                      >
                                        <a >View charts</a>
                                      </Link>

                                    </td> */}
                                  </tr>
                                </>
                              ))
                            ) : (
                              <td colSpan={5}>
                                <div className="mb-4 text-center"
                                >
                                  <p>No Participants</p>
                                </div>
                              </td>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>

              ) : (
                <Empty
                  description="No Clinical Trial Selected Selected"
                  style={{ color: "#ffff" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ClinicalTrial;
