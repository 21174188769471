import React, { useEffect, useState } from "react";
import Card from "../components/Header/Card";
import Left from "../assets/images/Left.png";
import Right from "../assets/images/Right.png";
import Print from "../assets/images/Print.png";
import Download from "../assets/images/Download.png";
import Doubleauro from "../assets/images/Doubleauro.png";
import "antd/dist/antd.css";
import { Col, Row } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import DynamicTable from "./DynamicTable";
import { Link } from "react-router-dom";
import * as scentawareApi from "../services/api/scentaware";
ChartJS.register(ArcElement, Tooltip, Legend);

const columns = [
  {
    title: "Tube Id",
    dataIndex: "TubeId",
  },
  {
    title: "Session Id",
    dataIndex: "SessionId",
  },
  {
    title: "Created Date",
    dataIndex: "CreatedDate",
  },
  {
    title: "Selected Answer",
    dataIndex: "SelectedAnswer",
  },
  {
    title: "Average Response Time",
    dataIndex: "AverageResponseTime",
  },
  {
    title: "User Id",
    dataIndex: "UserId",
  },
  {
    title: "Stuffy Nose",
    dataIndex: "IsStuffyNose",
    render: (IsStuffyNose) => (IsStuffyNose ? "True" : "False"),
  },
];

const Scentaware = () => {
  const [scentawareTable, setScentawareTable] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);

  const getScentawareData = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await scentawareApi?.getScentaware();
      setScentawareTable(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getScentawareTestStats = async () => {
    try {
      const {
        data: { data },
      } = await scentawareApi?.getScentawareTestStats();
      setStats(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getScentawareData();
    getScentawareTestStats();
  }, []);

  const cardsData = [
    {
      name: "8 scent kit tests",
      link: "/scentaware/eight-scent",
      count: stats?.eightKitTests,
    },
    {
      name: "16 scent kit tests",
      link: "/scentaware/sixteen-scent",
      count: stats?.sixteenKitTests,
    },
  ];
  const data = {
    datasets: [
      {
        label: "# of Votes",
        data: [25, 75],
        backgroundColor: ["#33C9FC", "#0E84F2"],
        borderWidth: 1,
        innerRadius: 0.8,
      },
    ],
  };

  return (
    <>
      <div className="Container">
        <div className="Topheading-col">
          <Link to="/dashboard">
            <img src={Left}></img>
          </Link>

          <h2>ScentAware Overview</h2>
        </div>
        <Row className="spacing">
          <Col span={8}>
            <Col span={24}>
              <Card>
                <div className="card-digit-section">
                  <div className="content">
                    <h4>Total tests performed :</h4>
                    {/* <h4>
                      Tests performed using<br></br>8 scent kits :
                    </h4>
                    <h4>
                      Tests performed using<br></br>16 scent kits :
                    </h4> */}
                  </div>
                  <div className="number">
                    <h5>{stats?.totalScentAwareTests}</h5>
                    {/* <h5>8,821</h5>
                    <h5>4,750</h5> */}
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={24}>
              <div className="Donut-section">
                <Doughnut
                  data={data}
                  borderWidth={1}
                  options={{
                    cutout: 200,
                  }}
                />
              </div>
            </Col>
          </Col>

          <Col className="all-cards" span={16}>
            {cardsData?.map((item) => {
              return (
                <>
                  <Col span={11}>
                    <Card>
                      <h2>{item.name}</h2>
                      <p>Tests performed : {item?.count}</p>
                      <ul>
                        <li>Tests performed to measure</li>
                        <li>-Odor identification</li>
                        <li>-Oder intensity</li>
                        <li>-Oder discrimination</li>
                        <li>-Executive function</li>
                      </ul>
                      <div className="view">
                        <Link to={item.link}>
                          <span>View data</span>
                          <img src={Right}></img>
                        </Link>
                      </div>
                    </Card>
                  </Col>
                </>
              );
            })}
          </Col>
        </Row>
        <DynamicTable
          columns={columns}
          dataSource={scentawareTable}
          loading={loading}
        />
      </div>
    </>
  );
};
export default Scentaware;
